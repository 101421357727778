<h2 mat-dialog-title>2 factor authenticatie</h2>

<mat-dialog-content class="container-fluid">
    <div class="row" *ngIf="!recoverCodes() && !twoFactorEnabled()">
        <div class="col-md-12 enable">
            <p>Er is nog geen 2 factor authenticatie ingesteld.</p>
        </div>
    </div>

    <div class="row qrcode" *ngIf="recoverCodes()">
        <div class="col-md-6">
            <p>
                Scan deze QR-code met een 2 factor authenticatie app zoals
                Google Authenticator of FreeOTP.
            </p>
            <div class="qrcode-container" [innerHTML]="qrcode()"></div>
        </div>
        <div class="col-md-6">
            <h6 class="fw-bold">Herstel codes:</h6>
            <p *ngFor="let code of recoverCodes()">{{ code }}</p>
        </div>
    </div>

    <div class="row" *ngIf="twoFactorEnabled() && !recoverCodes()">
        <div class="col-md-12">
            <p>2 factor authenticatie is ingeschakeld.</p>
        </div>
    </div>

    <mat-dialog-actions class="row">
        <div class="col-md-12">
            <button
                *ngIf="recoverCodes() || twoFactorEnabled()"
                mat-raised-button
                color="warn"
                (click)="disable2FA()"
            >
                2FA uitschakelen
            </button>

            <button
                *ngIf="!recoverCodes() && !twoFactorEnabled()"
                mat-raised-button
                color="primary"
                (click)="enable2FA()"
            >
                2FA inschakelen
            </button>
        </div>
    </mat-dialog-actions>
</mat-dialog-content>
