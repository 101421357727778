import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService, AuthService } from 'app/_services';
import { environment } from 'environments/environment';
import {
    FormBuilder,
    Validators,
    FormGroup
} from '@angular/forms';

@Component({
    selector: 'reset',
    templateUrl: 'reset.component.html',
    styleUrls: ['reset.component.css']
})
/**
 * This component handles the password reset
 */
export class ResetComponent implements OnInit {
    email: string;
    emailSent: boolean;

    resetForm: FormGroup;

    hide1 = true;
    hide2 = true;

    token: string;

    error = '';
    loading = false;
    randomImg: string;
    environment: any;

    constructor(
        private router: Router,
        public configService: ConfigService,
        public authService: AuthService,
        private http: HttpClient,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder
    ) {
        // Clear all localStorage
        localStorage.clear();
        this.environment = environment;
    }

    ngOnInit(): void {
        // Get reset token from url to allow resetting the password
        if (this.activeRoute.snapshot.params['token']) {
            this.token = this.activeRoute.snapshot.params['token'];
        }

        this.resetForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()\\-\\?_=+{};:,<.>])[A-Za-z\\d!@#$%^&*()\\-\\?_=+{};:,<.>]{1,}$'
                    )
                ]
            ],
            confirmPassword: [
                '',
                [Validators.required, Validators.minLength(8)]
            ]
        });
    }

    /**
     * Login the user using the authentication service
     * @return {[type]} [description]
     */
    requestReset(): void {
        // Create the post body
        const body = {
            email: this.email.toLowerCase(),
            organization: this.environment.loginPrefix
        };

        this.http
            .post(environment.base_url + '/password/email', body, {
                observe: 'response',
                responseType: 'json'
            })
            .subscribe(
                (response: any) => {
                    this.emailSent = true;
                },
                error => {
                    if (error.error) {
                        this.error =
                            'Wachtwoord herstellen mislukt, controleer het emailadres en probeer het nog eens. Heeft u een vraag, neem dan contact op met de applicatiebeheerder Cook bij de gemeente.';
                    }
                }
            );
    }

    /**
     * Updates the users password
     */
    resetPassword(): void {
        const body = {
            token: this.token,
            email: this.resetForm.get('email').value,
            password: this.resetForm.get('password').value,
            password_confirmation: this.resetForm.get('confirmPassword').value,
            organization: this.environment.loginPrefix
        };

        body.email = body.email.toLowerCase();

        this.http
            .post(environment.base_url + '/password/reset', body, {
                observe: 'response',
                responseType: 'json'
            })
            .subscribe(
                (response: any) => {
                    if (response.status == 200) {
                        this.authService.redirectUrl.set(undefined);
                        // Password change successful, redirect to home
                        this.router.navigate(['/login']);
                    }
                },
                error => {
                    // clear last error
                    this.error = undefined;
                    if (error.status === 400 && error.error) {
                        if (error.error.password) {
                            this.error =
                                'Uw wachtwoord is ongeldig of de wachtwoorden komen niet overeen.';
                        } else if (error.error.email) {
                            this.error =
                                'Het ingevulde e-mailadres is ongeldig, vul een geldig e-mailadres in.';
                        }
                    }

                    if (
                        error.status === 422 &&
                        error.error &&
                        error.error.error
                    ) {
                        if (error.error.error === 'passwords.token') {
                            this.error =
                                'Het token om uw wachtwoord te herstellen is verlopen of ongeldig. Probeer opnieuw het wachtwoord te herstellen.';
                        }
                        if (error.error.error === 'passwords.user') {
                            this.error =
                                'Uw e-mailadres komt niet overeen met het e-mailadres waarmee u het wachtwoordherstel heeft aangevraagd. Controleer uw e-mailadres en probeer het nogmaals.';
                        }
                        if (error.error.error === 'passwords.duppassword') {
                            this.error =
                                'Dit wachtwoord is al eerder gebruikt, maak een nieuw wachtwoord aan.';
                        }
                        if (error.error.error === 'email') {
                            this.error =
                                'Het ingevulde e-mailadres is ongeldig, vul een geldig e-mailadres in.';
                        }
                    }
                }
            );
    }

    // For short syntax in HTML
    get f() {
        return this.resetForm.controls;
    }
}
