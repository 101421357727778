import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { LegendService } from 'app/_services';
import { Layer } from 'ol/layer';

@Component({
    selector: 'cook-map-opacity-slider',
    templateUrl: 'map-opacity-slider.html',
    styleUrls: ['map-opacity-slider.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapOpacitySliderComponent {
    title: string;
    opacity: number;
    map: Layer;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBar: MatSnackBar,
        private legendService: LegendService
    ) {
        this.map = this.legendService.findMap(this.data.olMap.id);
        this.title = this.map.get('title');
        this.opacity = this.map.getOpacity() ?? 1;
    }

    change(event: Event): void {
        const value = (event.target as HTMLInputElement).valueAsNumber;
        this.opacity = value;
        this.data.olMap.opacity = this.map.setOpacity(this.opacity);
        this.legendService.customMap[this.data.olMap.name] = this.data.olMap;
    }

    formatLabel(value: number): string {
        return `${value}`;
    }
}
