<div class="legendItem">
    <span class="collapseState" (click)="toggleCollapse()">
        <div *ngIf="collapsed()">
            <fa-icon [icon]="faAngleRight"></fa-icon>
        </div>
        <div *ngIf="!collapsed()">
            <fa-icon [icon]="faAngleDown"></fa-icon>
        </div>
    </span>
    <div class="form-check custom-checkbox">
        <ng-content></ng-content>
        <input
            type="checkbox"
            name="inlineRadioOptions"
            class="form-check-input"
            id="{{ 'group' + group.id }}"
            [checked]="checked()"
            (change)="checkboxChanged($event)"
            (click)="toggleGroup()"
        />
        <label class="form-check-label" for="{{ 'group' + group.id }}">
            {{ label() }}
        </label>

        <ng-content select="[slot=drag-handle]"></ng-content>
    </div>
    <a
        class="metadata"
        aria-label="Metadata"
        href="{{ metadata_url() || '' }}"
        target="_blank"
        *ngIf="metadata_url()?.length > 0"
        ><fa-icon [icon]="faInfoCircle"></fa-icon
    ></a>
    <ul
        *ngIf="layers().length > 0"
        [ngClass]="{ hidden: collapsed() }"
        cdkDropList
        cdkDropListData="layers()"
        (cdkDropListDropped)="drop($event)"
    >
        <li cdkDrag cdkDragLockAxis="y" *ngFor="let layer of layers()">
            <span *cdkDragPreview>{{ layer.name }}</span>

            <layer
                [layer]="layer"
                [map]="map"
                (checkEvent)="viewChildChecked($event)"
            >
                <div class="drag-handle" slot="drag-handle" cdkDragHandle></div>
            </layer>
        </li>
    </ul>
</div>
