import { CommonModule, DatePipe, JsonPipe, NgForOf } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    signal
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter } from '@angular/material/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { environment } from 'environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface ValueItem {
    item_id: number;
    item_name: string;
    current_value: string | null;
    exists: boolean;
    filled: boolean;
    register_id: number;
}

export interface HistoryData {
    [key: string]: {
        [key: string]: ValueItem;
    };
}

@Component({
    selector: 'history-dialog',
    templateUrl: 'history.dialog.html',
    styleUrls: ['history.dialog.scss'],
    standalone: true,
    imports: [
        HttpClientModule,
        MatDialogModule,
        NgForOf,
        MatDividerModule,
        DatePipe,
        JsonPipe,
        CommonModule,
        MatCardModule,
        MatListModule,
        MatAccordion,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Dialog for the metadata
 */
export class HistoryDialogComponent implements OnInit {
    readonly history = signal<{
        [time: string]: { [itemId: string]: ValueItem };
    }>({});

    constructor(
        private readonly dialogRef: MatDialogRef<HistoryDialogComponent>,
        private readonly http: HttpClient,
        private readonly dateAdapter: DateAdapter<Date>,
        @Inject(MAT_DIALOG_DATA) public data: { id: number }
    ) {
        this.dateAdapter.setLocale('nl');
    }

    ngOnInit() {
        const url = environment.api_base_url + '/forms/history/' + this.data.id;

        this.http.get<HistoryData>(url).subscribe((res: HistoryData) => {
            this.history.set(res);
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getHistoryTimes(): string[] {
        return Object.keys(this.history());
    }

    getValuesForTime(time: string): ValueItem[] {
        const values = this.history()[time];
        return Object.keys(values).map(key => values[key]);
    }

    getValueDisplay(valueItem: ValueItem): string {
        if (!valueItem.exists) {
            return `Invulveld "${valueItem.item_name}" bestond nog niet bij het aanmaken van deze registratie`;
        }

        return valueItem.current_value || 'Geen waarde';
    }
}
