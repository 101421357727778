import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Injectable, model } from '@angular/core';
import { Draw, Modify, Snap } from 'ol/interaction';
import { MapService } from './map.service';
import { EditService } from './edit.service';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is made for all the tools that you can see on the right of the Map
 */
export class InteractionService {
    readonly defaultLineColor = '#f49441';
    readonly defaultFillColor = 'rgba(244, 148, 65, 0.3)';

    readonly circle = {
        title: '',
        radius: model<number>(undefined),
        lineColor: this.defaultLineColor,
        fillColor: this.defaultFillColor
    };
    readonly point = {
        title: '',
        lineColor: this.defaultLineColor,
        fillColor: this.defaultFillColor
    };
    readonly line = {
        title: '',
        lineColor: this.defaultLineColor,
        fillColor: this.defaultFillColor,
        lineWidth: 5
    };
    readonly polygon = {
        title: '',
        lineColor: this.defaultLineColor,
        fillColor: this.defaultFillColor
    };

    editLayers = [];

    click = {
        interaction: undefined as any
    };

    info = {
        interaction: undefined as any,
        active: false
    };

    vrPhoto = {
        interaction: undefined as any,
        active: false
    };

    cyclo = {
        interaction: undefined as any,
        active: false
    };

    oblique = {
        interaction: undefined as any,
        active: false
    };

    getCoordinate = {
        interaction: undefined as any,
        active: false
    };

    draw = {
        interaction: undefined as Draw,
        active: false
    };

    snap = {
        interaction: undefined as Snap,
        active: false
    };

    modifyDraw = {
        interaction: undefined as Modify,
        active: false
    };

    create = {
        interaction: undefined as any,
        active: false
    };

    select = {
        interaction: undefined as any,
        active: false
    };

    edit = {
        interaction: undefined as any,
        active: false
    };

    measure = {
        interaction: undefined as any,
        lineActive: false,
        surfaceActive: false,
        active: false
    };

    polyinfo = {
        interaction: undefined as any,
        active: false
    };

    circleinfo = {
        interaction: undefined as any,
        active: false
    };

    buffer = {
        interaction: undefined as any,
        active: false
    };

    queryPoly = {
        interaction: undefined as any,
        active: false
    };

    // Used to move features
    translate = {
        interaction: undefined as any,
        active: false
    };

    clusterClick = {
        interaction: undefined as any
    };

    duplicate = {
        interaction: undefined as any
    };

    deleteRedline = {
        interaction: undefined as any,
        active: false
    };

    deleteMeasure = {
        interaction: undefined as any,
        active: false
    };

    interactions = {
        draw: this.draw,
        create: this.create,
        select: this.select,
        edit: this.edit,
        vrPhoto: this.vrPhoto,
        measure: this.measure,
        info: this.info,
        cyclo: this.cyclo,
        oblique: this.oblique,
        getCoordinate: this.getCoordinate,
        polyinfo: this.polyinfo,
        circleinfo: this.circleinfo,
        queryPoly: this.queryPoly,
        translate: this.translate,
        duplicate: this.duplicate,
        deleteRedline: this.deleteRedline,
        deleteMeasure: this.deleteMeasure
    };

    constructor(
        private readonly mapService: MapService,
        private readonly bottomSheet: MatBottomSheet,
        private editService: EditService
    ) {}

    // Remove interactions added to the map previously
    removeInteractions(): void {
        for (const i of Object.keys(this.interactions)) {
            const type = this.interactions[i];

            if (type?.interaction) {
                console.log(type.interaction)
                this.mapService.map().removeInteraction(type.interaction);
                if (type?.interaction.listener) {
                    this.mapService
                        .map()
                        .un(type.interaction.type, type.interaction.listener);
                }

                // Make sure we have a undefined value instead of an empty object {}
                type.interaction = undefined;
            }
            if (type?.active) {
                type.active = false;
            }
        }

        this.editService.feature.set(undefined);

        this.bottomSheet.dismiss();
        if (document.getElementById('cook_map'))
            document.getElementById('cook_map').style.cursor = 'default';
    }
}
