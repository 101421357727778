<h1 mat-dialog-title>Geschiedenis Bekijken</h1>

<mat-dialog-content>
    <mat-accordion multi="true">
        <mat-expansion-panel *ngFor="let time of getHistoryTimes()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>access_time</mat-icon>&nbsp;&nbsp;<span
                        class="date"
                    >
                        {{ time | date: 'yyyy-MM-dd HH:mm:ss' }}
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-card>
                <mat-card-content style="padding: 8px">
                    <div
                        *ngFor="let valueItem of getValuesForTime(time)"
                        style="margin-bottom: 4px"
                    >
                        <strong>{{ valueItem.item_name }}: </strong>
                        <span>{{ getValueDisplay(valueItem) }}</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>

<mat-dialog-content *ngIf="getHistoryTimes().length === 0" class="p-4">
    <p>Geen wijzigingen gevonden.</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
</mat-dialog-actions>
