import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from 'app/_services';

@Component({
    selector: 'settings-dialog',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.css']
})
/**
 * This is a dialog with possible settings
 */
export class SettingsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<SettingsDialogComponent>,
        public configService: ConfigService
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
