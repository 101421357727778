import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { TableColumn } from '@cubejs-client/core';
import { Subject } from 'rxjs';

import { CubejsClient } from '@cubejs-client/ngx';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
    selector: 'chart-renderer',
    templateUrl: './chart-renderer.component.html',
    styleUrls: ['./chart-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @shouldRemove add description
 */
export class ChartRendererComponent implements OnInit {
    @Input() chartType;
    @Input() query;
    @Input() config;

    public chartData;
    public chartLabels;
    public chartOptions: any = {
        responsive: true
    };
    public ready = false;
    public showChart = false;
    public chartColors;
    public querySubject;

    public _cubejs: CubejsClient;

    columnTitles: string[] = [];
    displayedColumns: string[] = [];

    public lineChartColors = [
        {
            borderColor: '#7DB3FF',
            backgroundColor: 'rgba(106, 110, 229, .16)'
        }
    ];

    public barChartColors = [
        {
            borderColor: '#7DB3FF',
            backgroundColor: '#7DB3FF'
        },
        {
            borderColor: '#49457B',
            backgroundColor: '#49457B'
        },
        {
            borderColor: '#FF7C78',
            backgroundColor: '#FF7C78'
        }
    ];

    private numberFormatter = x => x.toLocaleString();

    constructor(
        private http: HttpClient,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.http
            .get(environment.api_base_url + `/key/cubeJs`, {
                observe: 'response',
                responseType: 'json'
            })
            .subscribe((response: any) => {
                if (response.body) {
                    this._cubejs = new CubejsClient({
                        token: response.body,
                        options: {
                            apiUrl: environment.cubejs_url + '/cubejs-api/v1'
                        }
                    });
                }
                this.showChart = this.chartType !== 'singleValue';
                this.querySubject = new Subject();
                this.resultChanged = this.resultChanged.bind(this);
                this._cubejs
                    .watch(this.querySubject)
                    .subscribe(this.resultChanged, err =>
                        console.log('HTTP Error', err)
                    );

                this.querySubject.next(this.query);
                this.cdr.detectChanges();
            });
    }

    commonSetup(resultSet) {
        // .map(this.dateFormatter)
        this.chartLabels = resultSet.chartPivot().map(row => row.x);
        console.log(this.chartLabels);
        this.chartData = resultSet.seriesNames().map(({ key, title }) => ({
            data: resultSet.chartPivot().map(element => element[key]),
            label: title
        }));
    }

    setLineChartData() {
        this.chartOptions = {
            ...this.chartOptions,
            scales: {
                xAxes: [
                    {
                        ticks: {
                            maxTicksLimit: 4,
                            maxRotation: 0
                        }
                    }
                ]
            },
            legend: {
                display: false
            }
        };
        this.chartColors = this.lineChartColors;
    }

    setPieChartData() {}

    setStackedBarChartData() {
        this.chartType = 'bar';
        this.chartColors = this.barChartColors;
        this.chartOptions = {
            ...this.chartOptions,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            maxTicksLimit: 4,
                            maxRotation: 0
                        }
                    }
                ],
                yAxes: [{ stacked: true }]
            },
            legend: {
                position: 'bottom'
            }
        };
    }

    resultChanged(resultSet) {
        this.commonSetup(resultSet);
        if (this.chartType === 'line') {
            this.setLineChartData();
        } else if (this.chartType === 'pie') {
            this.setPieChartData();
        } else if (this.chartType === 'stackedBar') {
            this.setStackedBarChartData();
        } else if (this.chartType === 'singleValue') {
            this.chartData = this.numberFormatter(
                resultSet.chartPivot()[0][resultSet.seriesNames()[0].key]
            );
        } else if (this.chartType === 'table') {
            this.chartData = resultSet.tablePivot(this.config);
            this.displayedColumns = this.getDisplayedColumns(
                resultSet.tableColumns(this.config)
            );
            this.columnTitles = this.flattenColumns(
                resultSet.tableColumns(this.config)
            );
        }
        this.ready = true;
        this.cdr.detectChanges();
    }

    getDisplayedColumns(tableColumns: TableColumn[]): string[] {
        const queue = tableColumns;
        const columns = [];

        while (queue.length) {
            const column = queue.shift();

            if (column.dataIndex) {
                columns.push(column.dataIndex);
            }

            if ((column.children || []).length) {
                column.children.map(child => queue.push(child));
            }
        }

        return columns;
    }

    flattenColumns(columns: TableColumn[] = []) {
        return columns.reduce((memo, column) => {
            const titles = this.flattenColumns(column.children);
            return [
                ...memo,
                ...(titles.length
                    ? titles.map(title => column.shortTitle + ', ' + title)
                    : [column.shortTitle])
            ];
        }, []);
    }
}
