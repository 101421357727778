import { Component, ViewEncapsulation } from '@angular/core';
import { MapService } from '../../_services/map.service';
import { LayerService } from '../../_services/layer.service';
import { InteractionService } from '../../_services/interaction.service';
import { faVectorSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Polygon, LineString } from 'ol/geom';
import Draw from 'ol/interaction/Draw';
import { Stroke, Style, Fill, Circle } from 'ol/style.js';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay.js';

@Component({
    selector: 'measure',
    templateUrl: 'measure.component.html',
    styleUrls: ['measure.component.scss'],
    encapsulation: ViewEncapsulation.None
})
/**
 * With this tool you can measure the length of a line or the surface of a polygon
 * On the right of the Map
 */
export class MeasureComponent {
    // fontawsome import
    faVectorSquare = faVectorSquare;
    readonly faTimes = faTimes;

    /**
     * Currently drawn feature.
     * @type {ol.Feature}
     */
    sketch: any;

    /**
     * The measure tooltip element.
     * @type {Element}
     */
    measureTooltip: any;

    /**
     * The measure tooltip element.
     * @type {Element}
     */
    measureTooltipElement: any;

    /**
     * Keeps all the overlay tooltips so we can delete them later on
     * @type {[type]}
     */
    tooltips: any[] = [];

    /**
     * Tool status, used for active class
     * @type {Boolean}
     */
    geometryType: any;

    constructor(
        private mapService: MapService,
        private layerService: LayerService,
        public interactionService: InteractionService
    ) {}

    measure(geometryType: string): void {
        // First, remove all previous interactions if not active
        if (geometryType == this.geometryType) {
            this.interactionService.removeInteractions();
            this.geometryType = undefined;
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.measure.active =
                !this.interactionService.measure.active;
        }

        if (this.interactionService.measure.active) {
            // Toggle activity
            if (geometryType == 'LineString') {
                this.geometryType = geometryType;
                this.interactionService.measure.lineActive = true;
                this.interactionService.measure.surfaceActive = false;
            } else if (geometryType == 'Polygon') {
                this.geometryType = geometryType;
                this.interactionService.measure.lineActive = false;
                this.interactionService.measure.surfaceActive = true;
            }

            document.getElementById('cook_map').style.cursor = 'crosshair';

            // Now create our new interaction
            this.addInteraction(geometryType);
        } else {
            document.getElementById('cook_map').style.cursor = 'default';
            this.geometryType = undefined;
        }
    }

    /**
     * Format length output.
     * @param {ol.geom.LineString} line The line.
     * @return {string} The formatted length.
     */
    formatLength(line: any): string {
        const length = Math.round(line.getLength() * 100) / 100;

        let output: string;
        if (length != null) {
            output = Math.round(length * 100) / 100 + ' ' + 'm';
        }
        return output;
    }

    /**
     * Format area output.
     * @param {ol.geom.Polygon} polygon The polygon.
     * @return {string} Formatted area.
     */
    formatArea(polygon: any): any {
        const area = polygon.getArea();

        let output: any;
        if (area != null) {
            output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
        }
        return output;
    }

    addInteraction(type): void {
        this.interactionService.measure.interaction = new Draw({
            source: this.layerService.measurementLayer().getSource(),
            type,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.5)',
                    lineDash: [10, 10],
                    width: 2
                }),
                image: new Circle({
                    radius: 5,
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.7)'
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    })
                })
            })
        });
        this.mapService
            .map()
            .addInteraction(this.interactionService.measure.interaction);

        this.createMeasureTooltip();

        let listener: any;
        this.interactionService.measure.interaction.on(
            'drawstart',
            (evt: any) => {
                // set sketch
                this.sketch = evt.feature;

                let tooltipCoord = evt.coordinate;

                listener = this.sketch
                    .getGeometry()
                    .on('change', (evt: any) => {
                        const geom = evt.target;
                        let output: any;
                        if (geom instanceof Polygon) {
                            output = this.formatArea(geom);
                            tooltipCoord = geom
                                .getInteriorPoint()
                                .getCoordinates();
                        } else if (geom instanceof LineString) {
                            output = this.formatLength(geom);
                            tooltipCoord = geom.getLastCoordinate();
                        }
                        this.measureTooltipElement.innerHTML = output;
                        this.measureTooltip.setPosition(tooltipCoord);
                    });
            }
        );

        this.interactionService.measure.interaction.on('drawend', event => {
            // Unset sketch
            this.sketch = null;

            event.feature.set(
                'text',
                this.measureTooltipElement.textContent ||
                    this.measureTooltipElement.innerText ||
                    ''
            );

            // Remove this tooltip now we have the label set
            this.measureTooltipElement.parentNode.removeChild(
                this.measureTooltipElement
            );

            this.measureTooltipElement = null;

            this.createMeasureTooltip();
            unByKey(listener);
        });
    }

    /**
     * Creates a new measure tooltip
     */
    createMeasureTooltip(): void {
        if (this.measureTooltipElement) {
            this.measureTooltipElement.parentNode.removeChild(
                this.measureTooltipElement
            );
        }
        const positioning: any = 'bottom-center';
        this.measureTooltipElement = document.createElement('div');
        this.measureTooltipElement.className = 'tooltip tooltip-measure';
        this.measureTooltip = new Overlay({
            element: this.measureTooltipElement,
            offset: [0, -15],
            positioning
        });

        this.mapService.map().addOverlay(this.measureTooltip);
    }

    clear(): void {
        // Clear the interaction
        this.geometryType = null;

        if (this.interactionService.deleteMeasure.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();

            this.interactionService.deleteMeasure.active = true;
            this.interactionService.deleteMeasure.interaction = this.mapService
                .map()
                .on('singleclick', evt => {
                    this.mapService
                        .map()
                        .forEachFeatureAtPixel(evt.pixel, f => {
                            if (
                                this.layerService
                                    .measurementLayer()
                                    .getSource()
                                    .hasFeature(f)
                            ) {
                                this.layerService
                                    .measurementLayer()
                                    .getSource()
                                    .removeFeature(f);
                            }
                        });
                });
        }
    }
}
