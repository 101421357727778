<mat-tab-group mat-stretch-tabs>
    <mat-tab label="Opgeslagen Locaties">
        <setting-custom-extent></setting-custom-extent>
    </mat-tab>

    <mat-tab label="Legenda">
        <setting-custom-legend></setting-custom-legend>
    </mat-tab>

    <mat-tab
        label="Gedeelde configuraties"
        *ngIf="configService.config()?.options?.share"
    >
        <shared-config-overview></shared-config-overview>
    </mat-tab>
</mat-tab-group>
