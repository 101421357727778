import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    imageSrc: string;
    videoSrc: string;
}
/**
 * @shouldRemove
 * Small media dialog
 * Dont know where this is used
 */
@Component({
    selector: 'media-dialog',
    templateUrl: 'media.dialog.html'
})
export class MediaDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
