<form [formGroup]="resetUserPasswordFormGroup" (ngSubmit)="changePassword()">
    <div class="p-3">
        <h2>Wachtwoord wijzigen</h2>
        <br /><br />
        <div class="input-group">
            <input
                class="input-password form-control"
                autocomplete="current-password"
                placeholder="Nieuw wachtwoord"
                value=""
                formControlName="newPassword"
                type="password"
                [type]="hide_new ? 'password' : 'text'"
                required
                i18n-placeholder
            />
            <div
                class="input-group-append float-start"
                (click)="hide_new = !hide_new"
            >
                <div class="input-group-text">
                    <mat-icon>{{
                        hide_new ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
                </div>
            </div>
        </div>

        <div class="input-group">
            <input
                class="input-password form-control"
                autocomplete="current-password"
                placeholder="Herhaal het nieuwe wachtwoord"
                value=""
                formControlName="repeatedPassword"
                type="password"
                [type]="hide_repeated ? 'password' : 'text'"
                required
                i18n-placeholder
            />
            <div
                class="input-group-append m-0"
                (click)="hide_repeated = !hide_repeated"
            >
                <div class="input-group-text">
                    <mat-icon>{{
                        hide_repeated ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="float-end">
        <button (click)="back()" mat-raised-button i18n>Terug</button>
        <button mat-raised-button color="primary" type="submit" i18n>
            Bevestigen
        </button>
    </div>
</form>
