<header></header>

<section class="header-section">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="my-0" i18n>Formulier registraties</h1>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="table-responsive">
            <table
                matSort
                mat-table
                [matSortActive]="sort.active"
                [matSortDirection]="sort.direction"
                [dataSource]="dataSource()"
                #sort="matSort"
            >
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
                        Gemaakt op
                    </th>
                    <td mat-cell *matCellDef="let form">
                        {{ form.created_at }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="created_by">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
                        Gemaakt door
                    </th>
                    <td mat-cell *matCellDef="let form">
                        {{ form.created_by ? form.created_by : 'Onbekend' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>Details</th>
                    <td mat-cell *matCellDef="let form">
                        <button
                            mat-stroked-button
                            (click)="openDetailDialog(form.value)"
                        >
                            View Details
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
            </table>
        </div>

        <mat-divider class="my-3"></mat-divider>

        <div class="row">
            <div class="col-12 col-md-6">
                <button
                    mat-raised-button
                    color="secondary"
                    [routerLink]="['/admin', 'forms', 'manage']"
                    i18n
                >
                    Terug
                </button>
            </div>
        </div>
    </div>
</section>
