<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> DWG </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div
            class="drag-drop-area"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
        >
            <label for="txtFileUpload" class="form-label"
                >Sleep hier je DWG-bestanden of klik om te uploaden</label
            >
            <input
                type="file"
                class="form-control"
                #dwgReader
                name="UploadDWG"
                id="txtFileUpload"
                (change)="uploadListener($event)"
                accept=".dwg"
            />
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 1" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Selecteer laag </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field>
            <mat-label>Formaat</mat-label>
            <mat-select [(ngModel)]="selectedLayerName">
                <mat-option *ngFor="let key of layerNames" [value]="key">{{
                    key
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="col float-end">
            <button
                mat-raised-button
                color="primary"
                (click)="selectLayer()"
                i18n
            >
                Volgende
            </button>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 2" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Result </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="resourceFormGroup" (ngSubmit)="onSubmit()">
            <div
                *ngIf="name.invalid && (name.dirty || name.touched)"
                class="alert alert-danger"
            >
                Naam is verplicht.
            </div>
            <mat-form-field class="w-50">
                <mat-label>Naam</mat-label>
                <input matInput type="text" formControlName="name" />
            </mat-form-field>

            <div class="col float-end">
                <button
                    mat-raised-button
                    [disabled]="resourceFormGroup.invalid"
                    color="primary"
                    type="submit"
                    i18n
                >
                    Opslaan
                </button>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>
