import {
    Component,
    Input,
    ViewChildren,
    QueryList,
    Output,
    EventEmitter,
    signal,
    ChangeDetectionStrategy
} from '@angular/core';
import { LegendService } from 'app/_services';
import { LegendLayerComponent } from '../layer/layer.component';
import {
    faAngleRight,
    faAngleDown,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'cook-group',
    templateUrl: 'group.component.html',
    styleUrls: ['group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * There are two kinds of layers in OpenLayers and the LayerSwitcherComponent
 * The LayerGroup, which is a group of paramLayers that are contained within one actual Layer. Using the updateLayer
 * function we can change these so the paramLayers in the group are toggled on or off. These paramLayers are toggled
 * on or off by adding/removing them from the layer itself.
 */
export class LegendGroupComponent {
    readonly faAngleRight = faAngleRight;
    readonly faAngleDown = faAngleDown;
    readonly faInfoCircle = faInfoCircle;

    readonly checked = signal(false);
    readonly collapsed = signal(false);

    readonly label = signal(undefined);
    readonly layerName = signal(undefined);
    readonly metadata_url = signal(undefined);
    readonly layers = signal([]);

    @ViewChildren(LegendLayerComponent)
    readonly childLayers: QueryList<LegendLayerComponent>;

    @Input() readonly group: any;
    @Input() readonly map: any;
    @Output() readonly checkEvent = new EventEmitter<boolean>();

    constructor(private readonly legendService: LegendService) {}

    ngOnInit(): void {
        this.checked.set(!!this.group.visible);
        this.collapsed.set(!!this.group.collapsed);
        this.label.set(this.group.name);
        this.layerName.set(this.group.layerName);
        this.metadata_url.set(this.group.metadata_url);

        if (Array.isArray(this.group.layers) && this.group.layers.length > 0) {
            this.layers.set(this.group.layers.sort((a, b) => a.sort - b.sort));
        }

        this.syncGroupVisibility();
    }

    toggleGroup(): void {
        this.group.visible = !this.group.visible;
        // If there are layers with names (layers that don't just have a label purpose), update them
        const hasNamedLayers = this.childLayers.some(
            l => typeof l.name() === 'string'
        );

        if (hasNamedLayers) {
            // Update all children to match the checked property of the group
            this.childLayers.forEach(layer => {
                layer.layer.visible = this.group.visible;

                const isVisible = this.legendService.isLayerVisible(
                    layer.layer,
                    layer.map
                );

                if (this.group.visible !== isVisible) {
                    this.legendService.toggleLayer(layer.layer, layer.map);
                }

                layer.checked.set(this.group.visible);
            });
        } else if (this.layerName()) {
            // Treat this group like a layer and toggle it accordingly
            this.syncGroupVisibility();
        }
        // add the changes to customlegend to save it later
        this.legendService.customMap[this.map.name] = this.map;
    }

    viewChildChecked(checked: boolean): void {
        const shouldCheck = checked && !this.checked();
        const childLayersArray = this.childLayers.toArray();
        const hasCheckedChildren = childLayersArray.some(l => l.checked());
        const shouldUncheck = !checked && !hasCheckedChildren;

        if (shouldCheck) {
            this.checked.set(true);
            this.group.visible = true;
            this.legendService.toggleLayer(this.group, this.map);
            this.legendService.customMap[this.map.name] = this.map;
        } else if (shouldUncheck) {
            this.checked.set(false);
            this.group.visible = false;
            this.legendService.toggleLayer(this.group, this.map);
            this.legendService.customMap[this.map.name] = this.map;
        }

        this.checkEvent.emit(this.group.visible);
    }

    /**
     * Triggers when the checkbox is checked by a user and emits the event to the parent
     */
    checkboxChanged(event: Event): void {
        // If this item is checked now, emit an event so the map knows and can check accordingly
        this.checked.set(this.group.visible);
        this.checkEvent.emit(this.group.visible);
    }

    /**
     * Collapse the layers
     */
    toggleCollapse(): void {
        this.collapsed.set(!this.collapsed());
        this.group.collapsed = this.collapsed();
        this.legendService.customMap[this.map.name] = this.map;
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.layers(), event.previousIndex, event.currentIndex);

        this.layers().forEach((map, index) => {
            if (map.sort !== index) {
                map.sort = index;
            }
        });

        this.legendService.customMap[this.map.name] = this.map;
    }

    private syncGroupVisibility(): void {
        const isVisible = this.legendService.isGroupVisible(
            this.group,
            this.map
        );

        if (this.group.visible !== isVisible) {
            this.legendService.toggleLayer(this.group, this.map);
        }
    }
}
