import { HttpClient } from '@angular/common/http';
import { Component, OnInit, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

type SharedConfig = {
    id: number;
    created_by: number;
    extent: string;
    legend_settings: string;
    expire: string;
    created_at: string;
    updated_at: string;
    option_id: number;
}

@Component({
    selector: 'shared-config-overview',
    templateUrl: 'shared-config-overview.component.html',
    styleUrls: ['shared-config-overview.component.scss']
})
export class SharedConfigOverviewComponent implements OnInit {
    readonly sharedConfigs = signal<SharedConfig[]>([]);

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly dialogRef: MatDialogRef<SharedConfigOverviewComponent>
    ) {}

    ngOnInit(): void {
        this.loadSharedConfigs();
    }

    private loadSharedConfigs(): void {
        const url = `${environment.api_base_url}/shared/personal`;

        this.http.get(url).subscribe({
            next: (response: SharedConfig[]) => {
                console.log('Shared configs loaded', response);
                this.sharedConfigs.set(response);
            },
            error: error => {
                console.error('Error loading shared configs', error);
            }
        });
    }

    loadConfig(sharedConfig: SharedConfig): void {
        const id = sharedConfig.id;

        this.router.navigate(['/shared', id]).then(() => {
            this.dialogRef.close();
            // @TODO: properly handle loading of shared config
            window.location.reload();
        });
    }
}
