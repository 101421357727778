import { Component, signal } from '@angular/core';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { GetCapabilitiesService } from '../../admin/map/create/services/getCapabilities.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ConfigService, MapService } from 'app/_services';
import { MapCreatorDialogComponent } from '../map-creator.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'from-wms',
    templateUrl: './from-wms.component.html',
    styleUrls: ['./from-wms.component.scss']
})
export class MapFromWMSComponent {
    step = 0;
    wmsServiceUrl: string;
    capabilities: any;
    layers = signal([]);
    selectedLayers = signal([]);
    name = new FormControl('', Validators.required);
    description = new FormControl('');
    metadata_url = new FormControl('');

    readonly resourceFormGroup = new FormGroup({
        name: this.name,
        description: this.description,
        metadata_url: this.metadata_url
    });
    error: string;

    constructor(
        private getCapabilitiesService: GetCapabilitiesService,
        // private fb: FormBuilder,
        private mapService: MapService,
        private http: HttpClient,
        private configService: ConfigService,
        public dialogRef: MatDialogRef<MapCreatorDialogComponent>

    ) {}

    fetchWMSCapabilities() {
        this.http
            .get(this.wmsServiceUrl, {
                responseType: 'text'
            })
            .subscribe({
                next: data => {
                    this.capabilities = this.getCapabilitiesService.handle(
                        data,
                        'mapserver'
                    );

                    this.layers.set(this.capabilities.succes.layers);
                    this.step = 1;
                },
                error: error => {
                    this.error = error;
                }
            });
    }

    selectLayers() {
        this.selectedLayers.set(this.layers().filter(layer => layer.selected));
        this.step = 2;
    }

    onSubmit() {
        console.log(this.resourceFormGroup)
        if (this.resourceFormGroup.valid) {
            const body: any = this.resourceFormGroup.getRawValue();

            // this.selectedLayers().forEach(l => {
            //     layers.push({
            //         name: l,
            //         label: l,
            //         click: true
            //     })
            // });
            
            body.collapsed = true;
            body.visible = true;
            body.order = 50;
            body.hide = false;
            body.click = 'grid';
            body.grid = {};
            body.type = 'Image'
            body.source = {
                type: 'ImageWMS',
                url:  this.wmsServiceUrl,
            };
            body.layers = this.selectedLayers()
                
            this.http
                .post(`${environment.api_base_url}/maps`, body)
                .toPromise()
                .then((res: any) => {
                    // this.changeLoading.emit(false);
                    this.dialogRef.close();

                    this.bindToConfig(res.id);
                })
                .catch(err => {
                    console.error(err);
                    // this.changeLoading.emit(false);
                });
        }
    }

    bindToConfig(map_id): void {
        const body = {
            map_id,
            config_id: this.configService.config().id
        };

        this.http
            .post(`${environment.api_base_url}/maps/map-to-config`, body)
            .toPromise()
            .then((res: any) => {
                this.configService.config.set(undefined);
                this.mapService.map.set(undefined);
                this.configService.loadConfiguration();
            })
            .catch(err => {
                console.error(err);
            });
    }

}
