import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    WritableSignal,
    signal
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'cook-multi-factor-auth',
    templateUrl: './multi-factor-auth.dialog.html',
    styleUrls: ['./multi-factor-auth.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Dialog for 2FA
 */
export class MultiFactorAuthDialogComponent implements OnInit {
    readonly qrcode: WritableSignal<SafeHtml> = signal(undefined);
    readonly recoverCodes = signal(undefined);
    readonly twoFactorEnabled = signal(false);

    constructor(
        private readonly http: HttpClient,
        private readonly sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        const url = environment.api_base_url + '/remote-2fa/status';

        this.http.get(url).subscribe({
            next: (res: any) => {
                this.twoFactorEnabled.set(res.enabled);
            }
        });
    }

    enable2FA(): void {
        const url = environment.base_url + '/user/two-factor-authentication';

        this.http.post(url, {}).subscribe({
            next: () => {
                const urlQr = environment.base_url + '/user/two-factor-qr-code';

                this.http.get(urlQr).subscribe({
                    next: (res: any) => {
                        this.qrcode.set(
                            this.sanitizer.bypassSecurityTrustHtml(res.svg)
                        );
                    }
                });

                const urlRecoverCodes =
                    environment.base_url + '/user/two-factor-recovery-codes';

                this.http.get(urlRecoverCodes).subscribe({
                    next: (res: any) => {
                        this.recoverCodes.set(res);
                    }
                });
                this.twoFactorEnabled.set(true);
            }
        });
    }

    disable2FA(): void {
        const url = environment.base_url + '/user/two-factor-authentication';

        this.http.delete(url).subscribe({
            next: () => {
                this.qrcode.set(undefined);
                this.recoverCodes.set(undefined);
                this.twoFactorEnabled.set(false);
            }
        });
    }
}
