import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
/**
 * All the custom extent functions are in this service
 */
export class CustomExtentService {
    readonly customExtents = signal(undefined);

    constructor(private http: HttpClient) {}

    loadExtents(): void {
        this.http
            .get(environment.api_base_url + '/extents', {
                responseType: 'json'
            })
            .toPromise()
            .then(res => {
                this.customExtents.set(res);
                this.customExtents().sort((a, b) => (a.name < b.name ? -1 : 1));
            })
            .catch();
    }

    loadPublicExtents(config_id): void {
        this.http
            .get(environment.api_base_url + '/public-extents/' + config_id, {
                responseType: 'json'
            })
            .toPromise()
            .then(res => {
                this.customExtents.set(res);
                this.customExtents().sort((a, b) => (a.name < b.name ? -1 : 1));
            })
            .catch();
    }

    deleteCustomExtent(id): void {
        this.http
            .delete(environment.api_base_url + '/extents/' + id, {
                responseType: 'json'
            })
            .toPromise()
            .then(res => {
                this.loadExtents();
            })
            .catch();
    }

    storeCustomExtent(body): void {
        // If its a public config store it to the config instead of the user
        this.http
            .post(environment.api_base_url + '/extents', body, {
                responseType: 'json'
            })
            .toPromise()
            .then(res => {
                this.loadExtents();
            })
            .catch();
    }
}
