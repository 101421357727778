<div class="login-screen">
    <div class="container">
        <div class="card card-container">
            <img
                id="profile-img"
                class="profile-img-card mt-2"
                src="{{ environment.subDirectory }}/assets/img/logo_{{
                    environment.loginPrefix
                }}.png"
            />
            <h5>Two factor authenticatie</h5>
            <p id="profile-name" class="profile-name-card">{{ error() }}</p>
            <form class="form-group" name="loginForm" (ngSubmit)="sendCode()">
                <span id="reauth-email" class="reauth-email"></span>
                <input
                    type="text"
                    id="inputUsername"
                    autocomplete="code"
                    class="form-control"
                    placeholder="Two factor code"
                    required
                    autofocus
                    name="code"
                    [(ngModel)]="totpcode"
                />
                <button
                    class="btn btn-lg btn-primary btn-block btn-signin"
                    type="submit"
                >
                    Verzenden
                </button>
            </form>
            <!-- /form -->
            <br />
            <!-- <a routerLink="/password/reset" class="forgot-password">Wachtwoord vergeten?</a> -->
            <a href="https://www.gisarts.nl/" target="_blank"
                ><img
                    src="{{
                        environment.subDirectory
                    }}/assets/img/logo_gisarts.png"
                    class="logo_gisarts"
            /></a>
        </div>
        <!-- /card-container -->
    </div>
    <!-- /container -->
    <!-- @shouldRemove -->
    <!-- <img id="image" src="{{randomImg}}"> -->
    <div
        id="image"
        *ngIf="configService.backgroundImage"
        [style.backgroundImage]="'url(' + configService.backgroundImage + ')'"
    ></div>
    <!-- @shouldRemove -->
    <!-- <video muted autoplay loop id="myVideo" class="fade-in" *ngIf="configService.backgroundVideo">
      <source [src]="configService.backgroundVideo" type="video/mp4">
    </video> -->
</div>
