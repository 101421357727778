import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chart } from 'chart.js';

@Component({
    selector: 'edit-grid-dialog',
    templateUrl: './edit-grid.dialog.html',
    styleUrls: ['./edit-grid.dialog.scss']
})

export class EditGridDialog implements AfterViewInit, OnDestroy {
    loading = false;

    readonly chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };

    @ViewChild('baseChart', {static: false}) chartCanvas;

    chartType: string;
    
    selectedColumn;
    chart: Chart;
    constructor(
        public dialogRef: MatDialogRef<EditGridDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (this.data.graphInfo?.x) {
            this.selectedColumn = this.data.graphInfo?.x;
        } else {
            this.selectedColumn = this.data.columnNames[0];
        }
    }

    ngAfterViewInit() {
        this.createChart();
    }

    onNoClick(): void {
        if (this.data.graphInfo) this.data.graphInfo.x = this.selectedColumn;

        this.dialogRef.close(this.data);
    }

    onTypeChange(type: any) {
        this.data.type = type;
        if (this.data.type !== 'columns') {
            this.createChart();
        }
    }

    
    createChart() {
        if (this.chart) {
            this.chart.destroy();
            const canvas = this.chartCanvas.nativeElement;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = canvas.width; 
        } 

        if (this.chartCanvas && this.chartCanvas.nativeElement) {
            this.chart = new Chart(this.chartCanvas.nativeElement, {
                type: this.data.type,
                data: {
                    labels: this.data.labels,
                    datasets: [this.data.graph]
                },
                options: this.chartOptions
            });
        }
    }

    chartFunctionChange(value) {

    }

    columnChange(column) {
        const counting = {};
        const count = [];

        for (const currentObject of this.data.rawData) {
            const result = currentObject[column];
            counting[result] = (counting[result] || 0) + 1;
        }

        console.log('resultaat', count)
        count.push(...Object.values(counting));
        this.data.labels = [];
        this.data.labels.push(...Object.keys(counting));
        this.data.labels.sort((a, b) => a - b);
        this.data.graph = {
            data: count,
            // backgroundColor: this.chartColors
        };

        this.createChart();
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    }

    average(data) {
        const sum = data.reduce((a, b) => a + b, 0);
        return sum / data.length;
    }
    
    sum(data) {
        return data.reduce((a, b) => a + b, 0);
    }
    
    stdDev(data) {
        const mean = this.average(data);
        const squaredDiffs = data.map(value => Math.pow(value - mean, 2));
        const avgSquaredDiff = this.average(squaredDiffs);
        return Math.sqrt(avgSquaredDiff);
    }

    groupDataByDay(data) {
        const groupedData = {};
    
        data.forEach(item => {
            const date = item.date;
            if (!groupedData[date]) {
                groupedData[date] = 0;
            }
            groupedData[date] += item.value;
        });
    
        return groupedData;
    }
}
