import { Component, EffectRef, OnDestroy, effect } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    InteractionService,
    ConfigService,
    SidenavService
} from 'app/_services';
import * as FileSaver from 'file-saver';
import { environment } from 'environments/environment';

@Component({
    selector: 'cook-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss']
})
/**
 * Tool for downloading
 * On the right of the Map
 */
export class DownloadComponent implements OnDestroy {
    files: Array<any> = [];
    configSubscription: EffectRef;
    downloadUrl: string;
    config: any;

    constructor(
        private readonly http: HttpClient,
        protected readonly configService: ConfigService,
        public readonly interactionService: InteractionService,
        private readonly sideNavService: SidenavService
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();

                this.files = [];
                this.config = config.tools.downloads;
                this.getDownload();
            },
            { allowSignalWrites: true }
        );

        this.sideNavService.setWidth(380, 'px');
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    download(value): void {
        // remove the underscore and navigate to the right dir
        const fileLocation = `${environment.loginPrefix.slice(0, -1)}/${value}`;
        // And encode the value
        let url: string;
        if (this.config.public) {
            url = environment.api_base_url + '/public-download/';
        } else {
            url = environment.api_base_url + '/download/';
        }
        const encodedValue = btoa(fileLocation);
        this.http
            .get(url + encodedValue, { responseType: 'blob' })
            .subscribe(x => {
                value = value.substring(value.lastIndexOf('/') + 1);
                this.save(x, value);
            });
    }

    getDownload(): void {
        let url: string;
        if (this.config.public) {
            url =
                environment.api_base_url +
                '/public-download/list/' +
                environment.loginPrefix.slice(0, -1);
        } else {
            url = environment.api_base_url + '/download';
        }
        this.http.get(url, { responseType: 'json' }).subscribe(
            (response: any) => {
                response.forEach(file => {
                    file = file.substr(environment.loginPrefix.length);

                    if (!file.indexOf(this.config.downloadUrl + '/')) {
                        this.files.push(file);
                    }
                });
            },
            error => {
                console.log('Error', error);
            }
        );
    }

    /**
     * Save the file as an XLS
     * @param  any the XLS blob
     */
    save(file: any, name: string): void {
        FileSaver.saveAs(file, name);
    }
}
