import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'form_list',
    templateUrl: './form_list.component.html',
    styleUrls: ['./form_list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormListComponent {
    readonly columns = ['created_by', 'created_at', 'value'];

    readonly total = signal(0);
    readonly dataSource = signal(undefined);
    readonly name = signal('');
    readonly id = this.route.snapshot.paramMap.get('id');
    private readonly url = `${environment.api_base_url}/forms/list_values/${this.id}`;

    parent_form: any;

    constructor(
        private readonly http: HttpClient,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialog: MatDialog
    ) {
        if (!this.id) {
            this.router.navigate(['/admin/forms']);
        }

        this.http.get(environment.api_base_url + '/forms/' + this.id).subscribe({
            next: (result: any) => {
                this.parent_form = result;
            },
            error: error => {
                console.error('Error fetching data:', error);
            }
        });

        this.http.get(this.url).subscribe({
            next: (result: any) => {
                console.log(result);
                this.dataSource.set(result.data);
                this.total.set(result.total);
                this.name.set(result.name);
            },
            error: error => {
                console.error('Error fetching data:', error);
            }
        });
    }

    openDetailDialog(data: any): void {
        this.dialog.open(FormDetailDialog, {
            width: '50%',
            height: '75%',
            data: {
                data, 
                form: this.parent_form
            }
        });
    }
}

@Component({
    selector: 'form-detail-dialog',
    template: `
    <h1 mat-dialog-title>Form Details</h1>
    <div mat-dialog-content>
        <div *ngFor="let item of data.form.form_items">
            {{ item.name }}:
            <span *ngFor="let v of data.data | keyvalue">
                {{ item.id == v.key ? v.value : '' }}
            </span>
        </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Close</button>
    </div>
    `
})
export class FormDetailDialog {
    constructor(
        public dialogRef: MatDialogRef<FormDetailDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}