<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Web Mapping Service </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
            <div class="col">
                <h1>WMS Details</h1>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>WMS Service URL</mat-label>
                    <input matInput type="text" [(ngModel)]="wmsServiceUrl" />
                </mat-form-field>
                <button
                    mat-raised-button
                    color="primary"
                    (click)="fetchWMSCapabilities()"
                >
                    Fetch Capabilities
                </button>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        [expanded]="step === 1"
        hideToggle
        *ngIf="capabilities"
    >
        <div class="row">
            <div class="col-12">
                <h2>Beschikbare lagen</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-list>
                    <mat-list-item *ngFor="let layer of layers()">
                        <mat-checkbox [(ngModel)]="layer.selected">{{
                            layer.name
                        }}</mat-checkbox>
                    </mat-list-item>
                </mat-list>
                <button
                    mat-raised-button
                    color="primary"
                    (click)="selectLayers()"
                >
                    Next
                </button>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 2" hideToggle>
        <div class="row">
            <div class="col-12">
                <h2>Selected Layers</h2>
            </div>
        </div>

        <form [formGroup]="resourceFormGroup" (ngSubmit)="onSubmit()">
            <div class="row mb-3">
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <mat-label>Map Name</mat-label>
                        <input matInput type="text" formControlName="name" />
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="w-100">
                        <mat-label>Metadata URL</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="metadata_url"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Description</mat-label>
                        <textarea
                            matInput
                            formControlName="description"
                        ></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col float-end">
                    <button mat-raised-button color="primary" type="submit">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>
