import { Injectable, signal } from '@angular/core';
import { Map } from 'ol';
import { ScaleLine } from 'ol/control';
import { Projection } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is for changing the view of the map, for example, changing the scale
 */
export class MapService {
    projection: Projection;

    readonly zoomLevel = signal(0);
    readonly resolution = signal(0);
    readonly center = signal<Coordinate | undefined>(undefined);
    readonly map = signal<Map>(undefined);
    readonly scaleline = signal<ScaleLine>(undefined);

    /**
     * Track changes to the zoom level, resolution, center, extent, and rotation of the map
     */
}
