<div class="notifications">
    <div class="my-3">
        <h4>Meldingen</h4>
        <mat-button-toggle-group
            #filter="matButtonToggleGroup"
            hideSingleSelectionIndicator="true"
        >
            <mat-button-toggle value="Afgehandeld">
                <mat-icon class="text-success">done</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="In behandeling">
                <mat-icon class="">timeline</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="Uitgesteld">
                <mat-icon class="">query_builder</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="Geweigerd">
                <mat-icon class="text-danger">close</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <mat-accordion *ngIf="notifications; else noNotifications">
        <ng-container *ngFor="let notification of notifications">
            <mat-expansion-panel
                hideToggle
                *ngIf="!filter.value || filter.value === notification.status"
                class="my-2"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title
                        style="
                            width: 225px !important;
                            flex-grow: 0 !important;
                            flex-basis: 225px !important;
                        "
                        >{{ notification.subject }}</mat-panel-title
                    >
                    <mat-panel-description>
                        <mat-icon
                            *ngIf="notification.status === 'Afgehandeld'"
                            class="text-success"
                            >done</mat-icon
                        >
                        <mat-icon
                            *ngIf="notification.status === 'In behandeling'"
                            class=""
                            >timeline</mat-icon
                        >
                        <mat-icon
                            *ngIf="notification.status === 'Uitgesteld'"
                            class=""
                            >query_builder</mat-icon
                        >
                        <mat-icon
                            *ngIf="notification.status === 'Geweigerd'"
                            class="text-danger"
                            >close</mat-icon
                        >
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <b>Bericht</b>
                <p>{{ notification.message }}</p>
                <button
                    mat-raised-button
                    *ngIf="
                        notification.features && notification.features.length
                    "
                    (click)="loadView(notification.features)"
                    class="float-end"
                >
                    Bekijk element
                </button>
                <div *ngIf="notification.files" class="w-100">
                    <mat-list *ngFor="let file of notification.files">
                        {{ file.name }}
                        <button
                            mat-icon-button
                            (click)="download(file.path, file.name)"
                            class="light-icon"
                        >
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                    </mat-list>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
    <ng-template #noNotifications> Er zijn nog geen meldingen. </ng-template>
</div>
