import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormControl,
    Validators,
    FormBuilder,
    FormGroup
} from '@angular/forms';
import { ConfigService } from 'app/_services';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-create-notification',
    templateUrl: './create-notification.dialog.html',
    styleUrls: ['./create-notification.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * This component is made for letting a user create a notification
 */
export class CreateNotificationDialogComponent implements OnInit {
    error = '';
    email: FormControl;
    subject: FormControl;
    message: FormControl;
    createNotificationFormGroup: FormGroup;

    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly configService: ConfigService,
        private readonly route: ActivatedRoute,
        private readonly dialogRef: MatDialogRef<CreateNotificationDialogComponent>
    ) {}

    ngOnInit(): void {
        this.createFormControls();
        this.createForm();
    }

    createFormControls(): void {
        this.email = new FormControl('', Validators.required);
        this.subject = new FormControl('', Validators.required);
        this.message = new FormControl('', Validators.required);
    }
    createForm(): void {
        this.createNotificationFormGroup = this.formBuilder.group({
            email: this.configService.config().tools.notifications.email,
            subject: this.subject,
            message: this.message
        });
    }

    createNotification(): void {
        const body = this.createNotificationFormGroup.getRawValue();

        if (!body.email) {
            this.snackBar.open('U bent het emailadres vergeten', 'Ok', {
                duration: 3000
            });
            return;
        }
        if (!body.subject) {
            this.snackBar.open('U bent het onderwerp vergeten', 'Ok', {
                duration: 3000
            });
            return;
        }
        if (!body.message) {
            this.snackBar.open('U bent het bericht vergeten', 'Ok', {
                duration: 3000
            });
            return;
        }

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            'application/json; charset=utf-8'
        );
        this.http
            .post(environment.api_base_url + '/notifications/notify', body, {
                observe: 'response',
                responseType: 'json'
            })
            .subscribe(
                (response: any) => {
                    if (response.status >= 200 && response.status <= 299) {
                        this.snackBar.open(
                            'De melding is succesvol verstuurd',
                            'Ok',
                            {
                                panelClass: 'white-snackbar',
                                verticalPosition: 'top',
                                duration: 3000
                            }
                        );
                        this.back();
                    } else {
                        if (response.message) {
                            this.snackBar.open(response.message, 'Ok', {
                                panelClass: 'white-snackbar',
                                verticalPosition: 'top',
                                duration: 3000
                            });
                        }
                    }
                },
                error => {
                    this.snackBar.open(error.error.message, 'Ok', {
                        panelClass: 'white-snackbar',
                        verticalPosition: 'top',
                        duration: 3000
                    });
                    console.log(error);
                }
            );

        this.router.navigate(['/'], { relativeTo: this.route });
    }

    back(): void {
        this.dialogRef.close();
    }
}
