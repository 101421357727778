<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="openOblique()"
    matTooltip="Oblique"
    matTooltipPosition="left"
    [ngClass]="{ active: interactionService.oblique.active }"
>
    <fa-icon [icon]="faDove"></fa-icon>
</button>
