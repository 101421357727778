import { Component, EffectRef, OnDestroy, effect, signal } from '@angular/core';
import {
    MapService,
    FeatureService,
    InteractionService,
    ConfigService,
    LegendService
} from 'app/_services';
import { faMousePointer } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'cook-vr-photo',
    templateUrl: 'vr-photo.component.html',
    styleUrls: ['vr-photo.component.css']
})
/**
 * @shouldRemove add description
 */
export class VrPhotoComponent implements OnDestroy {
    readonly faMousePointer = faMousePointer;

    readonly action = 'vrphoto';
    readonly disabled = signal(true);

    config: any;

    readonly configSubscription: EffectRef;
    readonly zoomLevelSubscription: EffectRef;

    message: string;

    constructor(
        public readonly mapService: MapService,
        public readonly interactionService: InteractionService,
        private readonly legendService: LegendService,
        private readonly configService: ConfigService,
        private readonly featureService: FeatureService
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();
                this.config = config;
            },
            { allowSignalWrites: true }
        );

        this.zoomLevelSubscription = effect(
            () => {
                const zoomLevel = this.mapService.zoomLevel();
                if (zoomLevel > 12) {
                    this.disabled.set(false);
                    this.message = '360 Graden foto viewer';
                } else {
                    this.disabled.set(true);
                    this.message = 'Zoom in om de tool te gebruiken';
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        this.zoomLevelSubscription.destroy();
        this.interactionService.removeInteractions();
    }

    selectFeatureInfo(): void {
        if (this.interactionService.vrPhoto.active) {
            this.interactionService.removeInteractions();
            this.interactionService.vrPhoto.active = false;
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.vrPhoto.active =
                !this.interactionService.vrPhoto.active;
        }

        if (
            !this.interactionService.vrPhoto.interaction &&
            this.interactionService.vrPhoto.active
        ) {
            // Set the config to the featureService
            this.featureService.configuration.set(this.config.tools.vrPhoto);

            // Turn on the layers
            this.config.tools.vrPhoto[0].enableLayers.forEach(layer => {
                this.legendService.enableLayer(layer);
            });

            this.interactionService.vrPhoto.interaction = this.mapService
                .map()
                .on('singleclick', e => {
                    this.featureService.clicks = undefined;
                    this.featureService.deselectFeatures();
                    this.featureService.queryServers(e.coordinate, 'grid');
                });
        }
    }

    disableSelectFeatureInfo(): void {
        // Remove any previous interactions
        this.interactionService.removeInteractions();
    }
}
