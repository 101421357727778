<h2 class="p-3">Exporteren Naar</h2>
<mat-dialog-content class="exportPrint">
    <div *ngIf="!loading || error">
        <div class="row">
            <button class="col" mat-raised-button (click)="export('GEOJSON')">
                GeoJSON (Geographic JavaScript Object Notation)
            </button>
        </div>
        <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('xlsx')">
                Excel (.xlsx)
            </button>
        </div>
        <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('ACAD')">
                Autodesk AutoCAD DWG
            </button>
        </div>
        <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('CSV')">
                Comma Separated Value (CSV)
            </button>
        </div>
        <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('GPX')">
                GPS eXchange Format (GPX)
            </button>
        </div>
        <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('IGDS')">
                Bentley MicroStation Design (V7)
            </button>
        </div>
        <!-- <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('SDF3')">Autodesk MapGuide Enterprise SDF</button>
        </div> -->
        <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('SHAPEFILE')">
                Shape (.shp, .shx, .dbf)
            </button>
        </div>
        <div class="row mt-2">
            <button
                class="col"
                mat-raised-button
                (click)="export('GEOPACKAGE')"
            >
                Geopackage (GPKG)
            </button>
        </div>
        <!-- <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('SQLITE3FDO')">SQLite Spatial (FDO)</button>
        </div> -->
        <div class="row mt-2">
            <button class="col" mat-raised-button (click)="export('GML')">
                GML (Geography Markup Language)
            </button>
        </div>
    </div>
    <ngx-loading [show]="loading"></ngx-loading>
    <div *ngIf="error" class="text-danger">
        <br />Error bij het maken van de shapefile.
    </div>
</mat-dialog-content>
