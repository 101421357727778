import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'map-creator-dialog',
    templateUrl: './map-creator.component.html',
    styleUrls: ['./map-creator.component.scss']
})
/**
 * This handles the dialog
 */
export class MapCreatorDialogComponent {
    loading = false;

    type: any;
    constructor(public dialogRef: MatDialogRef<MapCreatorDialogComponent>) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    changeLoading(event: any) {
        this.loading = !this.loading;
    }
}
