<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="draw('Point')"
    matTooltip="Redline Punt"
    aria-label="Redline Punt"
    matTooltipPosition="left"
    [class.active]="
        activeGeometryType() === 'Point' && interactionService.draw.active
    "
>
    <fa-icon [icon]="faCircle"></fa-icon>
</button>

<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="draw('LineString')"
    matTooltip="Redline Lijn"
    aria-label="Redline Lijn"
    matTooltipPosition="left"
    [class.active]="
        activeGeometryType() === 'LineString' && interactionService.draw.active
    "
>
    <mat-icon>timeline</mat-icon>
</button>

<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="draw('Polygon')"
    matTooltip="Redline Vlak"
    aria-label="Redline Vlak"
    matTooltipPosition="left"
    [class.active]="
        activeGeometryType() === 'Polygon' && interactionService.draw.active
    "
>
    <fa-icon [icon]="faVectorSquare"></fa-icon>
</button>

<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="draw('Circle')"
    matTooltip="Redline Cirkel"
    aria-label="Redline Cirkel"
    matTooltipPosition="left"
    [class.active]="
        activeGeometryType() === 'Circle' && interactionService.draw.active
    "
>
    <fa-icon [icon]="farCircle"></fa-icon>
</button>

<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="disableSelectFeatureInfo()"
    matTooltip="Verwijder redlining"
    aria-label="Verwijder redlining"
    matTooltipPosition="left"
    [class.active]="interactionService.deleteRedline.active"
>
    <fa-icon [icon]="faTimes"></fa-icon>
</button>
