import { Component } from '@angular/core';
import {
    MapService,
    FeatureService,
    InteractionService,
    ConfigService
} from 'app/_services';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Draw from 'ol/interaction/Draw';

@Component({
    selector: 'circle-info',
    templateUrl: 'circle-info.component.html',
    styleUrls: ['circle-info.component.scss']
})
export class CircleInfoComponent {
    /**
     * This component is for the info circle and the deselect all tools
     * On the right of the Map
     */
    readonly faTimes = faTimes;
    faDrawCircle = faCircle;

    active = false;
    disabled = true;

    message = 'Informatie Cirkel';
    // @shouldRemove doesnt get used in this file
    _scaleSubscription;

    constructor(
        public mapService: MapService,
        private featureService: FeatureService,
        public interactionService: InteractionService,
        private configService: ConfigService
    ) {}

    drawFeatureInfo(): void {
        // Remove any previous interactions
        if (this.interactionService.circleinfo.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.circleinfo.active =
                !this.interactionService.circleinfo.active;
        }

        document.getElementById('cook_map').style.cursor =
            this.interactionService.circleinfo.active === true
                ? 'crosshair'
                : 'default';

        if (this.interactionService.circleinfo.active) {
            // Start drawing a polygon
            this.interactionService.circleinfo.interaction = new Draw({
                type: 'Circle'
            });

            this.featureService.configuration.set(null);
            // Set the config to the featureService
            if (this.configService.config().tools.info) {
                this.featureService.configuration.set(
                    this.configService.config().tools.info
                );
            }

            this.mapService
                .map()
                .addInteraction(this.interactionService.circleinfo.interaction);

            this.interactionService.circleinfo.interaction.on(
                'drawend',
                event => {
                    this.featureService.deselectFeatures();
                    this.featureService.queryServers(
                        event.feature.getGeometry(),
                        'grid'
                    );
                }
            );
        }
    }

    deselect(): void {
        this.featureService.deselectFeatures();
        this.interactionService.removeInteractions();
    }
}
