import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy
} from '@angular/core';
import {
    FeatureService,
    InteractionService,
    LayerService
} from 'app/_services';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import * as turf from '@turf/turf';
import { GeometryCollection } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON';

@Component({
    selector: 'buffer',
    templateUrl: 'buffer.component.html',
    styleUrls: ['buffer.component.scss']
})
export class BufferComponent implements OnDestroy {
    constructor(
        readonly layerService: LayerService,
        readonly featureService: FeatureService,
        readonly interactionService: InteractionService,
        private readonly snackBar: MatSnackBar
    ) {}

    ngOnDestroy(): void {
        this.interactionService.removeInteractions();
    }

    selectFeatureInfo(): void {
        const fs = this.layerService.featureLayer().getSource().getFeatures();
        const geometries = fs.map(feature => feature.getGeometry());

        // Combineer de geometrieën in een GeometryCollection
        const combinedGeometry = new GeometryCollection(geometries);

        this.snackBar.openFromComponent(BufferSnackBarComponent, {
            data: {
                geom: combinedGeometry
            },
            panelClass: 'white-snackbar',
            verticalPosition: 'top'
        });
    }

    disableSelectFeatureInfo(): void {
        // Remove any previous interactions
        this.interactionService.removeInteractions();
    }
}

@Component({
    selector: 'cook-buffer',
    template: `
        <p class="title">Buffer creëeren rondom huidige selectie.</p>
        <div class="content">
            <mat-slider
                min="1"
                max="1000"
                step="1"
                discrete
                (input)="change($event)"
                [displayWith]="formatLabel"
            >
                <input matSliderThumb [value]="distance" type="range" />
            </mat-slider>
            <button mat-raised-button (click)="submit()" class="closeButton">
                Selecteren
            </button>
        </div>
    `,
    styles: [
        `
            .title {
                font-size: 1.2em;
            }

            .content {
                gap: 1em;
                display: flex;
                align-content: space-between;

                mat-slider {
                    flex: 4;
                }

                button {
                    flex: 1;
                }
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BufferSnackBarComponent {
    title: string;
    distance: number;

    geom: any;
    layer: any;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private readonly snackBar: MatSnackBar,
        private readonly layerService: LayerService,
        private readonly featureService: FeatureService
    ) {
        this.layer = this.layerService.bufferLayer();

        const geom: any = new GeoJSON().writeGeometryObject(data.geom, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:28992'
        });
        this.geom = {
            type: 'GeometryCollection',
            geometries: geom.geometries
        };

        console.log(this.geom);
    }

    change(event: Event): void {
        const value = (event.target as HTMLInputElement).valueAsNumber;

        // Polygons need to be handled diffrently
        const buffered: any = turf.buffer(this.geom, value, {
            units: 'meters',
            steps: 8
        });
        // Converteer de gebufferde geometrie terug naar OpenLayers formaat
        const bufferedFeatures = buffered.features.map(feature =>
            new GeoJSON().readFeature(feature, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:28992'
            })
        );

        // Vervang de bestaande bufferlaag met de nieuwe bufferlaag
        this.layerService.bufferLayer().getSource().clear();
        this.layerService
            .bufferLayer()
            .getSource()
            .addFeatures(bufferedFeatures);
    }

    formatLabel(value: number): string {
        return `${value}`;
    }

    submit() {
        const features = this.layerService
            .bufferLayer()
            .getSource()
            .getFeatures();
        const geometries = features.map(feature => {
            return feature
                .getGeometry()
                .clone()
                .transform('EPSG:28992', 'EPSG:4326');
        });

        // Converteer OpenLayers geometrieën naar Turf geometrieën
        const turfGeometries = geometries.map(geometry => {
            return turf.getGeom(new GeoJSON().writeGeometryObject(geometry));
        });

        const polygons = turfGeometries.map(polygon =>
            turf.polygon(polygon.coordinates)
        );

        let combinedPolygon;
        if (polygons.length < 2) {
            combinedPolygon = polygons[0];
        } else {
            combinedPolygon = turf.union(turf.featureCollection(polygons));
        }

        // Converteer de gecombineerde geometrie terug naar een OpenLayers geometrie
        const combinedGeometry = new GeoJSON().readFeature(combinedPolygon);
        combinedGeometry.getGeometry().transform('EPSG:4326', 'EPSG:28992');

        this.featureService.queryServers(
            combinedGeometry.getGeometry(),
            'grid'
        );

        this.layerService.bufferLayer().getSource().clear();
        this.snackBar.dismiss();
    }
}
