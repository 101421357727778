import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/**
 * This service handles the grid status
 */
export class GridService {
    readonly features = signal(undefined);
    readonly openStatus = signal('closed');
    featuresChange: Subject<any> = new Subject();

    activeTab: any;
}
