import {
    Component,
    OnInit,
    Input,
    ViewChildren,
    QueryList,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    signal
} from '@angular/core';
import { LegendService } from 'app/_services';
import { LegendMapComponent } from '../map/map.component';
import {
    faAngleDown,
    faAngleRight,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'cook-map-group',
    templateUrl: 'mapgroup.component.html',
    styleUrls: ['mapgroup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendMapGroupComponent implements OnInit {
    readonly faAngleRight = faAngleRight;
    readonly faAngleDown = faAngleDown;
    readonly faInfoCircle = faInfoCircle;

    readonly checked = signal(false);
    readonly label = signal(undefined);
    readonly name = signal(undefined);
    readonly layerName = signal(undefined);
    readonly metadataUrl = signal(undefined);
    readonly maps = signal([]);
    readonly collapsed = signal(undefined);
    readonly sort = signal(undefined);

    @ViewChildren(LegendMapComponent)
    readonly childMaps: QueryList<LegendMapComponent>;

    @Input() readonly mapGroup: any;
    @Output() readonly checkEvent = new EventEmitter<boolean>();

    constructor(private readonly legendService: LegendService) {}

    ngOnInit(): void {
        this.name.set(this.mapGroup.name);
        this.label.set(this.mapGroup.name);
        this.mapGroup.collapsed = !!this.mapGroup.collapsed;
        this.collapsed.set(this.mapGroup.collapsed);
        this.sort.set(this.mapGroup.sort);
        this.metadataUrl.set(this.mapGroup.metadata_url);

        this.mapGroup.visible = !!this.mapGroup.visible;
        this.checked.set(this.mapGroup.visible);

        let hasCheckedChildren = false;

        this.mapGroup.maps.forEach(m => {
            const hasVisibleLayers = m.source.layers.some(l => l.visible);
            const hasVisibleLayerGroups = m.source.layer_groups.some(
                lg => lg.visible
            );

            const shouldCheck =
                m.visible || hasVisibleLayers || hasVisibleLayerGroups;


            m.visible = shouldCheck;

            if (shouldCheck) {
                hasCheckedChildren = true;
            }

            const isVisible = this.legendService.isMapVisible(m);

            if (m.visible !== isVisible) {
                this.legendService.toggleMap(m);
            }
        });

        this.maps.set(this.mapGroup.maps.sort((n1, n2) => n1.sort - n2.sort));

        this.checked.set(hasCheckedChildren);
        this.mapGroup.visible = hasCheckedChildren;

        this.collapsed.set(this.mapGroup.collapsed);
    }

    toggleGroup(): void {
        this.mapGroup.visible = !this.mapGroup.visible;

        this.mapGroup.maps.forEach(m => {
            m.visible = this.mapGroup.visible;

            const isVisible = this.legendService.isMapVisible(m);

            if (m.visible !== isVisible) {
                this.legendService.toggleMap(m);
            }

            if (m.source.layers?.length !== 0) {
                m.source?.layers.forEach(l => {
                    l.visible = m.visible;

                    const isVisible = this.legendService.isLayerVisible(l, m);

                    if (this.mapGroup.visible !== isVisible) {
                        this.legendService.toggleLayer(l, m);
                    }
                });
            }
            if (m.source?.layer_groups?.length !== 0) {
                m.source?.layer_groups.forEach(lg => {
                    lg.visible = m.visible;

                    const isVisible = this.legendService.isGroupVisible(lg, m);

                    if (this.mapGroup.visible !== isVisible) {
                        this.legendService.toggleLayer(lg, m);
                    }

                    if (lg?.layers?.length !== 0) {
                        lg.layers.forEach(l => {
                            l.visible = lg.visible;

                            const isVisible = this.legendService.isLayerVisible(
                                l,
                                m
                            );

                            if (this.mapGroup.visible !== isVisible) {
                                this.legendService.toggleLayer(l, m);
                            }
                        });
                    }
                });
            }
        });

        this.childMaps.forEach(m => {
            m.checked.set(this.mapGroup.visible);

            if (m.layers.length !== 0) {
                m.layers.forEach(l => {
                    l.checked.set(this.mapGroup.visible);
                });
            }

            if (m.groups.length !== 0) {
                m.groups.forEach(g => {
                    g.checked.set(this.mapGroup.visible);

                    if (g.childLayers.length !== 0) {
                        g.childLayers.forEach(l => {
                            l.checked.set(this.mapGroup.visible);
                        });
                    }
                });
            }
        });

        this.legendService.customMapGroup[this.mapGroup.name] = this.mapGroup;
    }

    checkboxChanged(event: Event): void {
        this.checked.set(this.mapGroup.visible);
        this.checkEvent.emit(this.mapGroup.visible);
    }

    viewChildChecked(checked: boolean): void {
        const childMapsArray = this.childMaps.toArray();
        const shouldUncheck =
            !checked && childMapsArray.every(m => !m.map.visible);

        if (shouldUncheck) {
            this.checked.set(false);
            this.mapGroup.visible = false;
        } else {
            this.checked.set(true);
            this.mapGroup.visible = true;
        }

        this.checkEvent.emit(this.mapGroup.visible);
    }

    toggleCollapse(): void {
        this.collapsed.set(!this.collapsed());
        this.mapGroup.collapsed = this.collapsed();
        this.legendService.customMapGroup[this.mapGroup.name] = this.mapGroup;
    }

    trackByFn(index, item): number {
        return item.id;
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.maps(), event.previousIndex, event.currentIndex);
        this.maps().forEach((m, i) => (m.sort = i));

        this.legendService.customMapGroup[this.mapGroup.name] = this.mapGroup;
    }
}
