import { Component, EventEmitter, Output } from '@angular/core';
import { ConfigService, MapService } from '../../_services';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MapCreatorDialogComponent } from '../map-creator.component';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import * as XLSX from 'xlsx';

@Component({
    selector: 'from-xlsx',
    templateUrl: './from-xlsx.component.html',
    styleUrls: ['./from-xlsx.component.scss']
})
export class MapFromXLSXComponent {
    @Output() changeLoading = new EventEmitter<boolean>();

    environment: any;
    headersRow: Array<any>;
    csvRecordsArray: any;
    records: any;
    json: any;

    xCoordinateName: any;
    yCoordinateName: any;

    step = 0;

    geoJson: any;

    name: FormControl;
    resourceFormGroup: FormGroup;

    error: string;

    constructor(
        public configService: ConfigService,
        private http: HttpClient,
        // private excelService: ExcelService,
        private mapService: MapService,
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MapCreatorDialogComponent>
    ) {
        this.environment = environment;
        this.name = new FormControl('', Validators.required);

        this.resourceFormGroup = this.formBuilder.group({
            name: this.name
        });
    }

    uploadListener($event: any): void {
        const files = $event.srcElement.files;

        this.handleFileUpload(files[0]);
    }

    handleFileUpload(file) {
        if (this.isValidXlsxFile(file)) {
            const reader = new FileReader();
            
            reader.onload = (e: any) => {
                const binaryData: string = e.target.result;
                const workbook: XLSX.WorkBook = XLSX.read(binaryData, { type: 'binary' });
                const sheetName: string = workbook.SheetNames[0];
                const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
                this.json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                this.headersRow = this.json[0];

                this.step = 1;
                // this.saveGeoJsonFile(geoJson, 'export.geojson');
            };
            reader.readAsBinaryString(file);


            reader.onerror = function () {
                console.log('error is occured while reading file!');
            };
        } else {
            alert('Please import valid .xlsx file.');
            this.fileReset();
        }
    }

    convertToGeoJson(): void {
        this.error = undefined;
        this.changeLoading.emit(true);

        // Assuming your data is in the format [latitude, longitude, ...properties]
        const geoJsonFeatures = this.json.slice(1).map((row: any[]) => {
            console.log(row)
            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(row[this.headersRow.indexOf(this.xCoordinateName)]), parseFloat(row[this.headersRow.indexOf(this.yCoordinateName)])] // [longitude, latitude]
                },
                properties: {
                    // Add any additional properties here
                    // assuming the first row is the header
                    ...row.slice(2).reduce((acc, val, idx) => {
                        acc[this.json[0][idx + 2]] = val;
                        return acc;
                    }, {})
                }
            };
        });

        this.geoJson = {
            type: 'FeatureCollection',
            crs: {
                type: 'EPSG',
                properties: {
                    code: '28992'
                }
            },
            features: geoJsonFeatures
        };        
                        
        // And go to the next step
        this.changeLoading.emit(false);
        if (this.error === undefined) {
            this.step = 2;
        }
    }

    onSubmit(): void {
        this.changeLoading.emit(true);

        // Required map attributes
        const body = this.resourceFormGroup.getRawValue();
        body.type = 'Vector';
        body.collapsed = true;
        body.visible = true;
        body.order = 50;
        body.hide = false;
        body.click = 'featureInMap';
        body.grid = {};
        body.source = {
            type: 'Vector',
            geojson: this.geoJson
        };

        this.http
            .post(`${environment.api_base_url}/maps`, body)
            .toPromise()
            .then((res: any) => {
                console.log(res);
                this.changeLoading.emit(false);

                this.bindToConfig(res.id);
            })
            .catch(err => {
                console.error(err);
                this.changeLoading.emit(false);
            });
    }

    isValidXlsxFile(file: File): boolean {
        return file.name.endsWith('.xlsx');
    }

    fileReset(): void {
        this.records = [];
    }

    bindToConfig(map_id): void {
        const body = {
            map_id,
            config_id: this.configService.config().id
        };
        this.http
            .post(`${environment.api_base_url}/maps/map-to-config`, body)
            .toPromise()
            .then((res: any) => {
                console.log(res);
                this.dialogRef.close();
                this.configService.config.set(undefined);
                this.mapService.map.set(undefined);
                this.configService.loadConfiguration();
            })
            .catch(err => {
                console.error(err);
            });
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer?.files;
        console.log(files)
        if (files && files.length) {
            if (files[0].name.slice(-3) === 'csv') {
                this.handleFileUpload(files[0]);
            }
        }
    }
}
