<div
    *ngIf="
        activeBackgroundMap() &&
        backgroundMaps().length &&
        backgroundMaps().length !== 1
    "
    id="background"
>
    <button
        *ngIf="backgroundMaps().length > 2; else simpleButton"
        class="backgroundButton btn"
        [@grid]="gridService.openStatus()"
        [matMenuTriggerFor]="backgroundsMenu"
        matTooltip="Kies een achtergrondkaart"
        matTooltipPosition="above"
    >
        <img
            alt="Achtergrondkaart {{
                activeBackgroundMap()?.pivot?.background_image
            }}"
            [src]="getImageSrc(activeBackgroundMap())"
        />
    </button>

    <ng-template #simpleButton>
        <button
            class="backgroundButton btn"
            (click)="setActive(nextBackgroundMap())"
            [@grid]="gridService.openStatus()"
            [matTooltip]="nextBackgroundMap()?.name"
            matTooltipPosition="above"
        >
            <img
                alt="Achtergrondkaart {{
                    nextBackgroundMap()?.pivot?.background_image
                }}"
                [src]="getImageSrc(nextBackgroundMap())"
            />
        </button>
    </ng-template>

    <mat-menu #backgroundsMenu="matMenu" yPosition="above">
        <button mat-menu-item (click)="setActive('empty')" type="button">
            <img
                alt="Achtergrondkaart: {{ nextBackgroundMap()?.name }}"
                src="{{
                    env.subDirectory
                }}/assets/img/map_backgrounds/empty2.png"
                class="backgroundImage"
            />
            Lege Kaart
        </button>
        <button
            mat-menu-item
            *ngFor="let map of backgroundMaps()"
            (click)="setActive(map)"
            type="button"
        >
            <img
                [src]="getImageSrc(map)"
                alt="Achtergrondkaart: {{ map?.pivot.background_image }}"
                class="backgroundImage"
            />
            {{ map.name }}
            <a
                class="metadata"
                matTooltip="Metadata"
                matTooltip="Metadata"
                matTooltipPosition="below"
                [href]="map.metadata_url"
                target="_blank"
                *ngIf="map.metadata_url"
            >
                <fa-icon [icon]="faInfoCircle"></fa-icon
            ></a>
            <a
                class="metadata"
                (click)="metadata(map)"
                matTooltip="Metadata"
                matTooltip="Metadata"
                matTooltipPosition="below"
                target="_blank"
                *ngIf="map.metadata"
            >
                <fa-icon [icon]="faInfoCircle"></fa-icon
            ></a>
        </button>
    </mat-menu>
</div>
