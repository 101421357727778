<mat-card class="card p-3">
    <h4>Opgeslagen Locaties</h4>
    <mat-list
        *ngIf="
            customExtentService.customExtents() &&
                customExtentService.customExtents().length;
            else noCustomExtents
        "
    >
        <mat-list-item
            role="listitem"
            *ngFor="let v of customExtentService.customExtents()"
        >
            <mat-icon class="mapSign" matListItemIcon
                ><fa-icon [icon]="faMapSign"></fa-icon
            ></mat-icon>
            <div matListItemTitle class="w-100">
                {{ v.name }}
                <button mat-icon-button (click)="onDelete(v)">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
            </div>
        </mat-list-item>
    </mat-list>
    <ng-template #noCustomExtents>
        <p>
            U heeft nog geen opgeslagen locaties. Voeg er een toe in de
            rechterzijde bij het
            <mat-icon matListIcon
                ><fa-icon [icon]="faMapPin"></fa-icon
            ></mat-icon>
            logo.
        </p>
    </ng-template>
</mat-card>
