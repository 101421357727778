import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    readonly history = signal<number[][]>([]);
    readonly shouldUpdate = signal<boolean>(true);
    readonly historyIndex = signal<number>(1);

    // Search fields for the admin module
    readonly configSearch = new BehaviorSubject<string>('');
    readonly mapSearch = new BehaviorSubject<string>('');
    readonly dashboardSearch = new BehaviorSubject<string>('');
    readonly userSearch = new BehaviorSubject<string>('');
    readonly logSearch = new BehaviorSubject<string>('');
    readonly notificationSearch = new BehaviorSubject<string>('');
    readonly formSearch = new BehaviorSubject<string>('');

    updateHistory(extent: number[]): void {
        if (!this.shouldUpdate()) {
            this.shouldUpdate.set(true);
            return;
        }

        const newHistory = [...this.history(), extent];
        this.history.set(newHistory);

        this.historyIndex.set(this.history()?.length);
    }
}
