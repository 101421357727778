import { Component, EffectRef, OnInit, effect } from '@angular/core';
import { ConfigService, MapService } from 'app/_services';
import * as source from 'ol/source';
import Style from 'ol/style/Style';
import { Stroke } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { GeoJSON } from 'ol/format';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RoutingService } from 'app/_services/routing.service';

@Component({
    selector: 'cook-routing',

    templateUrl: './routing.component.html',

    styleUrls: ['./routing.component.scss']
})
export class RoutingComponent implements OnInit {
    _configSubscription: any;
    _coordinateSubscription: any;
    _endpointSubscription: any;
    routeSource: any;
    routeLayer: any;
    coordinate: any;
    config: any;
    configSubscription: EffectRef;
    highlightSearch = true;

    constructor(
        private readonly mapService: MapService,
        private readonly routingService: RoutingService,
        protected configService: ConfigService,
        private http: HttpClient
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();
                this.config = config;
                this.initRouting();
            },
            { allowSignalWrites: true }
        );
    }

    onOnDestroy(): void {
        this._configSubscription.unsubscribe();
        this._coordinateSubscription.unsubscribe();
        this._endpointSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.initRouting();
        this.config = this.configService.config();

        this._coordinateSubscription =
            this.routingService.coordinateChange.subscribe(response => {
                this.coordinate = response;

                // this.updateRouting(); // TODO. Test the "walking around" the graveyard.
            });

        this._endpointSubscription =
            this.routingService.endpointChange.subscribe(response => {
                // Update the starting point

                this.highlightSearch = false;

                this.updateRouting();
            });
    }

    updateRouting(): void {
        this.routeSource.clear();

        const body = {
            startpoint:
                this.routingService.geolocationEnabled === false
                    ? this.config.tools.routing.startingPoint
                    : this.coordinate,

            endpoint: this.routingService.endpoint
        };

        this.http

            .post(environment.api_base_url + '/getroute', body)

            .subscribe(r => {
                const features = new GeoJSON().readFeatures(JSON.stringify(r));

                this.routeSource.addFeatures(features);
            });
    }

    initRouting(): void {
        this.routeSource = new source.Vector({
            format: new GeoJSON(),

            features: []
        });

        this.routeLayer = new VectorLayer({
            source: this.routeSource,

            style: new Style({
                stroke: new Stroke({
                    width: 5,

                    color: '#ff0000'
                })
            })
        });

        this.mapService.map().addLayer(this.routeLayer);
    }
}
