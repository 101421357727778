<div class="overlay m-0">
    <h3>Accorderen</h3>
    <mat-button-toggle-group
        #filter="matButtonToggleGroup"
        hideSingleSelectionIndicator="true"
    >
        <mat-button-toggle value="Afgehandeld">
            <mat-icon class="text-success">done</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="In behandeling">
            <mat-icon class="">timeline</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="Uitgesteld">
            <mat-icon>query_builder</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="Geweigerd">
            <mat-icon class="text-danger">close</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field class="w-25 float-end">
        <input
            matInput
            type="text"
            [(ngModel)]="searchInput"
            placeholder="Zoeken"
        />
        <button
            *ngIf="searchInput"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchInput = ''"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <mat-accordion *ngIf="notifications">
        <ng-container
            *ngFor="let notification of notifications | search : searchInput"
        >
            <mat-expansion-panel
                hideToggle
                *ngIf="!filter.value || filter.value === notification.status"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ notification.subject }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon
                            *ngIf="notification.status === 'Afgehandeld'"
                            class="text-success"
                            >done</mat-icon
                        >
                        <mat-icon
                            *ngIf="notification.status === 'In behandeling'"
                            class=""
                            >timeline</mat-icon
                        >
                        <mat-icon
                            *ngIf="notification.status === 'Uitgesteld'"
                            class=""
                            >query_builder</mat-icon
                        >
                        <mat-icon
                            *ngIf="notification.status === 'Geweigerd'"
                            class="text-danger"
                            >close</mat-icon
                        >
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <b>Bericht</b>
                <p>{{ notification.message }}</p>
                <!-- <button mat-raised-button *ngIf="notification.features && notification.features.length" (click)="loadView(notification.features)" class="float-end">Bekijk element</button> -->

                <div *ngIf="notification.files" class="w-100">
                    <mat-list *ngFor="let file of notification.files">
                        {{ file.name }}
                        <button
                            mat-icon-button
                            (click)="download(file.path, file.name)"
                            class="light-icon"
                        >
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                    </mat-list>
                </div>

                <!-- <div class="float-end">
                    <input type="file" class="file-input"
                    (change)="onFileSelected($event, notification)" #fileUpload>

                    <div class="file-upload">
                        Upload bestanden.

                        <button mat-mini-fab type="button" color="secondary"
                            (click)="fileUpload.click()">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                    </div>
                </div> -->

                <br />

                <!-- @shouldRemove *ngIf="notification.status === 'In behandeling' || notification.status === 'Uitgesteld'" -->
                <mat-action-row>
                    <button
                        type="button"
                        class="bg-danger"
                        mat-raised-button
                        (click)="
                            updateNotificationStatus(notification, 'Geweigerd')
                        "
                    >
                        Weigeren
                    </button>
                    <button
                        type="button"
                        class="bg-info"
                        mat-raised-button
                        (click)="
                            updateNotificationStatus(notification, 'Uitgesteld')
                        "
                    >
                        Uitstellen
                    </button>
                    <button
                        type="button"
                        class="bg-success"
                        mat-raised-button
                        (click)="
                            updateNotificationStatus(
                                notification,
                                'Afgehandeld'
                            )
                        "
                    >
                        Afronden
                    </button>
                </mat-action-row>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</div>
