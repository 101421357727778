import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    signal
} from '@angular/core';
import { DetailService } from 'app/_services/';
import { Image } from '@ks89/angular-modal-gallery';
import { environment } from 'environments/environment';
import {
    faFilePdf,
    faPencil,
    faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

@Component({
    selector: 'detail',
    templateUrl: 'detail.component.html',
    styleUrls: ['detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailComponent implements OnInit, OnDestroy {
    readonly faFilePdf = faFilePdf;
    readonly faPencil = faPencil;
    readonly faDownload = faDownload;
    readonly env = environment;
    readonly data = signal(undefined);
    private readonly _featureSubscription: Subscription;

    constructor(private readonly detailService: DetailService) {
        this._featureSubscription = this.detailService.featuresChange.subscribe(
            value => this.data.set(value)
        );
    }

    ngOnInit(): void {
        this.data.set(this.detailService.features());

        this.data().forEach(d => {
            d.layers.forEach(layer => {
                layer.features.forEach(f => {
                    const image = f.get('afbeelding');

                    if (image) {
                        this.createImages(image);
                    }
                });
            });
        });
    }

    private createImages(image: string): Image[] {
        const images: Image[] = [];
        const rawImages = image.split(',');

        for (let i = 0; i < rawImages.length; i++) {
            const image = rawImages[i];
            images.push(new Image(i, { img: image }));
        }

        return images;
    }

    ngOnDestroy(): void {
        this._featureSubscription.unsubscribe();
    }
}
