import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MapService, ConfigService, HistoryService } from 'app/_services';
import {
    faArrowRight,
    faArrowLeft,
    faExpandArrowsAlt
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {
    readonly faArrowRight = faArrowRight;
    readonly faArrowLeft = faArrowLeft;
    readonly faExpandArrowsAlt = faExpandArrowsAlt;

    constructor(
        readonly historyService: HistoryService,
        readonly configService: ConfigService,
        private readonly mapService: MapService
    ) {}

    zoomExtent(): void {
        let extent: number[];
        const configExtent = this.configService.config().extent;
        if (configExtent[0].length) {
            extent = [
                configExtent[0][0],
                configExtent[0][1],
                configExtent[1][0],
                configExtent[1][1]
            ];
        } else {
            extent = configExtent;
        }

        this.mapService.map().getView().fit(extent, { duration: 1000 });
    }

    lastExtent(): void {
        const newIndex = this.historyService.historyIndex() - 1;
        if (newIndex >= 1) {
            this.historyService.historyIndex.set(newIndex);
            this.historyService.shouldUpdate.set(false);

            this.mapService
                .map()
                .getView()
                .fit(this.historyService.history()[newIndex - 1], {
                    duration: 1000
                });
        }
    }

    nextExtent(): void {
        const newIndex = this.historyService.historyIndex() + 1;
        if (newIndex <= this.historyService.history().length) {
            this.historyService.historyIndex.set(newIndex);
            this.historyService.shouldUpdate.set(false);

            this.mapService
                .map()
                .getView()
                .fit(this.historyService.history()[newIndex - 1], {
                    duration: 1000
                });
        }
    }
}
