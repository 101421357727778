import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from 'app/_services';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    readonly env = environment;

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router
    ) {}

    /**
     * Check if a route can be activated, if this is not the case send the user to the login page
     * @return {boolean} [description]
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.id() || localStorage.getItem('id') || environment.public === true) {
            return true;
        }

        this.authService.id.set(undefined);
        localStorage.removeItem('id');

        this.authService.redirectUrl.set(state.url);

        this.router.navigate(['login']);
        return false;
    }
}
