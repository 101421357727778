import { Component, EffectRef, OnDestroy, OnInit, effect } from '@angular/core';
import Geolocation from 'ol/Geolocation';
import { Circle, Fill, Stroke, Style } from 'ol/style.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MapService } from 'app/_services/map.service';
import { LayerService } from 'app/_services/layer.service';
import { ConfigService } from 'app/_services/config.service';
import { RoutingService } from 'app/_services/routing.service';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'geolocation',
    templateUrl: 'geolocation.component.html',
    styleUrls: ['geolocation.component.css']
})
export class GeolocationComponent implements OnInit, OnDestroy {
    faCrosshairs = faCrosshairs;

    config: any;
    private configSubscription: EffectRef;

    geolocation: Geolocation | undefined;
    geolocationEnabled = false;
    positionFeature: Feature | undefined;

    constructor(
        private mapService: MapService,
        private layerService: LayerService,
        private configService: ConfigService,
        private routingService: RoutingService,
        private snackBar: MatSnackBar
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();
                this.config = config;
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.config = this.configService.config();
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    /**
     * Toggle the GPS on/off
     */
    toggleGps(): void {
        this.geolocationEnabled = !this.geolocationEnabled;
        this.routingService.geolocationEnabled = this.geolocationEnabled;

        if (!this.geolocation) {
            this.geolocation = new Geolocation({
                projection: this.mapService.map().getView().getProjection(),
                trackingOptions: {
                    enableHighAccuracy: true,
                    maximumAge: 2000
                }
            });
        }

        this.geolocation.setTracking(this.geolocationEnabled);

        if (this.geolocationEnabled) {
            // Create a position feature to show where the user is located
            this.positionFeature = new Feature();
            this.positionFeature.setStyle(
                new Style({
                    image: new Circle({
                        radius: 6,
                        fill: new Fill({
                            color: '#3399CC'
                        }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2
                        })
                    })
                })
            );

            // Get the coordinates from the geolocation object, add them to the feature and zoom to it
            this.geolocation.on('change:position', () => {
                const coordinates = this.geolocation!.getPosition();
                this.positionFeature!.setGeometry(
                    coordinates ? new Point(coordinates) : null
                );

                this.routingService.coordinate = coordinates;

                this.mapService
                    .map()
                    .getView()
                    .animate({ center: coordinates });
            });

            this.geolocation.on('error', error => {
                console.error(error);
                this.snackBar.open(error.message || 'An error occurred', 'Ok', {
                    panelClass: 'white-snackbar',
                    verticalPosition: 'top',
                    duration: 3000
                });
            });

            const overlayLayer = this.layerService.overlayLayer();
            overlayLayer.getSource().addFeature(this.positionFeature!);
        } else {
            // Remove the position feature when toggling
            const overlayLayer = this.layerService.overlayLayer();
            overlayLayer.getSource().removeFeature(this.positionFeature!);
            this.positionFeature = undefined;
        }
    }
}
