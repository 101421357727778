import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService } from '../_services/auth.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import * as localforage from 'localforage';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const baseurl = req.url.split('/').slice(0, 3).join('/');

        if (baseurl === environment.base_url) {
            req = req.clone({
                withCredentials: true
            });

            return next.handle(req).pipe(
                catchError(error => {
                    return this.handleResponseError(error, req, next);
                })
            );
        }

        return next.handle(req);
    }

    private handleResponseError(error, request?, next?): Observable<never> {
        // Invalid token error
        if (error.status === 401 || error.status === 419) {
            if (
                !environment.public &&
                this.router.url != '/login' &&
                (this.authService.id() || this.authService.name())
            ) {
                this.authService.redirectUrl.set(undefined);
                this.authService.id.set(undefined);
                this.authService.name.set(undefined);
                this.authService.organization.set(undefined);
                this.authService.configId.set(undefined);
                this.authService.role.set(undefined);
                this.authService.configurations.set(undefined);

                localStorage.clear();
                localforage.clear();
                this.router.navigate(['/login']);
            }
        }

        return throwError(error);
    }
}
