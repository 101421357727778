<button
    mat-mini-fab
    color="secondary"
    *ngIf="
        configService.config()?.forms?.length &&
        configService.config()?.forms[0].type === 'intern' &&
        !environment.public
    "
    class="tool"
    (click)="editForm()"
    matTooltip="Bewerk formulieren intern"
    [class.active]="interactionService.edit.active"
>
    <fa-icon [icon]="faPencil"></fa-icon>
</button>

<button
    mat-mini-fab
    color="secondary"
    *ngIf="
        interactionService.editLayers.length === 1 &&
        authService.id() &&
        configService.config()?.forms?.length &&
        configService.config()?.forms[0].type !== 'intern'
    "
    class="tool"
    (click)="editFeature(interactionService.editLayers[0])"
    matTooltip="Bewerk"
    [class.active]="interactionService.edit.active"
>
    <fa-icon [icon]="faPencil"></fa-icon>
</button>

<ng-container
    *ngIf="
        interactionService.editLayers.length > 1 &&
        authService.id() &&
        configService.config()?.forms?.length &&
        configService.config()?.forms[0].type !== 'intern'
    "
>
    <button
        mat-mini-fab
        color="secondary"
        [matMenuTriggerFor]="editMenu"
        class="tool"
        [class.active]="interactionService.edit.active"
    >
        <fa-icon [icon]="faPencil"></fa-icon>
    </button>
    <mat-menu #editMenu="matMenu">
        <button
            *ngFor="let layer of interactionService.editLayers"
            (click)="editFeature(layer)"
            mat-menu-item
        >
            {{ layer.get('title') }}
        </button>
    </mat-menu>
</ng-container>
