import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { EquirectangularAdapter, Viewer } from '@photo-sphere-viewer/core';

declare var pannellum: any;
export interface Viewer360Data {
    imageUrl: any;
}

// Dialog for the Name of the saved location
@Component({
    selector: 'cook-vr-viewer',
    templateUrl: 'vr-viewer.html',
    styleUrls: ['vr-viewer.scss']
})
export class VrViewerDialogComponent implements OnDestroy, OnInit {

    @ViewChild('viewerContainer') viewerContainer: ElementRef;

    imageUrl: any;
    viewer: any;
    environment: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Viewer360Data,
    ) {
        if (data.imageUrl) {
            this.imageUrl = data.imageUrl;
        }

        this.environment = environment;
    }

    ngOnDestroy(): void {
        this.viewer.destroy();
        this.viewer = undefined;
    }

    ngOnInit(): void {
        this.viewer = new Viewer({
            container: "viewerContainer",
            panorama: this.imageUrl,
            defaultZoomLvl: 0,
            adapter: [EquirectangularAdapter, {
                backgroundColor: '#77addb',
                interpolateBackground: true,
            }],

        });
    }
}
