import { Component, EffectRef, OnDestroy, OnInit, effect } from '@angular/core';
import { MapService, InteractionService, ConfigService } from 'app/_services';
import { faStreetView } from '@fortawesome/free-solid-svg-icons';
import { unByKey } from 'ol/Observable';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { StreetviewComponent } from '../../_dialogs';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'cyclo',
    templateUrl: 'cyclo.component.html',
    styleUrls: ['cyclo.component.css']
})

/**
 * Tool for opening openStreetsmart
 * On the right of the Map
 */
export class CycloComponent implements OnInit, OnDestroy {
    faStreetView = faStreetView;

    ol: any;
    googleCoordinates: any;

    public environment: any;

    config: any;

    configSubscription: EffectRef;

    constructor(
        protected configService: ConfigService,
        private mapService: MapService,
        private http: HttpClient,
        public interactionService: InteractionService,
        public dialog: MatDialog
    ) {
        this.environment = environment;

        this.configSubscription = effect(
            () => {
                this.config = this.configService.config();
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.config = this.configService.config();
    }

    ngOnDestroy(): void {
        (<any>window).searchComponent = null;
    }

    /**
     * Uses the click coordinates to open a streetsmart url
     * @return [description]
     */
    openStreetsmart(): void {
        if (this.interactionService.cyclo.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.cyclo.active =
                !this.interactionService.cyclo.active;
        }

        if (
            !this.interactionService.cyclo.interaction &&
            this.interactionService.cyclo.active
        ) {
            this.interactionService.cyclo.interaction = this.mapService
                .map()
                .on('singleclick', (e: any) => {
                    if (
                        this.config.tools.cyclo &&
                        this.config.tools.cyclo.client !== 'google'
                    ) {
                        const coordinate = this.mapService
                            .map()
                            .getCoordinateFromPixel(e.pixel);
                        this.http
                            .post(environment.api_base_url + '/streetsmart', {
                                username: this.config.tools.cyclo.username,
                                password: this.config.tools.cyclo.password,
                                longitude: coordinate[0],
                                latitude: coordinate[1]
                            })
                            .toPromise()
                            .then(res => {
                                console.log(res);
                                window.open(
                                    `${environment.base_url}/streetsmart/${res}`
                                );
                            });
                    } else {
                        this.googleCoordinates = this.mapService
                            .map()
                            .getCoordinateFromPixel(e.pixel);
                        this.dialog.open(StreetviewComponent, {
                            panelClass: 'StreetviewComponent',
                            data: {
                                coordinates: this.googleCoordinates
                            }
                        });
                    }
                });
        } else {
            // Remove the streetsmart event, the tool was deactivated
            unByKey(this.interactionService.cyclo.interaction);
            this.interactionService.cyclo.interaction = undefined;
        }
    }
}
