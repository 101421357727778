import {
    ChangeDetectionStrategy,
    Component,
    EffectRef,
    OnDestroy,
    OnInit,
    effect,
    signal
} from '@angular/core';
import {
    ConfigService,
    MapService,
    AuthService,
    GridService,
    SidenavService
} from 'app/_services';
import { environment } from 'environments/environment';
import Static from 'ol/source/ImageStatic';
import ImageLayer from 'ol/layer/Image';
import { unByKey } from 'ol/Observable';
import { MatDialog } from '@angular/material/dialog';
import { ExportPrintDialogComponent } from '../../_dialogs/export-print/export-print.dialog';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import * as htmlToImage from 'html-to-image';
import { getPointResolution } from 'ol/proj';
import { ScaleLine } from 'ol/control';

@Component({
    selector: 'print-form',
    templateUrl: 'print.component.html',
    styleUrls: ['./print.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * This tool will make a export of the current view of the Map
 * This tool is located on the left panel on the printer tab
 */
export class PrintComponent implements OnInit, OnDestroy {
    private readonly DPI = 150;
    private readonly PPI = 25.4;
    private readonly BORDER_DISTANCE = 42;
    private readonly METERS_IN_INCHES = 39.37;

    readonly faInfo = faInfo;
    readonly environment = environment;

    readonly configSubscription: EffectRef;

    readonly printConfig: any = signal({});
    private maskLayer: ImageLayer<Static>;
    private ipm: number;

    grid: any;

    // inputfields
    info = true;
    rotation = 0;
    scale = 25000;
    title: string;
    layout = 'A4';
    printMaskPostrender: any;
    type: 'portrait' | 'landscape' = 'portrait';

    readonly layouts = ['A0', 'A1', 'A2', 'A3', 'A4', 'A5'];

    readonly dims = {
        A0: [1189, 841],
        A1: [841, 594],
        A2: [594, 420],
        A3: [420, 297],
        A4: [297, 210],
        A5: [210, 148]
    };
    readonly printScales = [
        100, 200, 250, 500, 1000, 2000, 2500, 5000, 10000, 25000, 50000, 100000,
        200000
    ];

    readonly date = `Datum: ${new Date().getDate()}-${
        new Date().getMonth() + 1
    }-${new Date().getFullYear()}`;

    constructor(
        private readonly dialog: MatDialog,
        private readonly configService: ConfigService,
        private readonly sidenavService: SidenavService,
        private readonly gridService: GridService,
        public mapService: MapService,
        public authService: AuthService
    ) {
        // Set the printConfigs and the active config
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();

                this.printConfig.set(config.tools.print.configs[0]);
            },
            { allowSignalWrites: true }
        );

        this.sidenavService.setWidth(380, 'px');
    }

    ngOnInit(): void {
        const scale = Math.round(
            this.mapService.map().getView().getResolution() *
                (this.METERS_IN_INCHES * (this.PPI / 0.28))
        );

        for (let i = 0, ii = this.printScales.length; i < ii; ++i) {
            if (scale > this.printScales[i]) {
                this.scale = this.printScales[i];
            }
        }

        this.initPrintMask();
    }

    renderMap(): void {
        const map = this.mapService.map();

        const res =
            this.scale /
            1000 /
            getPointResolution(
                map.getView().getProjection(),
                this.DPI / this.PPI,
                map.getView().getCenter()
            );

        let height: number;

        if (this.type === 'portrait') {
            height = 0;
        } else if (this.type === 'landscape') {
            height = 1;
        } else {
            console.error('None existing this.type: ' + this.type);
        }

        map.getView().animate({
            resolution: (this.dims[this.layout][height] / 140) * res
        });
        map.render();
    }

    private createMaskLayer(): void {
        const map = this.mapService.map();

        this.maskLayer = new ImageLayer({
            source: new Static({
                url: environment.subDirectory + '/assets/img/logo_cook.svg',
                imageExtent: map.getView().calculateExtent(map.getSize())
            }),
            map: map,
            opacity: 0
        });
    }

    initPrintMask(): void {
        const map = this.mapService.map();
        // @Todo Make sure the logo get properly loaded on the maps
        // For now set the layer invisible
        this.createMaskLayer();

        this.calculateInchPerMeter();

        this.printMaskPostrender = this.maskLayer.on('postrender', evt => {
            const context = evt.context;

            if (!(context instanceof CanvasRenderingContext2D)) {
                return console.error(
                    `This is not the correct type: type ${typeof context}`
                );
            }
            this.calculateInchPerMeter();

            const frameState = evt.frameState;
            const viewportWidth = frameState.size[0] * frameState.pixelRatio;
            const viewportHeight = frameState.size[1] * frameState.pixelRatio;
            const center = [viewportWidth / 2, viewportHeight / 2];
            const size = this.dims[this.layout];

            let height: number;
            let width: number;

            if (this.type === 'portrait') {
                height = size[0];
                width = size[1];
            } else if (this.type === 'landscape') {
                height = size[1];
                width = size[0];
            } else {
                console.error('None existing this.type: ' + this.type);
            }

            this.scale = this.getOptimalScale(
                frameState.size,
                map.getView().getResolution(),
                this.printScales
            );

            const extentHalfWidth = this.calculatLength(width);
            const extentHalfHeight = this.calculatLength(height);

            context.fillStyle = 'rgba(0, 5, 25, 0.5)';

            // Draw a mask on the whole map.
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(viewportWidth, 0);
            context.lineTo(viewportWidth, viewportHeight);
            context.lineTo(0, viewportHeight);
            context.lineTo(0, 0);
            context.closePath();

            // Draw the print zone
            if (!this.rotation) {
                this.drawPrintZone(
                    context,
                    center,
                    extentHalfWidth,
                    extentHalfHeight
                );
            } else {
                const rotation = this.rotation * (Math.PI / 180);

                this.drawPrintZoneWithRotation(
                    context,
                    center,
                    extentHalfWidth,
                    extentHalfHeight,
                    rotation
                );
            }

            // Fill the mask
            context.fill();
        });

        map.render();
    }

    private calculateInchPerMeter(): void {
        this.ipm =
            this.mapService.map().getView().getProjection().getMetersPerUnit() *
            this.METERS_IN_INCHES;
    }

    private calculatLength(length: number): number {
        return (
            ((length / this.PPI / this.ipm) * this.scale) /
            this.mapService.map().getView().getResolution() /
            2
        );
    }

    private getOptimalScale(mapSize, mapResolution, printMapScales): number {
        const mapWidth = mapSize[0] * mapResolution;
        const mapHeight = mapSize[1] * mapResolution;

        const size = this.dims[this.layout];
        let height: number;
        let width: number;

        if (this.type === 'portrait') {
            height = size[0];
            width = size[1];
        } else if (this.type === 'landscape') {
            height = size[1];
            width = size[0];
        } else {
            console.error('None existing this.type: ' + this.type);
        }

        const scaleWidth = (mapWidth * this.ipm * this.PPI) / width;
        const scaleHeight = (mapHeight * this.ipm * this.PPI) / height;

        const scale = Math.min(scaleWidth, scaleHeight);

        let optimal = printMapScales[0];
        for (let i = 1; i < printMapScales.length; i++) {
            if (scale + 1 > printMapScales[i]) {
                optimal = printMapScales[i];
            } else {
                break;
            }
        }

        return optimal;
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        unByKey(this.printMaskPostrender);
        this.printMaskPostrender = undefined;
        this.mapService.map().render();
        this.mapService.map().removeLayer(this.maskLayer);
    }

    manual(): void {
        const url = `/assets/user_manual/${environment.loginPrefix}/print-manual.pdf`;
        const win = window.open(url, '_blank');
        win.opener = null;
        win.focus();
    }

    private drawPrintZone(
        context: CanvasRenderingContext2D,
        center: number[],
        extentHalfWidth: number,
        extentHalfHeight: number
    ): void {
        const minx = center[0] - extentHalfWidth;
        const miny = center[1] - extentHalfHeight;
        const maxx = center[0] + extentHalfWidth;
        const maxy = center[1] + extentHalfHeight;

        context.moveTo(minx, miny);
        context.lineTo(minx, maxy);
        context.lineTo(maxx, maxy);
        context.lineTo(maxx, miny);
        context.lineTo(minx, miny);
        context.closePath();
    }

    private drawPrintZoneWithRotation(
        context: CanvasRenderingContext2D,
        center: number[],
        extentHalfWidth: number,
        extentHalfHeight: number,
        rotation: number
    ): void {
        // diagonal = distance p1 to center.
        const diagonal = Math.sqrt(
            Math.pow(extentHalfWidth, 2) + Math.pow(extentHalfHeight, 2)
        );
        // gamma = angle between horizontal and diagonal (with rotation).
        const gamma = Math.atan(extentHalfHeight / extentHalfWidth) - rotation;
        // omega = angle between diagonal and vertical (with rotation).
        const omega = Math.atan(extentHalfWidth / extentHalfHeight) - rotation;
        // Calculation of each corner.
        const x1 = center[0] - Math.cos(gamma) * diagonal;
        const y1 = center[1] + Math.sin(gamma) * diagonal;
        const x2 = center[0] + Math.sin(omega) * diagonal;
        const y2 = center[1] + Math.cos(omega) * diagonal;
        const x3 = center[0] + Math.cos(gamma) * diagonal;
        const y3 = center[1] - Math.sin(gamma) * diagonal;
        const x4 = center[0] - Math.sin(omega) * diagonal;
        const y4 = center[1] - Math.cos(omega) * diagonal;

        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.lineTo(x3, y3);
        context.lineTo(x4, y4);
        context.lineTo(x1, y1);
        context.closePath();
    }

    print(): void {
        this.configService.configLoading.set(true);

        const map = this.mapService.map();
        const view = map.getView();
        const viewResolution = view.getResolution();

        const [dimWidth, dimHeight] = this.dims[this.layout];

        let pdfWidth: number;
        let pdfHeight: number;

        if (this.type === 'portrait') {
            pdfWidth = Math.round((dimHeight * 150) / this.PPI);
            pdfHeight = Math.round((dimWidth * 150) / this.PPI);
        } else if (this.type === 'landscape') {
            pdfWidth = Math.round((dimWidth * 150) / this.PPI);
            pdfHeight = Math.round((dimHeight * 150) / this.PPI);
        }

        map.once('rendercomplete', event => {
            this.createMap(viewResolution, pdfWidth, pdfHeight);
        });

        const scaleResolution =
            this.scale /
            1000 /
            getPointResolution(
                view.getProjection(),
                this.DPI / this.PPI,
                view.getCenter()
            );

        const targetElement = map.getTargetElement();
        const adjustedPdfWidth = pdfWidth - this.BORDER_DISTANCE;
        const adjustedPdfHeight = pdfHeight - this.BORDER_DISTANCE;

        targetElement.style.width = `${adjustedPdfWidth}px`;
        targetElement.style.height = `${adjustedPdfHeight}px`;

        map.updateSize();
        view.setResolution(scaleResolution);

        if (this.rotation) {
            view.setRotation(-((this.rotation * Math.PI) / 180));
        }

        this.maskLayer.setVisible(false);
    }

    createMap(
        viewResolution: number,
        pdfWidth: number,
        pdfHeight: number
    ): void {
        const dim = this.dims[this.layout];
        const templateId = 'printTmpl';

        // Make sure the created map has the correct dimensions
        htmlToImage
            .toPng(this.mapService.map().getViewport(), {
                width: pdfWidth - this.BORDER_DISTANCE,
                height: pdfHeight - this.BORDER_DISTANCE
            })
            .then(img => {
                const image: HTMLImageElement = document
                    .getElementById(templateId)
                    .querySelector('#printImage');

                image.crossOrigin = 'Anonymous';
                image.src = img;

                const template = document.getElementById(templateId);

                template.style.height = pdfHeight + 'px';
                template.style.width = pdfWidth + 'px';

                if (this.info) {
                    const img = document.getElementById('northArrow');
                    img.style.transform = 'rotate(' + this.rotation + 'deg)';
                }

                if (this.grid) {
                    const grid = this.getGrid();
                    console.log(grid);
                }
                setTimeout(() => {
                    htmlToImage
                        .toPng(document.getElementById(templateId))
                        .then(dataUrl => {
                            this.dialog.open(ExportPrintDialogComponent, {
                                closeOnNavigation: true,
                                width: '35vw',
                                height: '65vh',
                                data: {
                                    image: dataUrl,
                                    title: this.title,
                                    type: 'image/jpeg',
                                    size: this.type,
                                    layout: this.layout,
                                    height:
                                        this.type === 'portrait'
                                            ? dim[0]
                                            : dim[1],
                                    width:
                                        this.type === 'landscape'
                                            ? dim[0]
                                            : dim[1]
                                }
                            });

                            const map = this.mapService.map();

                            // map.removeControl(this.mapService.scaleline());
                            // map.addControl(this.scaleControl('Scaleline'));
                            map.getTargetElement().style.width = '';
                            map.getTargetElement().style.height = '';
                            map.updateSize();
                            map.getView().setRotation(0);
                            map.getView().setResolution(viewResolution);
                            this.maskLayer.setVisible(true);

                            image.src = undefined;

                            this.configService.configLoading.set(false);
                        })
                        .catch(error => {
                            console.error('Oops, something went wrong!', error);
                        });
                }, 100);
            })
            .catch(function (error) {
                console.error('Oops, something went wrong!', error);
            });
    }

    scaleControl(scaleType: string): ScaleLine {
        let control: ScaleLine;

        if (scaleType === 'Scaleline') {
            control = new ScaleLine({
                units: 'metric'
            });
        } else if (scaleType === 'Scalebar') {
            control = new ScaleLine({
                units: 'metric',
                bar: true,
                minWidth: 140
            });
        } else {
            console.error('Invalid scaleType: ' + scaleType);
            return null; // or throw an error, depending on your desired behavior
        }

        this.mapService.scaleline.set(control);
        return control;
    }

    getGrid() {
        htmlToImage
            .toPng(document.getElementById(this.gridService.activeTab))
            .then(dataUrl => {})
            .catch(error => {
                console.error('Oops, something went wrong!', error);
            });
    }
}
