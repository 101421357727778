<h1 mat-dialog-title>Metadata van {{ data.descriptor }}</h1>

<table
    class="table-hover table-striped table w-100"
    *ngFor="let item of data.metadata"
>
    <thead>
        <tr>
            <th>
                <h5>{{ item.title }}</h5>
            </th>
            <!-- @shouldRemove empty th -->
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let meta of item.info">
            <td>{{ meta.key }}</td>
            <td>{{ meta.value }}</td>
        </tr>
    </tbody>
</table>
