import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/_services';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService
    ) {}
    /**
     * Check if the user has the permission to navigate to the route
     * @return {boolean} [description]
     */
    canActivate(): boolean {
        if (
            this.authService.id() &&
            this.authService.hasPermission(['manager', 'admin'])
        ) {
            return true;
        }
        this.router.navigate(['login']);

        return false;
    }
}
