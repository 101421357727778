import { Component } from '@angular/core';
import {
    MapService,
    FeatureService,
    InteractionService,
    ConfigService
} from 'app/_services';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import Draw from 'ol/interaction/Draw';

@Component({
    selector: 'poly-info',
    templateUrl: 'poly-info.component.html',
    styleUrls: ['poly-info.component.css']
})
/**
 * Information surface tool
 * On the right of the Map
 */
export class PolyInfoComponent {
    faVectorSquare = faVectorSquare;
    active = false;

    constructor(
        public mapService: MapService,
        private featureService: FeatureService,
        public interactionService: InteractionService,
        private configService: ConfigService
    ) {}

    drawFeatureInfo(): void {
        // Remove any previous interactions
        if (this.interactionService.polyinfo.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.polyinfo.active =
                !this.interactionService.polyinfo.active;
        }

        document.getElementById('cook_map').style.cursor =
            this.interactionService.polyinfo.active === true
                ? 'crosshair'
                : 'default';

        if (this.interactionService.polyinfo.active) {
            // Start drawing a polygon
            const polygon = 'Polygon';
            this.interactionService.polyinfo.interaction = new Draw({
                type: polygon
            });

            this.featureService.configuration.set(null);
            // Set the config to the featureService
            if (this.configService.config().tools.info) {
                this.featureService.configuration.set(
                    this.configService.config().tools.info
                );
            }

            this.mapService
                .map()
                .addInteraction(this.interactionService.polyinfo.interaction);

            this.interactionService.polyinfo.interaction.on(
                'drawend',
                event => {
                    this.featureService.deselectFeatures();
                    this.featureService.queryServers(
                        event.feature.getGeometry(),
                        'grid'
                    );
                }
            );
        }
    }

    disableSelectFeatureInfo(): void {
        // Remove any previous interactions
        this.interactionService.removeInteractions();
    }
}
