import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from 'app/_services';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'cook-approving',
    templateUrl: './approving.dialog.html',
    styleUrls: ['./approving.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * This component is made for approving notifications
 */
export class ApprovingDialogComponent implements OnInit {
    config: any;
    notifications: any;
    searchInput: any;

    constructor(
        private readonly http: HttpClient,
        private readonly configService: ConfigService,
        private readonly dialogRef: MatDialogRef<ApprovingDialogComponent>
    ) {}

    ngOnInit(): void {
        this.config = this.configService.config();
        this.http
            .get(
                environment.api_base_url +
                    '/notifications/config/' +
                    this.config.id
            )
            .subscribe(res => {
                this.notifications = res;
            });
    }

    back(): void {
        this.dialogRef.close();
    }
    //    @shouldRemove code below doesnt get called
    onFileSelected(event, not): void {
        // foreach files
        const file: File = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            const upload$ = this.http.post(
                environment.api_base_url + '/upload',
                formData
            );

            upload$.subscribe(res => {
                const body = not;
                body.files.push(res);
                // @shouldRemove look if this works
                this.http
                    .put(
                        environment.api_base_url +
                            '/notifications/notify/' +
                            not.id,
                        body,
                        {}
                    )
                    .subscribe();
            });
        }
    }

    updateNotificationStatus(not, status): void {
        const body = not;
        body.status = status;

        this.http
            .put(
                environment.api_base_url + '/notifications/notify/' + not.id,
                body,
                {}
            )
            .subscribe(res => {
                console.log(res);
            });
    }

    download(value, name): void {
        const encodedValue = btoa(value);
        this.http
            .get(environment.api_base_url + '/download/' + encodedValue, {
                responseType: 'blob'
            })
            .subscribe(x => {
                value = value.substring(value.lastIndexOf('/') + 1);
                FileSaver.saveAs(x, name);
            });
    }
}
