<div
    class="legendItem"
    [ngClass]="{
        hiddenByResolution: hiddenByResolution(),
        hidden: hide(),
        broken: broken()
    }"
>
    <span
        class="collapseState"
        (click)="toggleCollapse()"
        *ngIf="layersAndGroups().length"
    >
        <!-- <ng-content select="[slot=drag-handle]"></ng-content> -->
        <div *ngIf="collapsed()">
            <fa-icon [icon]="faAngleRight"></fa-icon>
        </div>
        <div *ngIf="!collapsed()">
            <fa-icon [icon]="faAngleDown"></fa-icon>
        </div>
    </span>
    <div class="form-check">
        <input
            type="checkbox"
            class="form-check-input"
            name="inlineRadioOptions"
            id="{{ 'map' + map.id }}"
            [checked]="checked()"
            (click)="toggleMap()"
            (change)="checkboxChanged($event)"
        />

        <label
            matTooltip="{{ tooltip() ?? '' }}"
            matTooltipPosition="below"
            aria-label="Tooltip"
            class="form-check-label"
            for="{{ 'map' + map.id }}"
        >
            <div
                class="dynamicIcon"
                *ngIf="
                    (map.type === 'Vector' && map.styling) ||
                    (map.type === 'WebGLPoints' && map.styling)
                "
            >
                <span class="dot" [style.background-color]="map.styling.color">
                </span>
            </div>
            <svg
                *ngIf="icon()"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="20pt"
                height="18pt"
                [innerHTML]="icon()"
            ></svg>
            {{ label() }}
            <ng-content select="[slot=drag-handle]"> </ng-content>
        </label>
    </div>
    <a
        class="metadata"
        matTooltip="Metadata"
        matTooltip="Metadata"
        matTooltipPosition="below"
        aria-label="Metadata"
        href="{{ map.metadata_url ?? '' }}"
        target="_blank"
        *ngIf="map.metadata_url"
    >
        <fa-icon [icon]="faInfoCircle"> </fa-icon>
    </a>
    <a
        class="metadata"
        (click)="openMetadata()"
        matTooltip="Metadata"
        matTooltip="Metadata"
        matTooltipPosition="below"
        aria-label="Metadata"
        target="_blank"
        *ngIf="map.metadata"
    >
        <fa-icon [icon]="faInfoCircle"> </fa-icon>
    </a>

    <a
        class="opacityButton"
        (click)="changeOpacity(map)"
        *ngIf="checked()"
        matTooltip="Transparantie"
        matTooltipPosition="below"
    >
        <fa-icon [icon]="faAdjust"> </fa-icon>
    </a>
    <a
        class="deleteButton"
        *ngIf="map.created_by == authService.id()"
        (click)="deleteMap()"
        matTooltip="Verwijderen"
        matTooltipPosition="below"
    >
        <fa-icon [icon]="faTimesCircle"> </fa-icon>
    </a>

    <ng-container
        *ngIf="
            authService.id() &&
            (map.type === 'WebGLPoints' || map.type === 'Vector') &&
            configService.config()?.tools?.mapCreator
        "
    >
        <a
            class="styleButton"
            (click)="openStyleDialog(map)"
            matTooltip="Stijling"
            matTooltipPosition="below"
        >
            <fa-icon [icon]="faPaintBrush"> </fa-icon>
        </a>
    </ng-container>

    <ng-container *ngIf="!travel(); else travelContainer">
        <ul
            *ngIf="!collapsed()"
            [ngClass]="{ hidden: collapsed() }"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
        >
            <li
                cdkDrag
                cdkDragLockAxis="y"
                *ngFor="let layerOrGroup of layersAndGroups()"
            >
                <span *cdkDragPreview>{{ layerOrGroup.name }}</span>

                <!-- Check for properties that only exist in a group, if we don't have them, this is a layer -->
                <ng-container
                    *ngIf="!layerOrGroup.layers && !layerOrGroup.layerName"
                >
                    <layer
                        [layer]="layerOrGroup"
                        [map]="map"
                        (checkEvent)="viewChildChecked($event)"
                    >
                        <div
                            class="drag-handle"
                            slot="drag-handle"
                            cdkDragHandle
                        ></div>
                    </layer>
                </ng-container>

                <!-- Check for properties that only exist in a group, if we have them, this is a group -->
                <ng-container
                    *ngIf="layerOrGroup.layers || layerOrGroup.layerName"
                >
                    <cook-group
                        [group]="layerOrGroup"
                        [map]="map"
                        (checkEvent)="viewChildChecked($event)"
                    >
                        <div
                            class="drag-handle"
                            slot="drag-handle"
                            cdkDragHandle
                        ></div>
                    </cook-group>
                </ng-container>
            </li>
        </ul>
    </ng-container>
    <ng-template #travelContainer>
        <span *ngIf="!collapsed()" [ngClass]="{ hidden: collapsed() }">
            <br /><br />
            <div
                class="timetravel-slider"
                [style.height]="layersAndGroups().length * 20 + 'px'"
            >
                <ngx-slider
                    [(value)]="sliderOptions.value"
                    [options]="sliderOptions.options"
                    (userChangeEnd)="sliderChange($event)"
                >
                </ngx-slider>
            </div>
        </span>
    </ng-template>
</div>
