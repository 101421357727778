<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> CSV File </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div
            class="drag-drop-area"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
        >
            <label for="txtFileUpload" class="form-label"
                >Sleep hier je CSV-bestanden of klik om te uploaden</label
            >
            <input
                type="file"
                #csvReader
                class="form-control"
                name="Upload CSV"
                id="txtFileUpload"
                (change)="uploadListener($event)"
                accept=".csv"
            />
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel
        [expanded]="step === 1"
        hideToggle
        *ngIf="headersRow && headersRow.length"
    >
        <mat-expansion-panel-header>
            <mat-panel-title> Coordinates </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="">
            <h4 class="alert alert-danger" *ngIf="error">{{ error }}</h4>
            <div class="row">
                <mat-form-field>
                    <mat-label>X waarde</mat-label>
                    <mat-select [(value)]="xCoordinateName">
                        <mat-option *ngFor="let h of headersRow" [value]="h">
                            {{ h }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                    <mat-label>Y waarde</mat-label>
                    <mat-select [(value)]="yCoordinateName">
                        <mat-option *ngFor="let h of headersRow" [value]="h">
                            {{ h }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-action-row>
            <button mat-button color="primary" (click)="convertToGeoJson()">
                Next
            </button>
        </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" hideToggle *ngIf="geoJson">
        <mat-expansion-panel-header>
            <mat-panel-title> Result </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="resourceFormGroup" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>Naam</mat-label>
                <input matInput type="text" formControlName="name" />
            </mat-form-field>

            <div class="col float-end">
                <button mat-raised-button color="primary" type="submit" i18n>
                    Opslaan
                </button>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>
