<h3 class="p-3 text-align-center">Grid bewerken</h3>
<button mat-icon-button class="close-button" [mat-dialog-close]="data">
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select
            [(ngModel)]="data.type"
            (selectionChange)="onTypeChange($event.value)"
        >
            <mat-option value="columns"> Tabel</mat-option>
            <mat-option value="line"> Lijn grafiek </mat-option>
            <mat-option value="pie"> Taart grafiek </mat-option>
            <mat-option value="bar"> Staaf grafiek </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="data.type === 'columns'">
        <table
            mat-table
            [dataSource]="data"
            matSortDirection="asc"
            matSort
            #sort{{i}}="matSort"
        >
            <ng-container
                *ngFor="let column of data.columns"
                [matColumnDef]="column.prop"
            >
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <b>{{ column.name }}</b>
                </th>
                <td mat-cell *matCellDef="let element" [ngSwitch]="column.type">
                    <a
                        *ngSwitchCase="'link'"
                        href="{{ element[column.prop] }}"
                        target="_blank"
                    >
                        <span *ngIf="element[column.prop]">
                            Detailinformatie
                        </span>
                    </a>
                    <img
                        *ngSwitchCase="'image'"
                        class="image"
                        [src]="element[column.prop]"
                    />
                    <a *ngSwitchCase="'vrimage'" [routerLink]="[]"
                        >Open 360 Afbeelding</a
                    >
                    <a *ngSwitchCase="'video'" [routerLink]="[]"
                        >Bekijk video</a
                    >
                    <span *ngSwitchDefault>{{ element[column.prop] }}</span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="data.columnNames"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: data.columnNames"
                class="table-row"
            ></tr>
        </table>
    </div>

    <div
        *ngIf="
            data.type === 'line' || data.type === 'bar' || data.type === 'pie'
        "
    >
        <mat-form-field class="mt-0">
            <mat-label>Kolom</mat-label>
            <mat-select
                [(ngModel)]="selectedColumn"
                (selectionChange)="columnChange($event.value)"
            >
                <mat-option
                    *ngFor="let column of data.columnNames"
                    [value]="column"
                >
                    {{ column }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="mt-0">
            <mat-label>Grafiek functie</mat-label>
            <mat-select
                [(ngModel)]="chartType"
                (selectionChange)="chartFunctionChange($event.value)"
            >
                <mat-option value="sum"> Optellen </mat-option>
                <mat-option value="average"> Gemiddelde </mat-option>
                <!-- <mat-option value="sum"> Optellen </mat-option> -->
            </mat-select>
        </mat-form-field>

        <br />

        <canvas class="graph" #baseChart></canvas>
    </div>
</mat-dialog-content>
