import { Injectable } from '@angular/core';
import { MapService } from './map.service';

@Injectable({
    providedIn: 'root'
})
export class ClickService {
    constructor(private mapService: MapService) {}

    zoomToCluster(pixel): void {
        const featureAtPixel = this.mapService
            .map()
            .forEachFeatureAtPixel(pixel, (feature: any, layer: any) => {
                return [feature, layer];
            });

        if (featureAtPixel) {
            // Make sure there is a feature, otherwise we're drawing
            if (featureAtPixel[0]) {
                // Check if we are dealing with a project, if so show the detail component in the sidebar
                const mapView = this.mapService.map().getView();

                // Check if there are multiple features (meaning we have a cluster), if so, zoom in on the map
                if (featureAtPixel[0].get('features')) {
                    mapView.animate({
                        zoom: mapView.getZoom() + 2,
                        center: featureAtPixel[0]
                            .get('geometry')
                            .getFirstCoordinate()
                    });
                }
            }
        }
    }
}
