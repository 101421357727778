import { Component, Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MapService } from 'app/_services';
import { FormBuilder, Validators } from '@angular/forms';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { Stroke, Style, Fill, Icon } from 'ol/style.js';
import { Vector as LayerVector } from 'ol/layer';
import { Vector as SourceVector } from 'ol/source';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

export interface ZoomToCoordinateDialogData {
    coordinate: string;
    zoom: number;
}

@Component({
    selector: 'zoom-to-coordinate',
    templateUrl: './zoom-to-coordinate.component.html',
    styleUrls: ['./zoom-to-coordinate.component.css']
})
/**
 * You can use the tool zoom to coordinate by typing a coordinate in the searchbar like 165608.44, 402461.44
 * You can find the searchbar in the left top of the map
 */
export class ZoomToCoordinateComponent {
    faSearchLocation = faSearchLocation;

    coordinate: any;
    zoom: any;
    constructor(public dialog: MatDialog, private mapService: MapService) {}

    openDialog(): void {
        // open name dialog
        const dialogRef = this.dialog.open(ZoomToCoordinateDialogComponent, {
            data: {
                coordinate: this.coordinate,
                zoom: this.zoom
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result) {
                this.coordinate = result.coordinate;
                this.zoom = result.zoom;
                this.zoomToCoordinate();
            }
        });
    }

    zoomToCoordinate(): void {
        const coordinate = this.coordinate.split(',').map(function (item) {
            return parseInt(item, 10);
        });
        const iconStyle = new Style({
            fill: new Fill({
                color: 'rgba(0, 51, 255, 0.5)'
            }),
            stroke: new Stroke({
                color: '#0033ff',
                width: 5
            }),
            // @shouldRemove IconAnchorUnits
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: `assets/img/icons/map-marker-orange.png`
            })
        });

        const layer = new LayerVector({
            style: iconStyle,
            source: new SourceVector({
                features: [
                    new Feature({
                        geometry: new Point(coordinate),
                        name: 'Coordinaat'
                    })
                ]
            })
        });

        this.mapService.map().addLayer(layer);

        if (!this.zoom) {
            this.zoom = this.mapService.map().getView().getZoom();
        }
        // Set the map to the coordinate
        this.mapService
            .map()
            .getView()
            .animate({ zoom: this.zoom, center: coordinate });
    }
}

// Dialog for the Name of the saved location
@Component({
    selector: 'custom-extent-dialog',
    template: `
        <div mat-dialog-content>
            <h5>Vul hier je coordinaat in.</h5>
            <br />
            <p>bijvoorbeeld: 165608.44, 402461.44</p>
            <i>Je kunt het coordinaat nu ook in de zoekbalk plakken.</i>
            <br />
            <br />
            <form [formGroup]="coordinateForm">
                <mat-form-field>
                    <input
                        matInput
                        formControlName="coordinate"
                        placeholder="X,Y Coordinaat"
                        required
                    />
                    <mat-error *ngIf="coordinateForm.controls.coordinate.errors"
                        >Gebruik alsjeblieft de bovenstaande
                        schrijfwijze.</mat-error
                    >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Schaal niveau</mat-label>
                    <mat-select formControlName="zoom">
                        <mat-option>Huidig Schaalniveau</mat-option>
                        <mat-option value="16">1:250</mat-option>
                        <mat-option value="15">1:500</mat-option>
                        <mat-option value="14">1:2500</mat-option>
                        <mat-option value="13">1:5000</mat-option>
                        <mat-option value="12">1:10000</mat-option>
                        <mat-option value="11">1:25000</mat-option>
                        <mat-option value="10">1:50000</mat-option>
                        <mat-option value="9">1:100000</mat-option>
                        <mat-option value="8">1:250000</mat-option>
                        <mat-option value="7">1:375000</mat-option>
                        <mat-option value="6">1:500000</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
        <div mat-dialog-actions style="justify-content: right;">
            <button mat-button (click)="onNoClick()">Sluiten</button>
            <button
                mat-button
                [disabled]="!coordinateForm.valid"
                [mat-dialog-close]="coordinateForm.value"
            >
                Zoeken
            </button>
        </div>
    `
})
export class ZoomToCoordinateDialogComponent {
    coordinateForm: any;
    constructor(
        public dialogRef: MatDialogRef<ZoomToCoordinateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ZoomToCoordinateDialogData,
        private fb: FormBuilder
    ) {
        this.coordinateForm = this.fb.group({
            coordinate: [
                '',
                [
                    Validators.required,
                    Validators.pattern(/^\d+(?:.\d+)\s?,\s?\d+(?:.\d+)$/)
                ]
            ],
            zoom: []
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
