import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { SidenavService } from './sidenav.service';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is used to open the left grid for the detail information
 */
export class DetailService {
    readonly features = signal(undefined);
    readonly featuresChange: Subject<any> = new Subject();

    constructor(private readonly sidenavService: SidenavService) {}

    setFeatures(newFeatures: any) {
        this.features.set(newFeatures);
        this.featuresChange.next(this.features());

        if (newFeatures) {
            this.sidenavService.setWidth(380, 'px');
            this.sidenavService.tabIndex.set(1);
        }
    }
}
