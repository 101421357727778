import { Injectable, signal, WritableSignal } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
/**
 * Service for editing features
 */
export class EditService {
    readonly layer = signal(undefined);
    readonly feature = signal(undefined);
    readonly featureType: WritableSignal<string> = signal(undefined);
    // Geoserver namespace
    readonly namespace: WritableSignal<string> = signal(undefined);

    constructor() {}

    setfeatureType(newFeatureType: string) {
        this.featureType.set(newFeatureType);

        if (newFeatureType) {
            // this.sidenavService.sidenavOpened.set(true);
            // this.sidenavService.tabIndex.set(1);
        }
    }
}
