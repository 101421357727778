import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'app/_services';
import { environment } from 'environments/environment';
import {
    FormBuilder,
    Validators,
    FormGroup
} from '@angular/forms';

@Component({
    selector: 'password',
    templateUrl: 'password.component.html',
    styleUrls: ['password.component.css']
})
/**
 * Small component for handling the password
 */
export class PasswordComponent {
    hide1 = true;
    hide2 = true;
    password: string;
    confirmPassword: string;
    error = '';
    readonly env = environment;

    resetForm: FormGroup;

    constructor(
        public configService: ConfigService,
        private router: Router,
        private http: HttpClient,
        private fb: FormBuilder
    ) {
        this.resetForm = this.fb.group({
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()\\-\\?_=+{};:,<.>])[A-Za-z\\d!@#$%^&*()\\-\\?_=+{};:,<.>]{1,}$'
                    )
                ]
            ],
            confirmPassword: [
                '',
                [Validators.required, Validators.minLength(8)]
            ]
        });
    }

    /**
     * Updates the users password
     */
    updatePassword(): void {
        const body = {
            password: this.resetForm.get('password').value,
            password_confirmation: this.resetForm.get('confirmPassword').value
        };

        this.http
            .post(this.env.api_base_url + '/users/password', body, {
                responseType: 'json'
            })
            .subscribe(
                (response: any) => {
                    // User is already logged in so sent him home
                    if (response.success) {
                        // Password change successful
                        this.router.navigate(['/']);

                        // Get config the first time
                        this.configService.loadConfiguration();
                    }
                },
                error => {
                    // clear last error
                    this.error = error.error.password;
                }
            );
    }

    // For short syntax in HTML
    get f() {
        return this.resetForm.controls;
    }
}
