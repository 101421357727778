import { Component, EffectRef, effect } from '@angular/core';
import { MapService, InteractionService, ConfigService } from 'app/_services';
import { faDove } from '@fortawesome/free-solid-svg-icons';
import { unByKey } from 'ol/Observable';
import { transform } from 'ol/proj';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'oblique',
    templateUrl: 'oblique.component.html',
    styleUrls: ['oblique.component.css']
})
/**
 * This tool sends you to the oblique website with the clicked location
 * On the right of the Map
 */
export class ObliqueComponent {
    readonly faDove = faDove;

    obliqueConfig: any;
    readonly configSubscription: EffectRef;

    active = false;

    constructor(
        private readonly mapService: MapService,
        private readonly configService: ConfigService,
        private readonly http: HttpClient,
        public interactionService: InteractionService
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();

                if (!config) return;

                this.obliqueConfig = config.tools.oblique;
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    /**
     * Uses the click coordinates to open a oblique url
     * @return [description]
     */
    openOblique(): void {
        if (this.interactionService.oblique.active) {
            this.interactionService.removeInteractions();
            return;
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.oblique.active =
                !this.interactionService.oblique.active;
        }

        if (!this.interactionService.oblique.interaction) {
            console.log(this.obliqueConfig.token);

            // Create fallback for invalid configs
            this.obliqueConfig.client = this.obliqueConfig.client
                ? this.obliqueConfig.client
                : 'slagboomEnPeters';

            this.interactionService.oblique.interaction = this.mapService
                .map()
                .on('singleclick', e => {
                    let coordinate = this.mapService
                        .map()
                        .getCoordinateFromPixel(e.pixel);

                    switch (this.obliqueConfig.client) {
                        case 'slagboomEnPeters':
                            // coordinate = transform(coordinate, 'EPSG:28992', 'EPSG:4326');
                            // @shouldRemove creds for oblique
                            window.open(
                                `https://app.slagboomenpeeters.com/${this.obliqueConfig.token}?x=${coordinate[0]}&y=${coordinate[1]}&z=12&mode=oblique`
                            );
                            break;
                        case 'vision10':
                            console.log(this.obliqueConfig)
                            window.open(
                                `${this.obliqueConfig.token}/?state=oblique&x=${coordinate[0]}&y=${coordinate[1]}&api_key=${this.obliqueConfig.password}`
                            );
                            break;
                        case 'obliqueCloud':
                            // First get the jwt token from the app
                            // @shouldRemove vscode error
                            const body = {
                                email: this.obliqueConfig.email,
                                password: this.obliqueConfig.password
                            };

                            this.http
                                .post(
                                    'https://kavel10.obliquo.cloud/manager/auth',
                                    body
                                )
                                .toPromise()
                                .then((_res: any) => {
                                    coordinate = transform(
                                        coordinate,
                                        'EPSG:28992',
                                        'EPSG:3857'
                                    );

                                    // Now we have the token we can get the location
                                    const body2 = {
                                        x: coordinate[0],
                                        y: coordinate[1]
                                    };

                                    let headers = new HttpHeaders();
                                    headers = headers.set(
                                        'Authorization',
                                        `Bearer ${_res.token}`
                                    );
                                    this.http
                                        .post(
                                            'https://kavel10.obliquo.cloud/manager/elink',
                                            body2,
                                            { headers }
                                        )
                                        .toPromise()
                                        .then((_res2: any) => {
                                            console.log(_res2);
                                            window.open(
                                                `https://kavel10.obliquo.cloud/?e=${_res2.linkID}`
                                            );
                                        })
                                        .catch();
                                })
                                .catch();
                            break;
                        default:
                            break;
                    }
                });
        } else {
            // Remove the streetsmart event, the tool was deactivated
            unByKey(this.interactionService.oblique.interaction);
            this.interactionService.oblique.interaction = undefined;
        }
    }
}
