<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    matTooltip="Coördinaat Prikker"
    matTooltipPosition="left"
    (click)="getCoordinate()"
    [ngClass]="{ active: interactionService.getCoordinate.active }"
>
    <fa-icon [icon]="faMapMarkedAlt"></fa-icon>
</button>
