import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {
    // Returns file name with date Day/Month/Year
    private static generateExportFileName(excelFileName: string): string {
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${excelFileName}_${day}-${month}-${year}.xlsx`;
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data']
        };
        const exportFileName =
            ExcelService.generateExportFileName(excelFileName);
        XLSX.writeFile(workbook, exportFileName);
    }
}
