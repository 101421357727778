<div class="legendItem">
    <span class="collapseState" (click)="toggleCollapse()">
        <div *ngIf="!collapsed()">
            <fa-icon [icon]="faAngleRight"></fa-icon>
        </div>
        <div *ngIf="collapsed()">
            <fa-icon [icon]="faAngleDown"></fa-icon>
        </div>
    </span>
    <div class="form-check">
        <input
            type="checkbox"
            name="inlineRadioOptions"
            class="form-check-input"
            id="{{ name() + label() }}"
            [checked]="checked()"
            (change)="checkboxChanged($event)"
            (click)="toggleGroup()"
        />

        <label class="form-check-label" for="{{ name() + label() }}">
            {{ label() }}
            <ng-content select="[slot=drag-handle]"></ng-content>
        </label>
    </div>
    <a
        class="metadata"
        href="{{ metadataUrl() ?? '' }}"
        target="_blank"
        *ngIf="metadataUrl()"
        ><fa-icon [icon]="faInfoCircle"></fa-icon
    ></a>
    <ul
        *ngIf="maps()"
        [ngClass]="{ hidden: !collapsed() }"
        id="mapGroup"
        cdkDropList
        [cdkDropListData]="maps()"
        (cdkDropListDropped)="drop($event)"
    >
        <li cdkDrag cdkDragLockAxis="y" *ngFor="let map of maps()">
            <span *cdkDragPreview>{{ map.name }}</span>
            <ng-container *ngIf="map.source">
                <cook-map
                    *ngIf="!map.background"
                    [map]="map"
                    (checkEvent)="viewChildChecked($event)"
                >
                    <div
                        class="drag-handle"
                        slot="drag-handle"
                        cdkDragHandle
                    ></div>
                </cook-map>
            </ng-container>
        </li>
    </ul>
</div>
