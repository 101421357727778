import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormControl,
    Validators,
    FormBuilder,
    FormGroup
} from '@angular/forms';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-password-reset',
    templateUrl: './user-password-reset.dialog.html',
    styleUrls: ['./user-password-reset.dialog.css']
})
/**
 * Dialog for resetting the password
 */
export class UserPasswordResetDialogComponent implements OnInit {
    hide = true;
    hide_new = true;
    hide_repeated = true;
    error = '';
    newPassword: FormControl;
    repeatedPassword: FormControl;
    resetUserPasswordFormGroup: FormGroup;

    constructor(
        private readonly dialogRef: MatDialogRef<UserPasswordResetDialogComponent>,
        private readonly snackBar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.resetUserPasswordFormGroup = this.fb.group({
            newPassword: ['', [Validators.required, Validators.minLength(8)]],
            repeatedPassword: [
                '',
                [Validators.required, Validators.minLength(8)]
            ]
        });
    }

    changePassword(): void {
        const body = this.resetUserPasswordFormGroup.getRawValue();
        console.log(body);

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            'application/json; charset=utf-8'
        );
        this.http
            .post(environment.api_base_url + '/users/reset_password', body, {
                observe: 'response',
                responseType: 'json'
            })
            .subscribe(
                (response: any) => {
                    if (response.status >= 200 && response.status <= 299) {
                        this.snackBar.open(
                            'Wachtwoord succesvol gewijzigd',
                            'Ok',
                            {
                                duration: 3500,
                                panelClass: 'white-snackbar'
                            }
                        );
                        this.back();
                    } else if (response.message) this.error = response.message;
                },
                error => {
                    if (error.error) {
                        this.snackBar.open(error.error.password, 'Ok', {
                            duration: 3500,
                            panelClass: 'white-snackbar'
                        });
                    } else {
                        this.snackBar.open(
                            'Er is iets fout gegaan probeer het opnieuw',
                            'Ok',
                            {
                                duration: 3500,
                                panelClass: 'white-snackbar'
                            }
                        );
                    }
                }
            );
    }

    back(): void {
        this.dialogRef.close();
    }
}
