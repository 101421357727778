<mat-list class="" *ngIf="files.length; else noFiles">
    <mat-list-item *ngFor="let f of files">
        <div>
            <button mat-icon-button (click)="download(f)" class="light-icon">
                <mat-icon>cloud_download</mat-icon>
            </button>
            {{ f.substring(f.lastIndexOf('/') + 1) }}
        </div>
    </mat-list-item>
</mat-list>
<ng-template #noFiles>
    <p>U heeft nog geen downloads</p>
</ng-template>
