import { ChangeDetectionStrategy, Component, ViewChild, signal } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService, ConfigService } from '../../_services';
import { environment } from 'environments/environment';
import { Title } from '@angular/platform-browser';
import {
    faTasks,
    faUserCircle,
    faCogs,
    faTags,
    faScroll,
    faMap,
    faUser,
    faBalanceScale,
    faDownload,
    faSignOut,
    faDatabase,
    faHeartPulse,
    faUndo,
    faCar,
    faBell,
    faChartLine,
    faBook
} from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
    readonly faTasks = faTasks;
    readonly faUserCircle = faUserCircle;
    readonly faUser = faUser;
    readonly faCogs = faCogs;
    readonly faTags = faTags;
    readonly faScroll = faScroll;
    readonly faMap = faMap;
    readonly faBalanceScale = faBalanceScale;
    readonly faDownload = faDownload;
    readonly faSignOut = faSignOut;
    readonly faDatabase = faDatabase;
    readonly faHeartbeat = faHeartPulse;
    readonly faUndo = faUndo;
    readonly faCar = faCar;
    readonly faBell = faBell;
    readonly faChartLine = faChartLine;
    readonly faBook = faBook;

    readonly showMenu = signal(false);
    readonly loading = signal(false);
    readonly env = environment;

    @ViewChild('menuTrigger') private readonly menuTrigger: MatMenuTrigger;

    constructor(
        readonly router: Router,
        readonly authService: AuthService,
        private readonly configService: ConfigService,
        private readonly titleService: Title
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (
                    event.url.startsWith('/login') ||
                    event.url.startsWith('/password')
                ) {
                    this.showMenu.set(false);
                    this.configService.configLoading.set(false);
                } else if (event.url.startsWith('/admin/')) {
                    this.closeMenu();
                    this.configService.configLoading.set(false);
                    this.titleService.setTitle('COOK - Beheeromgeving');
                    this.showMenu.set(true);
                } else {
                    this.showMenu.set(false);
                }
            });
    }

    logout(): void {
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    toggleHamburgerMenu(): void {
        const tag = document.getElementById('hamburgerMenu');
        tag.style.display = tag.style.display === 'block' ? 'none' : 'block';
    }

    closeHamburgerMenu(): void {
        document.getElementById('hamburgerMenu').style.display = 'none';
    }

    leavePage(): void {
        this.logout();
        document.getElementById('confirm-dialog').style.display = 'none';
    }

    stayOnPage(): void {
        this.router.navigate(['']);
        document.getElementById('confirm-dialog').style.display = 'none';
    }

    private closeMenu(): void {
        if (this.menuTrigger) {
            this.menuTrigger.closeMenu();
        } else {
            console.log('Menu trigger is not available.');
        }
    }
}
