<div>
    <h4>Filters</h4>
    <ul *ngFor="let filter of filters(); let i = index">
        <div class="state" (click)="toggleFilterCollapse(filter)">
            <h5>
                <span *ngIf="filter.collapsed">
                    <fa-icon [icon]="faAngleRight"></fa-icon>
                </span>
                <span *ngIf="!filter.collapsed">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                </span>
                {{ filter.name }}
            </h5>
        </div>
        <ng-container *ngIf="!filter.collapsed">
            <li *ngIf="filter?.timeFilter" class="p-4">
                <mat-form-field class="row w-100">
                    <mat-label>Kolom naam</mat-label>
                    <input
                        name="column"
                        matInput
                        type="text"
                        [(ngModel)]="filter.timeFilter.column"
                    />
                </mat-form-field>
                <mat-form-field class="row w-100">
                    <mat-label>Start datum</mat-label>
                    <input
                        name="startDate"
                        matInput
                        [matDatepicker]="startPicker"
                        [(ngModel)]="filter.timeFilter.start"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="startPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="row w-100">
                    <mat-label>Eind datum</mat-label>
                    <input
                        name="endDate"
                        matInput
                        [matDatepicker]="endPicker"
                        [(ngModel)]="filter.timeFilter.end"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="endPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
                <button
                    mat-raised-button
                    class="row w-100 text-center"
                    (click)="updateCql(filter)"
                >
                    Update tijd filter
                </button>
            </li>
            <mat-divider *ngIf="filter?.timeFilter"></mat-divider>
            <li class="filter" *ngFor="let f of filter.filters; let j = index">
                <button
                    mat-button
                    (click)="toggleFilter(filter, j)"
                    [ngClass]="{
                        active: f.active,
                        invertActive: f.invertActive
                    }"
                >
                    <div
                        class="square"
                        [style.background-color]="f.color"
                    ></div>
                    {{ f.title }}
                </button>
            </li>
        </ng-container>
    </ul>
</div>
