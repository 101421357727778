import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    signal
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'app/_services';
import { environment } from 'environments/environment';

import Pusher from 'pusher-js';

export interface MessageDialogData {
    id: string;
}

@Component({
    selector: 'message-dialog',
    templateUrl: 'message.dialog.html',
    styleUrl: 'message.dialog.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDialogComponent implements OnInit {
    readonly messages = signal([]);
    pusherInstance: Pusher;
    channel: any;
    readonly message = new FormControl('', [
        Validators.required,
        Validators.minLength(1)
    ]);

    constructor(
        readonly authService: AuthService,
        private readonly dialogRef: MatDialogRef<MessageDialogComponent>,
        private readonly http: HttpClient,
        private readonly dateAdapter: DateAdapter<Date>,
        @Inject(MAT_DIALOG_DATA) readonly data: MessageDialogData
    ) {
        (<any>window).Pusher = Pusher;

        this.dateAdapter.setLocale('nl');
    }

    ngOnInit() {
        const url =
            environment.api_base_url + '/messages/register/' + this.data.id;

        this.http.get(url).subscribe({
            next: (res: any) => {
                this.messages.set(res);
            },
            error: (error: any) => {
                console.error('Error fetching data:', error);
            }
        });

        this.pusherInstance = new Pusher('wjj1dewxges6r84mszgl', {
            // encrypted: true,
            wsHost: environment.base_url,
            // wsPort: 8080,
            enableStats: false,
            forceTLS: false,
            cluster: ''
        });

        this.channel = this.pusherInstance.subscribe(
            'register.' + this.data.id
        );
        this.channel.bind('message', (data: any) => {
            this.messages.set([...this.messages(), data.message]);
        });
    }

    callback(res) {
        console.log(res);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    sendMessage() {
        const body = {
            message: this.message.value,
            register_id: this.data.id
        };
        this.http
            .post(environment.api_base_url + '/messages', body)
            .subscribe(res => {
                this.message.setValue('');
            });
    }
}
