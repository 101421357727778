<div *ngFor="let info of data()">
    <div *ngFor="let feature of info.layers">
        <!-- Detail page for HTA Demo -->

        <div
            class="detail"
            *ngIf="feature.title === 'demo_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'midden-drenthe_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>
        <div
            class="detail"
            *ngIf="feature.title === 'over_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>
        <div
            class="detail"
            *ngIf="feature.title === 'haaksbergen_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'midden-drenthe_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Vlaardingen -->

        <div
            class="detail"
            *ngIf="feature.title === 'vlaardingen_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'aaenhunze_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'meierijstad_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Hogeland -->

        <div
            class="detail"
            *ngIf="feature.title === 'hogeland_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Eemsdelta -->

        <div
            class="detail"
            *ngIf="feature.title === 'eemsdelta_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'beekdaelen_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Schagen -->

        <div
            class="detail"
            *ngIf="feature.title === 'schagen_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Schagen Publiek -->

        <div
            class="detail"
            *ngIf="feature.title === 'schagen_begraafplaatsen_publiek'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Edamvolendam Publiek -->

        <div
            class="detail"
            *ngIf="feature.title === 'edamvolendam_begraafplaatsen_publiek'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Bodegraven-Reeuwijk -->

        <div
            class="detail"
            *ngIf="feature.title === 'bodegraven_reeuwijk_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Oosterwolde Fochteloo -->

        <div
            class="detail"
            *ngIf="
                feature.title === 'oosterwolde_fochteloo_begraafplaatsen_data'
            "
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Edam Volendam -->

        <div
            class="detail"
            *ngIf="feature.title === 'edamvolendam_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Oosterwolde Fochteloo Publiek -->

        <div
            class="detail"
            *ngIf="
                feature.title ===
                'oosterwolde_fochteloo_begraafplaatsen_data_publiek'
            "
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Tholen -->

        <div
            class="detail"
            *ngIf="feature.title === 'tholen_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'simpelveld_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'borne_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'westerkwartier_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab *ngIf="f.get('url')" label="Foto's">
                        <img
                            [src]="'https://data.gisarts.nl/' + f.get('url')"
                            alt="img"
                        />
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'nederlangbroek_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'bernheze_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'buren_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'veere_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'weststellingwerf_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'opsterland_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'kka_begraafplaatsen_data'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="RH">
                        <table class="table">
                            <tr>
                                <th>Naam</th>
                                <td>{{ f.get('rh_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Adres</th>
                                <td>
                                    {{ f.get('rh_straat') }}
                                    {{ f.get('rh_huisnummer') }}
                                </td>
                            </tr>

                            <tr>
                                <th>Woonplaats</th>
                                <td>{{ f.get('rh_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Telefoonnummer</th>
                                <td>{{ f.get('rh_telefoon') }}</td>
                            </tr>

                            <tr>
                                <th>Extra info</th>
                                <td>{{ f.get('rh_memo') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Edamvolendam Publiek -->

        <div
            class="detail"
            *ngIf="feature.title === 'borne_begraafplaatsen_publiek'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <!-- Detail page for HTA Edamvolendam Publiek -->

        <div
            class="detail"
            *ngIf="feature.title === 'veere_begraafplaatsen_publiek'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'haaksbergen_begraafplaatsen_publiek'"
        >
            <p>
                <img src="{{ env.subDirectory }}/assets/img/logo_hta.png" />
            </p>

            <p>
                +31 (0)30 227 33 33 -
                <a href="mailto:info@hta.nl">info&commat;hta.nl</a>
            </p>

            <hr />

            <div *ngFor="let f of feature.features">
                <h4>Grafcode: {{ f.get('g_grafcode') }}</h4>

                <mat-tab-group>
                    <mat-tab label="OL">
                        <table class="table">
                            <tr>
                                <th>Begraafplaats</th>
                                <td>{{ f.get('bp_naam') }}</td>
                            </tr>

                            <tr>
                                <th>Plaats</th>
                                <td>{{ f.get('bp_plaats') }}</td>
                            </tr>

                            <tr>
                                <th>Grafpositie</th>
                                <td>{{ f.get('gp_positie') }}</td>
                            </tr>

                            <tr>
                                <th>Naam overledene</th>
                                <td>{{ f.get('ol_naamgebruik') }}</td>
                            </tr>

                            <tr>
                                <th>Geboortedatum</th>
                                <td>{{ f.get('ol_geboortedatum') }}</td>
                            </tr>

                            <tr>
                                <th>Overlijdensdatum</th>
                                <td>{{ f.get('ol_overlijdensdatum') }}</td>
                            </tr>

                            <tr>
                                <th>Datum begraven</th>
                                <td>{{ f.get('ol_datumbegraven') }}</td>
                            </tr>

                            <tr>
                                <th>Locatieparameter</th>
                                <td>{{ f.get('sleutelgisarts') }}</td>
                            </tr>
                        </table>
                    </mat-tab>

                    <mat-tab label="Foto's" *ngIf="f.get('afbeelding')">
                        <table class="table">
                            <tr
                                *ngFor="
                                    let img of f.get('afbeelding').split(',')
                                "
                            >
                                <img [src]="img" alt="img" />
                            </tr>
                        </table>
                    </mat-tab>
                </mat-tab-group>

                <hr />
            </div>

            <h4>Contact</h4>

            <p>Veldzigt 10 | 3454 PW De Meern | The Netherlands | www.hta.nl</p>
        </div>
    </div>
</div>
