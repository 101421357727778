<div class="form m-1">
    <div class="row">
        <div class="col" *ngIf="showBottemsheet()">
            <h4>Stijling</h4>
        </div>
        <div class="col">
            <div class="text-end">
                <button
                    type="button"
                    class="btn p-0 m-0"
                    (click)="closeBottomsheet()"
                >
                    <p *ngIf="showBottemsheet()" class="m-0 p-0">
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                    </p>
                    <p *ngIf="!showBottemsheet()" class="m-0 p-0">
                        <fa-icon [icon]="faAngleUp"></fa-icon>
                    </p>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="showBottemsheet()">
        <!-- Begin of Circle -->
        <div *ngIf="data.type === 'Circle'">
            <div class="row">
                <div class="col">
                    <span>Invul kleur:</span>
                    <input
                        class="colorPicker"
                        [(colorPicker)]="interactionService.circle.fillColor"
                        [style.background]="interactionService.circle.fillColor"
                        [cpPosition]="'top'"
                        [cpUseRootViewContainer]="true"
                        [cpPositionRelativeToArrow]="false"
                        tabindex="-1"
                    />
                    <span class="ms-1">Lijn kleur:</span>
                    <input
                        class="colorPicker"
                        [(colorPicker)]="interactionService.circle.lineColor"
                        [style.background]="interactionService.circle.lineColor"
                        [cpPosition]="'top'"
                        [cpUseRootViewContainer]="true"
                        [cpPositionRelativeToArrow]="false"
                        tabindex="-1"
                    />
                </div>
            </div>

            <div class="row mt-1">
                <mat-form-field class="col-4">
                    <mat-label>Tekst:</mat-label>
                    <input
                        matInput
                        [(ngModel)]="interactionService.circle.title"
                        type="text"
                    />
                </mat-form-field>
                <!-- <mat-form-field class="col-6">
                    <mat-label>Radius in meters</mat-label>
                    <input
                        matInput
                        type="number"
                        placeholder=""
                        ngModel
                        [(ngModel)]="interactionService.circleRadius"
                        (ngModelChange)="editRadius($event)"
                        appAutoFocus
                    />
                </mat-form-field> -->
            </div>
        </div>
        <!-- End of Circle -->

        <!-- Begin of Point -->
        <div *ngIf="data.type === 'Point'">
            <div class="row">
                <div class="col">
                    <span>Invul kleur:</span>
                    <input
                        class="colorPicker"
                        [(colorPicker)]="interactionService.point.fillColor"
                        [style.background]="interactionService.point.fillColor"
                        [cpPosition]="'top'"
                        [cpUseRootViewContainer]="true"
                        [cpPositionRelativeToArrow]="false"
                        tabindex="-1"
                    />
                    <span class="ms-1">Lijn kleur:</span>
                    <input
                        class="colorPicker"
                        [(colorPicker)]="interactionService.point.lineColor"
                        [style.background]="interactionService.point.lineColor"
                        [cpPosition]="'top'"
                        [cpUseRootViewContainer]="true"
                        [cpPositionRelativeToArrow]="false"
                        tabindex="-1"
                    />

                    <mat-form-field class="ms-1">
                        <mat-label>Tekst:</mat-label>
                        <input
                            matInput
                            [(ngModel)]="interactionService.point.title"
                            type="text"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- End of Point -->

        <!-- Begin of LineString -->
        <div *ngIf="data.type === 'LineString'">
            <div class="row mb-1">
                <div class="col">
                    <span>Lijn kleur:</span>
                    <input
                        class="colorPicker"
                        [(colorPicker)]="interactionService.line.lineColor"
                        [style.background]="interactionService.line.lineColor"
                        [cpPosition]="'top'"
                        [cpUseRootViewContainer]="true"
                        [cpPositionRelativeToArrow]="false"
                        tabindex="-1"
                    />
                    <mat-form-field class="ms-1">
                        <mat-label>Tekst:</mat-label>
                        <input
                            matInput
                            [(ngModel)]="interactionService.line.title"
                            type="text"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <!-- <p class="col-3 mt-2">Lijn breedte: </p> -->
                <!-- <mat-slider class="col-6"
                    color="primary"
                    [min]="1"
                    [max]="30"
                    [step]="1"
                    [(ngModel)]="interactionService.line.lineWidth"
                    >
                </mat-slider> -->
            </div>
        </div>
        <!-- End of LineString -->

        <!-- Begin of Polygon -->
        <div *ngIf="data.type === 'Polygon'">
            <div class="row mb-1">
                <div class="col">
                    <span>Invul kleur:</span>
                    <input
                        class="colorPicker"
                        [(colorPicker)]="interactionService.polygon.fillColor"
                        [style.background]="
                            interactionService.polygon.fillColor
                        "
                        [cpPosition]="'top'"
                        [cpUseRootViewContainer]="true"
                        [cpPositionRelativeToArrow]="false"
                        tabindex="-1"
                    />
                    <span class="ms-1">Lijn kleur:</span>
                    <input
                        class="colorPicker"
                        [(colorPicker)]="interactionService.polygon.lineColor"
                        [style.background]="
                            interactionService.polygon.lineColor
                        "
                        [cpPosition]="'top'"
                        [cpUseRootViewContainer]="true"
                        [cpPositionRelativeToArrow]="false"
                        tabindex="-1"
                    />
                    <mat-form-field class="ms-1">
                        <mat-label>Tekst:</mat-label>
                        <input
                            matInput
                            [(ngModel)]="interactionService.polygon.title"
                            type="text"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- End of Polygon -->
    </div>
</div>
