import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard } from 'app/_guards/auth.guard';
import { RoleGuard } from 'app/_guards/role.guard';
import { PublicGuard } from 'app/_guards/public.guard';

// Components
import { LoginComponent } from 'app/authentication/login/login.component';
import { ResetComponent } from 'app/authentication/reset/reset.component';
import { TwoFactorComponent } from './authentication/two-factor/two-factor.component';
import { MapComponent } from 'app/map/map.component';
import { PasswordComponent } from 'app/authentication/password/password.component';

const appRoutes: Routes = [
    { path: 'login', canActivate: [PublicGuard], pathMatch: 'full', component: LoginComponent },
    {
        path: 'two-factor',
        canActivate: [PublicGuard],
        component: TwoFactorComponent
    },
    { path: 'remote/:username/:token', component: LoginComponent },
    { path: 'remote/:username/:token/:element', component: LoginComponent },

    // Password
    { path: 'password/reset', component: ResetComponent },
    { path: 'password/reset/:token', component: ResetComponent },

    // Protected
    {
        path: '',
        canActivate: [AuthGuard],
        pathMatch: 'prefix',
        children: [
            { path: 'wachtwoord/verlopen', component: PasswordComponent },
            { path: 'locatie/:extent', component: MapComponent },
            { path: 'element/:element', component: MapComponent },
            { path: 'shared/:shared', component: MapComponent},
            { path: 'form/:form_value_id', component: MapComponent},
            { path: '', component: MapComponent }
        ]
    },

    {
        path: 'three-dimensional',
        loadChildren: () =>
            import('./three-dim/three-dim.module').then(m => m.ThreeModule)
    },

    {
        path: ':public',
        children: [
            { path: '', component: MapComponent },
            { path: 'locatie/:extent', component: MapComponent },
            {
                path: 'three-dimensional',
                loadChildren: () =>
                    import('./three-dim/three-dim.module').then(
                        m => m.ThreeModule
                    )
            }
        ]
    },

    // Admin
    {
        path: 'admin',
        canActivate: [RoleGuard],
        data: { roles: ['admin', 'manager'] },
        loadChildren: () =>
            import('./admin/admin.module').then(m => m.AdminModule)
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

const tracing = false;

export const Routing = RouterModule.forRoot(appRoutes, {
    enableTracing: tracing
});
