<form
    [formGroup]="queryFormGroup"
    name="queryForm"
    (ngSubmit)="runQuery()"
    novalidate
    class="queryForm"
>
    <button
        mat-mini-fab
        type="button"
        class="query-manual"
        color="secondary"
        (click)="openManual()"
        matTooltip="Raadpleeg de handleiding."
    >
        <fa-icon [icon]="faInfo"></fa-icon>
    </button>

    <h4>Query generator</h4>
    <div class="row">
        <div class="col-9">
            <mat-form-field class="w-100">
                <input
                    matInput
                    placeholder="Selecteer een kaart laag*"
                    [matAutocomplete]="autoConfig"
                    formControlName="selectedMap"
                    value="mapLayers[0]"
                />
                <mat-autocomplete
                    #autoConfig="matAutocomplete"
                    [displayWith]="displayFn"
                >
                    <mat-option *ngFor="let c of mapLayers" [value]="c">
                        {{ c.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field
                class="w-100"
                *ngIf="queryFormGroup.get('selectedMap').value"
            >
                <mat-select
                    (selectionChange)="getFeatureTypes($event.value)"
                    [value]="
                        queryFormGroup.get('selectedMap').value.source.layers[0]
                            .name
                    "
                >
                    <mat-option
                        *ngFor="
                            let o of queryFormGroup.get('selectedMap').value
                                .source.layers
                        "
                        [value]="o.name"
                    >
                        {{ o.label ?? o.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <p>
                {{ queryService.fields ? '' : 'Er zijn geen velden gevonden' }}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <button
                mat-raised-button
                color="secondary"
                type="button"
                (click)="selectGeometry()"
                matTooltipPosition="left"
                [class.active]="interactionService.queryPoly.active"
            >
                Selecteer een gebied
            </button>
        </div>
    </div>
    <div class="mt-3 pt-1">
        <div
            formArrayName="conditions"
            *ngFor="
                let condition of queryFormGroup.get('conditions')['controls'];
                let i = index
            "
        >
            <div [formGroupName]="i">
                <div class="row justify-content-center" *ngIf="i">
                    <div class="col-6">
                        <mat-button-toggle-group
                            appearance="legacy"
                            formControlName="match"
                            hideSingleSelectionIndicator="true"
                        >
                            <mat-button-toggle
                                *ngFor="let m of matchOptions"
                                [value]="m.filter"
                            >
                                {{ m.name }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-4 query-item">
                        <mat-form-field class="w-100">
                            <mat-select
                                placeholder="Veld"
                                formControlName="property"
                            >
                                <mat-option
                                    *ngFor="let p of queryService.fields"
                                    [value]="p"
                                >
                                    {{ p }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 query-item">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="operator">
                                <mat-option
                                    *ngFor="let o of operatorOptions"
                                    [value]="o.filter"
                                >
                                    {{ o.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4 query-item">
                        <mat-form-field class="w-100">
                            <input
                                matInput
                                value=""
                                formControlName="expression"
                                i18n-placeholder
                            />
                        </mat-form-field>
                    </div>
                    <div class="col-1 query-item">
                        <button
                            type="button"
                            mat-icon-button
                            color="secondary"
                            (click)="removeCondition(i)"
                            class="removeFilter"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-auto">
            <button type="button" mat-button (click)="addCondition()" i18n>
                Voorwaarde toevoegen
            </button>
        </div>
    </div>

    <mat-divider class="mt-2"></mat-divider>
    <div class="button-row">
        <button type="submit" mat-raised-button i18n>Zoeken</button>
    </div>
</form>
