<nav
    class="navbar navbar-expand-md navbar-dark fixed-top bg-dark"
    *ngIf="showMenu()"
>
    <div class="container">
        <a class="navbar-brand btn" (click)="router.navigate([''])">
            <img
                src="{{ env.subDirectory }}/assets/img/logo_cook.svg"
                alt="logo_cook"
                style="max-height: 40px"
            />
        </a>
        <button
            class="navbar-toggler"
            type="button"
            aria-expanded="false"
            (click)="toggleHamburgerMenu()"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div id="hamburgerMenu" class="navbar-collapse">
            <ul
                class="navbar-nav ms-auto align-items-center"
                (click)="closeHamburgerMenu()"
            >
                <li class="nav-item">
                    <a
                        class="nav-link"
                        mat-button
                        [class.active]="router.isActive('/admin/users', false)"
                        [routerLink]="['/', 'admin', 'users', 'manage']"
                        i18n
                    >
                        <fa-icon [icon]="faUserCircle"></fa-icon> Gebruikers
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        mat-button
                        [class.active]="
                            router.isActive('/admin/maps', false) ||
                            router.isActive('/admin/group', false)
                        "
                        [routerLink]="['/', 'admin', 'maps', 'manage']"
                        i18n
                    >
                        <fa-icon [icon]="faMap"></fa-icon> Kaarten
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        mat-button
                        [class.active]="
                            router.isActive('/admin/configurations', false)
                        "
                        [routerLink]="[
                            '/',
                            'admin',
                            'configurations',
                            'manage'
                        ]"
                        i18n
                    >
                        <fa-icon [icon]="faCogs"></fa-icon> Configuraties
                    </a>
                </li>
                <li class="nav-item">
                    <button
                        class="nav-link"
                        mat-button
                        [matMenuTriggerFor]="menu"
                        #menuTrigger="matMenuTrigger"
                        [class.active]="
                            router.isActive('/admin/dashboards', false) ||
                            router.isActive('/admin/logs', false) ||
                            router.isActive('/admin/roles', false) ||
                            router.isActive('/admin/notifications', false) ||
                            router.isActive('/admin/organizations', false) ||
                            router.isActive('/admin/download', false) ||
                            router.isActive('/admin/forms', false) ||
                            router.isActive('/admin/manual', false)
                        "
                    >
                        Extra's
                    </button>
                    <mat-menu #menu="matMenu">
                        <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/dashboards', false)
                            "
                            [routerLink]="['/', 'admin', 'dashboards']"
                            i18n
                        >
                            <fa-icon [icon]="faChartLine"></fa-icon> Dashboards
                        </a>
                        <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/logs', false)
                            "
                            [routerLink]="['/', 'admin', 'logs']"
                            i18n
                        >
                            <fa-icon [icon]="faTasks"></fa-icon> Login
                            statistieken
                        </a>
                        <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/roles', false)
                            "
                            [routerLink]="['/', 'admin', 'roles', 'manage']"
                            *ngIf="authService.hasPermission('admin')"
                            i18n
                        >
                            <fa-icon [icon]="faTags"></fa-icon> Accountsoorten
                        </a>
                        <!-- <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/notifications', false)
                            "
                            [routerLink]="[
                                '/',
                                'admin',
                                'notifications',
                                'manage'
                            ]"
                            i18n
                        >
                            <fa-icon [icon]="faBell"></fa-icon> Notificaties
                        </a> -->
                        <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/organizations', false)
                            "
                            [routerLink]="[
                                '/',
                                'admin',
                                'organizations',
                                'manage'
                            ]"
                            *ngIf="authService.hasPermission('admin')"
                            i18n
                        >
                            <fa-icon [icon]="faBalanceScale"></fa-icon>
                            Organisaties
                        </a>
                        <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/download', false)
                            "
                            [routerLink]="['/', 'admin', 'download']"
                            *ngIf="authService.hasPermission('admin')"
                            i18n
                        >
                            <fa-icon [icon]="faDownload"></fa-icon> Downloads
                        </a>
                        <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/forms', false)
                            "
                            [routerLink]="['/', 'admin', 'forms', 'manage']"
                            *ngIf="
                                authService.hasPermission(['admin', 'manager'])
                            "
                            i18n
                        >
                            <fa-icon [icon]="faScroll"></fa-icon> Formulieren
                        </a>
                        <a
                            mat-menu-item
                            [class.active]="
                                router.isActive('/admin/manual', false)
                            "
                            [routerLink]="['/', 'admin', 'manual']"
                            i18n
                        >
                            <fa-icon [icon]="faChartLine"></fa-icon> Handleiding
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-menu-item (click)="logout()" i18n>
                            <fa-icon [icon]="faSignOut"></fa-icon> Uitloggen
                        </a>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </div>
</nav>

<mat-progress-bar mode="indeterminate" *ngIf="loading()"></mat-progress-bar>
