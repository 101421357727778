import {
    Component,
    EffectRef,
    Injector,
    OnDestroy,
    effect,
    signal
} from '@angular/core';
import {
    ConfigService,
    GridService,
    LegendService,
    MapService,
    SidenavService
} from 'app/_services';
import { environment } from 'environments/environment';
import { grid } from 'app/_extra';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MetadataDialogComponent } from '../../_dialogs/metadata/metadata.dialog';

@Component({
    selector: 'background',
    templateUrl: './background.component.html',
    styleUrls: ['./background.component.scss'],
    animations: [grid]
})
/**
 * This is the tool for changing the background Map
 * On the left bottom of the Map
 */
export class BackgroundComponent implements OnDestroy {
    readonly faInfoCircle = faInfoCircle;
    readonly backgroundMaps = signal([]);
    readonly activeBackgroundMap = signal(undefined);
    readonly nextBackgroundMap = signal(undefined);

    private configSubscription: EffectRef;
    config: any;
    readonly env = environment;

    constructor(
        readonly gridService: GridService,
        readonly configService: ConfigService,
        readonly sidenavService: SidenavService,
        private readonly dialog: MatDialog,
        private readonly mapService: MapService,
        private readonly legendService: LegendService,
        private readonly injector: Injector
    ) {
        this.configSubscription = effect(
            () => {
                this.backgroundMaps.set([]);
                this.activeBackgroundMap.set(undefined);
                this.nextBackgroundMap.set(undefined);

                const config = this.configService.config();

                if (!config) return;

                this.config = this.configService.config();
                setTimeout(() => {
                    this.setBackgroundMaps(this.config);
                }, 500);
            },
            {
                injector: this.injector,
                allowSignalWrites: true
            }
        );
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    getImageSrc(map: any): string {
        if (
            map?.pivot?.background_image &&
            map.pivot.background_image != undefined &&
            map.pivot.background_image != null
        ) {
            return `${this.env.subDirectory}/assets/img/map_backgrounds/${map.pivot.background_image}.png`;
        } else if (map === 'empty') {
            return `${this.env.subDirectory}/assets/img/map_backgrounds/empty2.png`;
        } else {
            return `${this.env.subDirectory}/assets/img/map_backgrounds/map.png`;
        }
    }

    setBackgroundMaps(config): void {
        // Get all background layers from the config
        this.backgroundMaps.set(
            config.maps.filter(m => m.pivot.background == true)
        );
        // Turn all background layers off except for one
        this.mapService
            .map()
            .getLayers()
            .forEach(map => {
                if (map.get('background')) {
                    map.setVisible(false);
                }
            });

        const orderedMaps = this.backgroundMaps().sort(
            (n1, n2) => n1.pivot.order - n2.pivot.order
        );

        this.activeBackgroundMap.set(this.backgroundMaps()[0]);

        this.legendService.toggleMap(this.activeBackgroundMap());

        if (orderedMaps.length <= 2 && this.activeBackgroundMap()) {
            const noArray = orderedMaps.filter(
                m => m.name !== this.activeBackgroundMap().name
            );
            this.nextBackgroundMap.set(noArray[0]);
        }
    }

    setActive(map): void {
        if (map === 'empty') {
            // Turns active map off
            this.legendService.toggleMap(this.activeBackgroundMap());
            // Clear the active map
            this.activeBackgroundMap.set(map);
        } else {
            // Turns new map on
            this.legendService.toggleMap(map);

            // Turns active map off if there is one
            this.legendService.toggleMap(this.activeBackgroundMap());

            // Sets the background for when there are only 2 maps
            this.nextBackgroundMap.set(this.activeBackgroundMap());
            this.activeBackgroundMap.set(map);

            Object.entries(this.legendService.customMap).forEach(
                ([key, val]) => {
                    if (this.legendService.customMap[key].pivot?.background) {
                        this.legendService.customMap[key].visible = false;
                    }
                }
            );

            if (this.activeBackgroundMap()) {
                const currentBackground = this.activeBackgroundMap();
                const updatedBackground = {
                    ...currentBackground,
                    visible: true
                };
                this.activeBackgroundMap.set(updatedBackground);
                this.legendService.customMap[updatedBackground.name] =
                    updatedBackground;
            }
        }
    }

    metadata(map): void {
        // open the delete dialog to confirm the delete
        this.dialog.open(MetadataDialogComponent, {
            data: {
                descriptor: map.name,
                metadata: JSON.parse(map.metadata)
            }
        });
    }
}
