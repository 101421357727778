import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is for routing inside the Map
 */
export class RoutingService {
    _coordinate: any;
    coordinateChange: Subject<any> = new Subject();

    _endpoint: any;
    endpointChange: Subject<any> = new Subject();

    geolocationEnabled = false;

    set coordinate(newCoordinate: any) {
        this._coordinate = newCoordinate;
        this.coordinateChange.next(this._coordinate);
    }

    get coordinate(): any {
        return this._coordinate;
    }

    set endpoint(newEndpoint: any) {
        this._endpoint = newEndpoint;
        this.endpointChange.next(this._endpoint);
    }

    get endpoint(): any {
        return this._endpoint;
    }
}
