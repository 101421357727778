<button
    *ngIf="featureService.clicks && featureService.clicks.length"
    mat-mini-fab
    color="secondary"
    class="tool"
    (click)="selectFeatureInfo()"
    matTooltip="Creëer een buffer rondom de huidige selectie"
    matTooltipPosition="left"
    [class.active]="interactionService.buffer.active"
>
    <div class="buffer-icon"></div>
</button>
