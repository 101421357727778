<div class="customExtent">
    <h4>Opgeslagen Locaties</h4>

    <mat-list
        *ngIf="customExtentService.customExtents()?.length; else noExtents"
    >
        <mat-list-item *ngFor="let v of customExtentService.customExtents()">
            <button mat-button (click)="loadView(v)">
                {{ v.name }}
                <fa-icon class="ms-3 light-icon" [icon]="faMapPin"></fa-icon>
            </button>
            <mat-divider></mat-divider>
        </mat-list-item>
    </mat-list>
    <ng-template #noExtents>
        U heeft nog geen opgeslagen locaties. Om er een toe te voegen, navigeer
        naar de gewenste locatie en druk op de tool met het volgende icoontje
        <fa-icon [icon]="faMapPin"></fa-icon>. Geef het een naam en dan kun je
        de locatie hier terug vinden.
    </ng-template>
</div>
