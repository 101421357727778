import { Injectable } from '@angular/core';

// import { MapService } from './map.service';

@Injectable({
    providedIn: 'root'
})
export class FormService {
    formLayer: any;
    form: any;
    // constructor(private readonly mapService: MapService) {

    // }
}
