<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Kml </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div
            class="drag-drop-area"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
        >
            <label for="txtFileUpload" class="form-label"
                >Sleep hier je KML-bestanden of klik om te uploaden</label
            >
            <input
                type="file"
                #kmlReader
                class="form-control"
                name="Upload KML"
                id="txtFileUpload"
                (change)="uploadListener($event)"
                accept=".kml"
            />
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 1" hideToggle *ngIf="geoJson">
        <mat-expansion-panel-header>
            <mat-panel-title> Result </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="resourceFormGroup" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>Naam</mat-label>
                <input matInput type="text" formControlName="name" />
            </mat-form-field>

            <div class="col float-end">
                <button mat-raised-button color="primary" type="submit" i18n>
                    Opslaan
                </button>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>
