<div mat-dialog-content>
    <h3>Kaart aanmaken</h3>
    <mat-form-field>
        <mat-label>Formaat</mat-label>
        <mat-select [(ngModel)]="type">
            <mat-option value="csv">CSV</mat-option>
            <mat-option value="kml">KML</mat-option>
            <mat-option value="dwg">DWG</mat-option>
            <mat-option value="shape">Shape</mat-option>
            <mat-option value="wms">WMS</mat-option>
            <mat-option value="xlsx">Excel (xlsx)</mat-option>
        </mat-select>
    </mat-form-field>
    <from-csv
        (changeLoading)="changeLoading($event)"
        *ngIf="type === 'csv'"
    ></from-csv>
    <from-kml
        (changeLoading)="changeLoading($event)"
        *ngIf="type === 'kml'"
    ></from-kml>
    <from-dwg
        (changeLoading)="changeLoading($event)"
        *ngIf="type === 'dwg'"
    ></from-dwg>
    <from-shape
        (changeLoading)="changeLoading($event)"
        *ngIf="type === 'shape'"
    ></from-shape>
    <from-wms
        (changeLoading)="changeLoading($event)"
        *ngIf="type === 'wms'"
    ></from-wms>
    <from-xlsx
        (changeLoading)="changeLoading($event)"
        *ngIf="type === 'xlsx'"
    ></from-xlsx>
</div>

<ngx-loading [show]="loading"></ngx-loading>
