<button
    mat-mini-fab
    color="secondary"
    type="button"
    class=""
    (click)="openStreetsmart()"
    matTooltip="Streetsmart"
    matTooltipPosition="left"
    [ngClass]="{ active: interactionService.cyclo.active }"
>
    <fa-icon [icon]="faStreetView"></fa-icon>
</button>
