<h1 mat-dialog-title>Bevestig verwijderen van {{ data.type }}</h1>

<mat-dialog-content>
    <span mat-description>
        Weet u zeker dat u de {{ data.type }} "{{ data.descriptor }}" wilt
        verwijderen?
    </span>

    <mat-dialog-actions class="d-flex justify-content-between">
        <button mat-raised-button mat-dialog-close (click)="onNoClick()">
            Nee
        </button>
        <button
            mat-raised-button
            [mat-dialog-close]="{
                delete: true,
                id: data?.id
            }"
            cdkFocusInitial
        >
            Ja
        </button>
    </mat-dialog-actions>
</mat-dialog-content>
