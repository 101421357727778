import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface MetadataDialogData {
    id: string;
    descriptor: string;
    metadata: {
        content: string;
    };
}

@Component({
    selector: 'metadata-dialog',
    templateUrl: 'metadata.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Dialog for the metadata
 */
export class MetadataDialogComponent {
    metadata: any;
    constructor(
        public dialogRef: MatDialogRef<MetadataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MetadataDialogData
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
