import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import WFS from 'ol/format/WFS';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is for making queries
 */
// @todo add geoserver authentication
export class QueryService {
    // The geometry property of the server, configured for mapserver by default
    configuration: any;

    fields: string[] = [];
    readonly geomTypes = ['msGeometry', 'geometry', 'geom'];

    constructor(
        private readonly http: HttpClient,
        private readonly snackBar: MatSnackBar
    ) {}

    queryWithFilter(featureTypes, filter: any, resource: any, serverType): any {
        const requestJson: any = {
            featureNS: '',
            featureTypes,
            maxFeatures: 1000,
            srsName: 'EPSG:28992',
            outputFormat: 'text/xml; subtype=gml/3.1.1',
            filter
        };

        // @TODO Make this work for MapServer: If the server is mapserver, use a prefix
        if (serverType === 'mapserver') {
            requestJson.featurePrefix = 'ms';
        }

        const featureRequest = new XMLSerializer().serializeToString(
            new WFS().writeGetFeature(requestJson)
        );

        const headers = new HttpHeaders();

        // Authenticate for geoserver

        // Get features through http request
        return this.http
            .post(resource, featureRequest, {
                headers: headers,
                responseType: 'text'
            })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getFeatureTypeDescription(resource: string, featureTypes: string[]): void {
        // Hardcode string replacement for common ground sources in the NL
        resource = resource.replace(/\/wms\//g, '/wfs/');

        // Hardcode string replacement for common ground sources in the NL
        resource = resource.replace(/\/wms\//g, '/wfs/');

        // Add params
        if (resource.includes('?')) {
            if (resource.endsWith('&')) {
                resource += 'service=WFS';
            } else {
                resource += '&service=WFS';
            }
        } else {
            resource += '?service=WFS';
        }
        
        resource = resource + '' + '&request=DescribeFeatureType';
        resource = resource + '' + '&version=2.0.0';

        this.fields = [];

        featureTypes.forEach(featureType => {
            resource = resource + '' + '&typeName=' + featureType;

            this.http
                .get(resource, {
                    responseType: 'text'
                })
                .subscribe(
                    (response: any) => {
                        const xmlParser = new DOMParser();
                        const xmlDOM = xmlParser.parseFromString(
                            response.toString(),
                            'application/xml'
                        );

                        const xmlNSResolver = xmlDOM.createNSResolver(
                            xmlDOM.documentElement
                        );

                        const featureTypeXPath = `//xsd:complexType[@name="${featureType}Type"]//xsd:element/@name`;
                        const xmlFeatureTypeDescription = xmlDOM.evaluate(
                            featureTypeXPath,
                            xmlDOM,
                            xmlNSResolver,
                            XPathResult.ANY_TYPE,
                            null
                        );

                        let field;
                        while (
                            (field = xmlFeatureTypeDescription.iterateNext())
                        ) {
                            if (!this.geomTypes.includes(field.nodeValue)) {
                                this.fields.push(field.nodeValue);
                            }
                        }
                    },
                    err => {
                        this.snackBar.open(
                            'Er was een probleem bij het ophalen van features',
                            'Sluiten',
                            {
                                panelClass: 'white-snackbar',
                                verticalPosition: 'top',
                                duration: 5000
                            }
                        );
                    }
                );
        });
    }
}