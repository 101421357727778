import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ConfigService } from 'app/_services';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { faSignOut, faUndo } from '@fortawesome/free-solid-svg-icons';
import {
    SwUpdate,
    VersionEvent,
    VersionReadyEvent
} from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'cook',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
    readonly faUndo = faUndo;
    readonly faSignOut = faSignOut;
    readonly env = environment;

    constructor(
        readonly configService: ConfigService,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly swUpdate: SwUpdate
    ) {
        this.updateApp();
        // Get the csrf token
        this.http.get(`${this.env.base_url}/sanctum/csrf-cookie`).subscribe();
    }

    leavePage(): void {
        this.authService.logout();
        document.getElementById('confirm-dialog').style.display = 'none';
    }

    stayOnPage(): void {
        this.router.navigate(['']);
        document.getElementById('confirm-dialog').style.display = 'none';
    }

    private updateApp(): void {
        if (this.swUpdate.isEnabled) {
            console.warn('Service worker enabled');
            this.swUpdate.versionUpdates
                .pipe(
                    filter(
                        (event: VersionEvent): event is VersionReadyEvent =>
                            event.type === 'VERSION_READY'
                    )
                )
                .subscribe(() => {
                    this.swUpdate.activateUpdate().then(() => {
                        console.warn('App updated');
                        document.location.reload();
                    });
                });
        } else {
            console.warn('Service worker disabled');
        }
    }
}
