<p class="title">{{ title }}</p>
<div class="content">
    <mat-slider
        min="0"
        max="1"
        step="0.01"
        discrete
        (input)="change($event)"
        [displayWith]="formatLabel"
    >
        <input matSliderThumb [value]="opacity" type="range" />
    </mat-slider>
    <button mat-raised-button (click)="snackBar.dismiss()" class="closeButton">
        Sluiten
    </button>
</div>
