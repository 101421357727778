import {
    Component,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
    EffectRef,
    effect
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    MapService,
    ConfigService,
    LayerService,
    InteractionService
} from 'app/_services';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { faPlus, faCopy } from '@fortawesome/free-solid-svg-icons';
import GeoJSON from 'ol/format/GeoJSON';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { Feature } from 'ol';

export class Message {
    message: string;
    feature: any;
    attachments: any;
    signature: string;
    image: string;
    type: string;
    subject: string;
    receivers: Array<string>;
    config_id: number;
    status: string;
    expired_at: any;
}

@Component({
    selector: 'message',
    templateUrl: 'message.component.html',
    styleUrls: ['message.component.scss']
})

export class MessageComponent implements OnInit, OnDestroy {
    readonly faPlus = faPlus;
    readonly faCopy = faCopy;

    message: Message;
    configSubscription: EffectRef;
    messageSent = false;

    copyGeom = false;
    copyInteraction: any;
    feature: Feature;
    expired_at: any;
    // @Input() feature: any;
    @Output() sent = new EventEmitter<boolean>();
    loading = false;

    uploadedFiles = [];
    props = {};

    config: any;
    config_id: any;

    constructor(
        private readonly http: HttpClient,
        private readonly mapService: MapService,
        private readonly layerService: LayerService,
        private readonly interactionService: InteractionService,
        private readonly configService: ConfigService
    ) {
        this.message = new Message();

        this.configSubscription = effect(
            () => {
                const config = this.configService.config();

                if (!config) return;

                this.config_id = config.id;
                this.config = config.tools.notifications;
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        if (this.copyInteraction) {
            this.mapService.map().un('click', this.copyInteraction);
        }
        this.layerService.notificationLayer().getSource().clear();
        this.interactionService.removeInteractions();
    }

    ngOnInit(): void {
        this.config = this.configService.config().tools.notifications;
        this.config_id = this.configService.config().id;
    }

    /**
     * Send the message to the authentication backend
     */
    sendMessage(): void {
        this.loading = true;
        // this.message.type = this.config.type;
        if (this.config.subject) {
            this.message.subject = this.config.subject;
        }

        this.message.config_id = this.config_id;

        if (this.expired_at) {
            this.message.expired_at = moment(this.expired_at).format(
                'YYYY-MM-DD'
            );
        }

        if (this.uploadedFiles) {
            this.message.attachments = this.uploadedFiles;
        }

        if (this.config.receiver) {
            this.message.receivers = this.config.receiver;
        }

        this.message.status = 'In behandeling';

        if (this.config.feature && this.feature) {
            this.message.feature = new GeoJSON().writeFeaturesObject(
                [this.feature],
                { featureProjection: this.mapService.projection }
            );
            const keys = this.feature.getProperties();
            for (const prop in keys) {
                if (this.props[prop]) {
                    this.message.message +=
                        ' \n' + prop + ': ' + this.props[prop];
                }
            }
        }

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            'application/json; charset=utf-8'
        );

        this.http
            .post(
                environment.api_base_url + '/notifications/notify',
                this.message,
                { headers, responseType: 'json' }
            )
            .subscribe(
                (res: any) => {
                    this.loading = false;
                    this.messageSent = true;

                    this.sent.emit(this.messageSent);
                },
                err => {
                    this.loading = false;
                }
            );
    }

    addToProps(evt, key, value): void {
        if (evt.checked) {
            this.props[key] = value;
        } else {
            delete this.props[key];
        }
    }

    onFileSelected(event): void {
        // foreach files
        const file: File = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            const upload$ = this.http.post(
                environment.api_base_url + '/upload',
                formData
            );

            upload$.subscribe(res => {
                this.uploadedFiles.push(res);
            });
        }
    }

    selectGeomFromMap(): void {
        this.copyGeom = !this.copyGeom;

        if (this.copyGeom) {
            this.interactionService.removeInteractions();

            this.copyInteraction = this.mapService.map().on('click', evt => {
                this.layerService.notificationLayer().getSource().clear();
                const f = this.mapService
                    .map()
                    .getFeaturesAtPixel(evt.pixel)[0];

                if (f) {
                    const feature = f as Feature; // Explicitly cast to ol.Feature
                    this.feature = feature.clone();

                    this.props = this.feature.getProperties();
                    delete this.props['boundedBy'];
                    delete this.props['geom'];

                    this.feature.setStyle(
                        new Style({
                            fill: new Fill({
                                color: this.layerService.styleColors.magenta
                                    .fill
                            }),
                            stroke: new Stroke({
                                color: this.layerService.styleColors.magenta
                                    .stroke,
                                width: 3
                            }),
                            image: new Circle({
                                radius: 7,
                                fill: new Fill({
                                    color: this.layerService.styleColors.magenta
                                        .fill
                                }),
                                stroke: new Stroke({
                                    color: this.layerService.styleColors.magenta
                                        .stroke,
                                    width: 3
                                })
                            })
                        })
                    );

                    this.layerService
                        .notificationLayer()
                        .getSource()
                        .addFeature(this.feature);
                }
            });
        } else {
            this.mapService.map().un('click', this.copyInteraction);
        }
    }
}
