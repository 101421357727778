<div
    [ngClass]="{
        hidden: hide_in_legend(),
        'form-check': name()
    }"
>
    <input
        type="checkbox"
        class="form-check-input"
        id="{{ 'layer' + layer.id }}"
        name="inlineRadioOptions"
        *ngIf="name()"
        [checked]="checked()"
        (change)="checkboxChanged($event)"
        (click)="toggleLayer()"
    />
    <label class="form-check-label" for="{{ 'layer' + layer.id }}">
        <svg
            *ngIf="layer.icon?.length > 0"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="20pt"
            height="18pt"
            [innerHTML]="icon()"
        ></svg>
        {{ label() }}
        <ng-content select="[slot=drag-handle]"></ng-content>
    </label>
    <a
        class="metadata"
        href="{{ metadata_url() ? metadata_url() : '' }}"
        target="_blank"
        matTooltip="Metadata"
        aria-label="Metadata"
        matTooltipPosition="below"
        *ngIf="metadata_url()"
    >
        <fa-icon [icon]="faInfoCircle"></fa-icon>
    </a>
    <a
        class="metadata"
        (click)="openMetadata()"
        matTooltip="Metadata"
        aria-label="Metadata"
        matTooltipPosition="below"
        target="_blank"
        *ngIf="metadata()"
    >
        <fa-icon [icon]="faInfoCircle"></fa-icon>
    </a>
</div>
