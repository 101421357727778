import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { LegendService, MapService } from 'app/_services';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Fill, Stroke, Style, Circle, Text } from 'ol/style';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'styling',
    templateUrl: 'styling.component.html',
    styleUrls: ['styling.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StylingComponent implements OnInit {
    readonly faXmark = faXmark;

    style: any;

    constructor(
        public mapService: MapService,
        public legendService: LegendService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        console.log(this.legendService.styleSettings.fillColor);
    }

    updateVectorStyle(): void {
        this.createStyle(this.legendService.styleSettings);

        const layer = this.legendService.findMap(
            this.legendService.styleMap().id
        );
        layer.setStyle(this.style);
    }

    updateWebGLStyle(): void {
        const style = {
            symbol: {
                symbolType: 'circle',
                size: [
                    'interpolate',
                    ['exponential', 2.5],
                    ['zoom'],
                    2,
                    1,
                    14,
                    32
                ],
                color:
                    this.legendService.styleSettings &&
                    this.legendService.styleSettings.color
                        ? this.legendService.styleSettings.color
                        : 'rgb(255, 172, 69)',
                offset: [0, 0],
                opacity: 0.95
            }
        };
        const layer = this.legendService.findMap(
            this.legendService.styleMap().id
        );

        const newLayer = new WebGLPointsLayer({
            source: layer.getSource(),
            style
        });
        newLayer.set('id', layer.get('id'));

        this.mapService.map().addLayer(newLayer);

        if (layer) {
            this.mapService.map().removeLayer(layer);
            layer.dispose();
        }
    }

    save(): void {
        const body = {
            styling: this.legendService.styleSettings,
            id: this.legendService.styleMap().id
        };
        this.http
            .put(`${environment.api_base_url}/maps/${body.id}`, body)
            .toPromise()
            .then(res => {
                this.cdr.detectChanges();
            })
            .catch(err => {
                this.snackBar.open(
                    'Er was een probleem bij het opslaan',
                    'Ok',
                    {
                        panelClass: 'white-snackbar',
                        verticalPosition: 'top',
                        duration: 3000
                    }
                );
            });

        this.legendService.styleMap.set(undefined);
    }

    reset(): void {
        // Reset the style to the old if it is not saved
        this.createStyle(this.legendService.styleMap().styles);
        const layer = this.legendService.findMap(
            this.legendService.styleMap().id
        );
        layer.setStyle(this.style);
    }

    toLegend(): void {
        this.legendService.styleMap.set(undefined);
    }

    createStyle(styleSettings): any {
        // If it is undefined make it a empty object
        if (!styleSettings) {
            styleSettings = {};
        }
        this.style = (feature, resolution) => {
            let featureText = f => '';

            if (styleSettings.labelKey) {
                featureText = f => {
                    const string = f.get(styleSettings.labelKey)
                        ? f.get(styleSettings.labelKey)
                        : '';
                    if (typeof string === 'string') {
                        return string;
                    }
                    return '';
                };
            }
            if (
                styleSettings.labelMaxRes &&
                styleSettings.labelMaxRes < resolution
            ) {
                featureText = f => '';
            }

            return new Style({
                image: new Circle({
                    fill: new Fill({
                        color: styleSettings.fillColor
                            ? styleSettings.fillColor
                            : 'rgba(244, 148, 65, 0.3)'
                    }),
                    stroke: new Stroke({
                        color: styleSettings.color
                            ? styleSettings.color
                            : '#f49441',
                        width: styleSettings.lineWidth
                            ? styleSettings.lineWidth
                            : 3
                    }),
                    radius: 5
                }),
                fill: new Fill({
                    color: styleSettings.fillColor
                        ? styleSettings.fillColor
                        : 'rgba(244, 148, 65, 0.3)'
                }),
                stroke: new Stroke({
                    color: styleSettings.color
                        ? styleSettings.color
                        : '#f49441',
                    width: styleSettings.lineWidth ? styleSettings.lineWidth : 3
                }),
                text: new Text({
                    text: featureText(feature),
                    textAlign: 'center',
                    offsetY: 40,
                    scale: 1.4,
                    stroke: new Stroke({
                        color: '#ffffff',
                        width: 3
                    }),
                    fill: new Fill({
                        // @shouldRemove
                        // color: this.styleColors[color]
                    })
                })
            });
        };
    }
}
