import { Component, EffectRef, OnDestroy, effect, signal } from '@angular/core';
import {
    MapService,
    FeatureService,
    DrawService,
    InteractionService,
    ConfigService
} from 'app/_services';
import { faMousePointer } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'info',
    templateUrl: 'info.component.html',
    styleUrls: ['info.component.scss']
})
/**
 * This tool is for clicking on objects in the map and showing information about the object
 * On the right of the Map
 */
export class InfoComponent implements OnDestroy {
    faMousePointer = faMousePointer;
    action = 'featureinfo';
    readonly config = signal(undefined);
    configSubscription: EffectRef;
    message: string;

    constructor(
        public mapService: MapService,
        protected featureService: FeatureService,
        protected drawService: DrawService,
        public interactionService: InteractionService,
        protected configService: ConfigService
    ) {
        this.configSubscription = effect(
            () => {
                if (this.configService.config()) {
                    this.config.set(this.configService.config());
                    this.interactionService.removeInteractions();
                    this.selectFeatureInfo();
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        this.interactionService.removeInteractions();
        this.configSubscription.destroy();
    }

    selectFeatureInfo(): void {
        if (this.interactionService.info.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.info.active =
                !this.interactionService.info.active;
        }

        // If the map is turned on set the items
        if (
            !this.interactionService.info.interaction &&
            this.interactionService.info.active
        ) {
            // Set the config to the featureService
            if (this.config().tools.info) {
                this.featureService.configuration.set(this.config().tools.info);
            }

            if (this.mapService.map()) {
                this.interactionService.info.interaction = this.mapService
                    .map()
                    .on('singleclick', e => {
                        this.featureService.deselectFeatures();
                        this.featureService.queryServers(e.coordinate, 'grid');
                    });
            }
        }
    }

    disableSelectFeatureInfo(): void {
        // Remove any previous interactions
        this.interactionService.removeInteractions();
    }
}
