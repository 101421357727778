<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    matTooltip="Zoom naar coordinaat"
    matTooltipPosition="left"
    (click)="openDialog()"
>
    <fa-icon [icon]="faSearchLocation"></fa-icon>
</button>
