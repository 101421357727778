<div>
    <h4>Gedeelde configuraties</h4>

    <mat-list *ngIf="sharedConfigs().length; else noSharedConfigs">
        <mat-list-item *ngFor="let sharedConfig of sharedConfigs()">
            <button mat-button (click)="loadConfig(sharedConfig)">
                {{ sharedConfig.id }}
            </button>
            <mat-divider></mat-divider>
        </mat-list-item>
    </mat-list>

    <ng-template #noSharedConfigs>
        U heeft nog geen opgeslagen locaties. Om er een toe te voegen, navigeer
        naar de gewenste locatie en druk op de tool met het volgende icoontje
        Geef het een naam en dan kun je de locatie hier terug vinden.
    </ng-template>
</div>
