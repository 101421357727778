<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="drawFeatureInfo()"
    matTooltip="Informatie Cirkel"
    aria-label="Informatie Cirkel"
    matTooltipPosition="left"
    [class.active]="interactionService.circleinfo.active"
>
    <fa-icon [icon]="faDrawCircle"></fa-icon>
</button>
<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="deselect()"
    matTooltip="Deselecteer alles"
    aria-label="Deselecteer alles"
    matTooltipPosition="left"
>
    <fa-icon [icon]="faTimes"></fa-icon>
</button>
