<ng-container
    *ngIf="configService.config().options.mapHistoryButtons !== false"
>
    <button
        *ngIf="configService.config().options.mapFullViewButton !== false"
        mat-mini-fab
        color="secondary"
        class="tool"
        (click)="lastExtent()"
        matTooltip="Vorig Kaartbeeld"
        aria-label="Vorig Kaartbeeld"
        matTooltipPosition="left"
        [disabled]="historyService.historyIndex() <= 1"
    >
        <fa-icon [icon]="faArrowLeft"></fa-icon>
    </button>

    <button
        mat-mini-fab
        color="secondary"
        class="tool"
        (click)="nextExtent()"
        matTooltip="Volgend Kaartbeeld"
        aria-label="Volgend Kaartbeeld"
        matTooltipPosition="left"
        [disabled]="
            historyService.historyIndex() >= historyService.history().length
        "
    >
        <fa-icon [icon]="faArrowRight"></fa-icon>
    </button>
</ng-container>

<button
    *ngIf="configService.config().options.mapFullViewButton !== false"
    mat-mini-fab
    color="secondary"
    class="tool"
    (click)="zoomExtent()"
    matTooltip="Volledig Kaartbeeld"
    aria-label="Volledig Kaartbeeld"
    matTooltipPosition="left"
>
    <fa-icon [icon]="faExpandArrowsAlt"></fa-icon>
</button>
