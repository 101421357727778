import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit
} from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule
} from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { jsPDF } from 'jspdf';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

export interface ExportDialogData {
    title: string;
    message: string;
    image: string;
    type: string;
    width: number;
    height: number;
    layout: string;
    size: 'portrait' | 'landscape';
}

@Component({
    selector: 'export-print-dialog',
    templateUrl: './export-print.dialog.html',
    styleUrls: ['./export-print.dialog.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        CommonModule,
        FontAwesomeModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportPrintDialogComponent implements OnInit {
    readonly faDownload = faDownload;
    readonly faPrint = faPrint;
    readonly faFilePdf = faFilePdf;

    printName: string;
    imageToShow: SafeHtml;

    constructor(
        public dialogRef: MatDialogRef<ExportPrintDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ExportDialogData
    ) {}

    ngOnInit(): void {
        const currentDate = new Date();
        this.printName = `${
            this.data.title || 'Cook-Print'
        }-${currentDate.getDate()}/${
            currentDate.getMonth() + 1
        }/${currentDate.getFullYear()}`;

        this.imageToShow = this.data.image;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    downloadPrint(): void {
        FileSaver.saveAs(this.data.image, `${this.printName}.jpg`);
    }

    downloadPrintAsPdf(): void {
        const pdf = new jsPDF(this.data.size, undefined, this.data.layout);

        pdf.addImage(
            this.data.image,
            'JPEG',
            0,
            0,
            this.data.width,
            this.data.height
        );
        pdf.save(`${this.printName}.pdf`);
    }

    print(): void {
        const win = window.open('about:blank', '_new');
        win.document.open();
        win.document.write(`
            <html>
            <head></head>
            <body onload="window.print()" onafterprint="window.close()">
                <img src="${this.imageToShow}" />
            </body>
            </html>
        `);
        win.document.close();
    }
}
