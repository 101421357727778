import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EffectRef,
    Input,
    OnDestroy,
    QueryList,
    ViewEncapsulation,
    effect,
    signal
} from '@angular/core';
import {
    ConfigService,
    LegendService,
    MapService,
    AuthService,
    SidenavService
} from 'app/_services';
import { LegendMapComponent } from './map/map.component';
import { LegendMapGroupComponent } from './mapgroup/mapgroup.component';
import { MapCreatorDialogComponent } from '../map-creator/map-creator.component';
import { environment } from 'environments/environment';
import {
    faAngleRight,
    faAngleDown,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'legend',
    templateUrl: 'legend.component.html',
    styleUrls: ['legend.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * There are two kinds of layers in OpenLayers and the LayerSwitcherComponent
 * The LayerGroup, which is a group of paramLayers that are contained within one actual Layer. Using the updateLayer
 * function we can change these so the paramLayers in the group are toggled on or off. These paramLayers are toggled
 * on or off by adding/removing them from the layer itself.
 */
export class LegendComponent implements OnDestroy {
    // Font awesome
    readonly faSave = faSave;
    readonly faAngleDown = faAngleDown;
    readonly faAngleRight = faAngleRight;

    readonly maps = signal([]);
    readonly environment = environment;
    private readonly configSubscription: EffectRef;
    private readonly zoomLevelSubscription: EffectRef;

    readonly manage = signal(true);

    @Input() readonly layer;

    @ContentChildren(LegendMapComponent, { descendants: true })
    readonly mapChildren: QueryList<LegendMapComponent>;

    @ContentChildren(LegendMapGroupComponent, { descendants: true })
    readonly mapGroupChildren: QueryList<LegendMapGroupComponent>;

    constructor(
        readonly authService: AuthService,
        readonly legendService: LegendService,
        private sidenavService: SidenavService,
        readonly configService: ConfigService,
        private readonly dialog: MatDialog,
        private readonly mapService: MapService
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();
                this.legendService.customMap = {};
                this.legendService.customMapGroup = {};
                this.legendService.styleMap.set(undefined);
                this.legendService.dynamicLegend.set(false);

                if (!config) return;

                this.manage.set(!!config?.options?.mapManageOpen);

                if (config?.maps) {
                    config.maps.sort((n1, n2) => n1.sort - n2.sort);
                    config.maps.forEach(map => {
                        if (map.source && map.source.layers) {
                            map.source.layers.sort(
                                (l1, l2) => l1.sort - l2.sort
                            );
                        }
                    });

                    this.maps.set(config.maps);
                }

                this.legendService.dynamicLegend.set(
                    !!config.options.dynLegendOpen
                );
            },
            { allowSignalWrites: true }
        );

        this.zoomLevelSubscription = effect(
            () => {
                const legendIsOpen = this.legendService.dynamicLegend();

                if (!legendIsOpen) {
                    return;
                }

                const center = this.mapService.center();

                if (center) {
                    this.legendService.createDynamicLegend();
                }
            },
            { allowSignalWrites: true }
        );

        this.sidenavService.setWidth(380, 'px');
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        this.zoomLevelSubscription.destroy();
    }

    setLegendMaps(config): void {
        this.manage.set(config.options.mapManageOpen);
    }

    saveLegend(): void {
        this.legendService.saveLegendSettings();
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.maps(), event.previousIndex, event.currentIndex);

        this.maps().forEach((m, i) => {
            if (m.sort !== i) {
                m.sort = i;
                if (m.maps) {
                    this.legendService.customMapGroup[m.name] = m;
                } else {
                    this.legendService.customMap[m.name] = m;
                }
            }
        });
    }

    dynLegendClick(): void {
        this.legendService.dynamicLegend.set(
            !this.legendService.dynamicLegend()
        );

        if (this.legendService.dynamicLegend()) {
            this.legendService.createDynamicLegend();
        }
    }

    openMapCreator(): void {
        this.dialog.open(MapCreatorDialogComponent, {
            width: '65vw',
            height: '75vh'
        });
    }

    // TODO doesnt work with sorting
    // trackByMap(item): number {
    //     return item.id;
    // }

    trackByIcon(icon: any): number {
        return icon.changingThisBreaksApplicationSecurity;
    }
}
