import { Injectable } from '@angular/core';
import { XmlToJsonAdapterService } from './XMLToJsonAdapter.service';

type Layer = {
    name: string;
    label: string;
    visible: boolean;
    layers?: Layer[];
};
type Map = {
    name: string;
    tooltip: string;
    type: string;
    collapsed: boolean;
    serverType?: string;
    visible: boolean;
    order: number;
    hide: boolean;
    source: {
        type: string;
        url: string;
    };
    layers: Layer[];
};

@Injectable({
    providedIn: 'root'
})
export class GetCapabilitiesService {
    private readonly parser = new DOMParser();

    constructor(private readonly xmlToJsonAdapter: XmlToJsonAdapterService) {}

    handle(xml: any, serverType: string) {
        const xmlDoc = this.parser.parseFromString(xml, 'text/xml');
        const json = this.xmlToJsonAdapter.transform(xmlDoc);

        return this.convertJSONToGroupsAndLayers(json, serverType);
    }

    private convertJSONToGroupsAndLayers(json: any, serverType: string) {
        console.log(json);
        const layers: Layer[] = [];

        // json.contents is for WMTS
        const sourceMap = json?.Capability?.Layer ?? json?.Contents;
        let layerArray = [];

        if (Array.isArray(sourceMap?.Layer)) {
            layerArray = sourceMap?.Layer;
        } else if (!Array.isArray(sourceMap?.Layer)) {
            layerArray = [sourceMap?.Layer];
        } else {
            console.log('Er is geen layer array gevonden');
        }

        layerArray.forEach((l: any) => {
            const layersInLayer: Layer[] = [];
            const name = l?.Name ?? l.Title;
            const cleanName = this.toCleanName(name);

            if (Array.isArray(l?.Layer)) {
                l?.Layer.forEach((l: any) => {
                    const name = l?.Name ?? l.Title;
                    const cleanName = this.toCleanName(name);

                    layersInLayer.push({
                        name: name,
                        label: cleanName,
                        visible: true
                    });
                });
            } else if (l) {
                layersInLayer.push({
                    name: name,
                    label: cleanName,
                    visible: true
                });
            }

            layers.push({
                name: name,
                label: cleanName,
                visible: true,
                layers: layersInLayer
            });
        });

        const mapName = this.getMapTitle(json);

        const map: Map = {
            name: this.toCleanName(mapName),
            tooltip:
                typeof sourceMap?.Abstract === 'string'
                    ? sourceMap.Abstract
                    : '',
            serverType: serverType,
            type: 'Image',
            collapsed: true,
            visible: true,
            order: 15,
            hide: false,
            source: {
                type: json?.Service?.Name ?? '',
                url:
                    json?.Capability?.Request?.GetCapabilities?.DCPType?.HTTP
                        ?.Get?.OnlineResource?.['xlink:href'] ?? ''
            },
            layers: layers
        };

        return {
            succes: map
        };
    }

    private toCleanName(name: string) {
        const cleanName = name.replace(/_/g, ' ');
        const firstLetter = cleanName.charAt(0).toUpperCase();
        const rest = cleanName.slice(1).toLowerCase();
        return firstLetter + rest;
    }

    private getMapTitle(json: any) {
        // Attempt to retrieve the title from ServiceIdentification or Service
        let title = json?.ServiceIdentification?.Title;

        if (typeof title === 'string') {
            return title;
        }

        title = json?.Service?.Title;

        if (typeof title === 'string') {
            return title;
        }

        // Default title if none is found or if the found title is an object
        return 'Geen titel gevonden';
    }
}
