import { Component, OnInit, OnDestroy, EffectRef, effect } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormArray
} from '@angular/forms';
import {
    QueryService,
    GridService,
    InteractionService,
    MapService,
    LayerService,
    ConfigService,
    LegendService,
    SidenavService
} from 'app/_services';
import {
    faTimes,
    faPlus,
    faVectorSquare
} from '@fortawesome/free-solid-svg-icons';
import { UserManualDialogComponent } from '../_dialogs/user-manual/user-manual.dialog';
import { MatDialog } from '@angular/material/dialog';
import Draw from 'ol/interaction/Draw.js';
import * as Filter from 'ol/format/filter';
import WFS from 'ol/format/WFS';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import {fromExtent} from 'ol/geom/Polygon';

@Component({
    selector: 'query',
    templateUrl: './query.component.html',
    styleUrls: ['./query.component.scss']
})
/**
 * @shouldRemove add description
 * With this component you can perform queries on the data of the map
 */
export class QueryComponent implements OnInit, OnDestroy {
    readonly faTimes = faTimes;
    readonly faInfo = faInfo;
    readonly faPlus = faPlus;
    faVectorSquare = faVectorSquare;
    configSubscription: EffectRef;

    queryFormGroup: FormGroup;

    mapLayers: Array<any> = [];
    selectedMap: any;
    featureType: string;

    clicks = [];

    matchOptions: any[] = [
        {
            name: 'And',
            filter: 'and'
        },
        // @shouldRemove
        // {
        //     name: 'Not',
        //     filter: 'not'
        // },
        {
            name: 'Or',
            filter: 'or'
        }
    ];

    operatorOptions: any[] = [
        {
            name: '=',
            filter: 'equalTo'
        },
        {
            name: '<>',
            filter: 'notEqualTo'
        },
        {
            name: '<',
            filter: 'lessThan'
        },
        {
            name: '>',
            filter: 'greaterThan'
        },
        {
            name: '<=',
            filter: 'lessThanOrEqualTo'
        },
        {
            name: '>=',
            filter: 'greaterThanOrEqualTo'
        },
        {
            name: 'like',
            filter: 'like'
        }
        // @shouldRemove
        // idk what this does
        // {
        //     name: 'between',
        //     filter: 'between'
        // }
    ];

    geometry: any;
    config: any;

    constructor(
        public interactionService: InteractionService,
        private readonly formBuilder: FormBuilder,
        private readonly gridService: GridService,
        private readonly mapService: MapService,
        private readonly layerService: LayerService,
        private readonly configService: ConfigService,
        private readonly legendService: LegendService,
        public readonly queryService: QueryService,
        public readonly dialog: MatDialog,
        public readonly snackBar: MatSnackBar,
        private readonly sideNavService: SidenavService
    ) {
        this.configSubscription = effect(
            () => {
                if (!this.config) {
                    this.createForm();

                    this.mapLayers = [];
                    const config = this.configService.config();
                    this.config = config.tools.query;
                    this.setLayers();
                }
            },
            { allowSignalWrites: true }
        );

        this.sideNavService.setWidth(1000, 'px');
    }

    ngOnInit(): void {
        if (!this.config) {
            this.createForm();

            this.config = this.configService.config().tools.query;

            this.setLayers();
        }
    }

    setLayers(): void {
        this.configService.config().maps.forEach(layer => {
            // Find the maps with the id from the config
            if (this.config.maps.find(id => id === layer.id)) {
                // Dubbel check its a layer we can perform a query on
                if (layer.source.url) this.mapLayers.push(layer);
            }
        });

        if (this.mapLayers.length === 1) {
            this.queryFormGroup.get('selectedMap').setValue(this.mapLayers[0]);
            this.getFeatureTypes(this.mapLayers[0].source.layers[0].name);
        }

        console.log(this.mapLayers)
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        this.config = undefined;
        if (this.layerService.outlineLayer()) {
            this.layerService.outlineLayer().getSource().clear();
        }
        if (this.layerService.overviewSelectedLayer()) {
            this.layerService.overviewSelectedLayer().getSource().clear();
        }
    }

    createForm(): void {
        this.queryFormGroup = this.formBuilder.group({
            selectedMap: '',
            conditions: this.formBuilder.array([this .createCondition()])
        });
    }

    createCondition(): FormGroup {
        return this.formBuilder.group({
            property: '',
            match: 'and',
            operator: 'equalTo',
            expression: ''
        });
    }

    addCondition(): void {
        let conditions = this.queryFormGroup.get(
            'conditions'
        ) as FormArray;
        conditions.push(this.createCondition());
    }

    getFeatureTypes(featureType: any): void {
        this.featureType = featureType;
        this.queryService.getFeatureTypeDescription(
            this.queryFormGroup.get('selectedMap').value.source.url,
            [featureType]
        );
    }

    /**
     * Remove a condition
     * @param conditionNumber The 'i', number of the conditition to remove in the array
     */
    removeCondition(conditionNumber: number): void {
        let conditions = this.queryFormGroup.get(
            'conditions'
        ) as FormArray;

        conditions.removeAt(conditionNumber);
    }

    selectGeometry(): void {
        if (this.interactionService.queryPoly.active) {
            this.interactionService.removeInteractions();
            this.layerService.outlineLayer().getSource().clear();
            this.geometry = undefined;
        } else {
            // Remove any previous interactions
            this.interactionService.removeInteractions();
            this.interactionService.queryPoly.active =
                !this.interactionService.queryPoly.active;
            this.geometry = undefined;
        }

        if (this.interactionService.queryPoly.active) {
            // Start drawing a polygon
            const polygon: any = 'Polygon';
            this.interactionService.queryPoly.interaction = new Draw({
                type: polygon,
                source: this.layerService.outlineLayer().getSource()
            });

            this.mapService
                .map()
                .addInteraction(this.interactionService.queryPoly.interaction);

            this.interactionService.queryPoly.interaction.on(
                'drawstart',
                event => {
                    this.layerService.outlineLayer().getSource().clear();
                }
            );

            this.interactionService.queryPoly.interaction.on(
                'drawend',
                event => {
                    this.geometry = event.feature.getGeometry();
                }
            );
        }
    }

    /**
     * Runs the query filled out in the form, delegates the query to the QueryService
     */
    runQuery(): void {
        this.clicks = [];

        // Clear featurelayer
        this.layerService.overviewSelectedLayer().getSource().clear();

        // Build the filters
        let filter: any;
        const and: Array<any> = [];
        // const not: Array<any> = [];
        const or: Array<any> = [];
        let queryFilters: any;

        this.queryFormGroup
            .get('conditions')
            ['controls'].forEach((condition, index) => {
                const conditionValues = condition.getRawValue();

                // Filter the first one because it doesn't have a match
                if (index === 0) {
                    filter = Filter[conditionValues.operator](
                        conditionValues.property,
                        conditionValues.expression
                    );

                    return;
                }

                switch (conditionValues.match) {
                    case 'and':
                        if (index == 1) {
                            and.push(filter);
                        }
                        and.push(
                            Filter[conditionValues.operator](
                                conditionValues.property,
                                conditionValues.expression
                            )
                        );
                        break;
                    // case 'not':
                    //     not.push(Filter[conditionValues.operator](conditionValues.property, conditionValues.expression));
                    //     break;
                    case 'or':
                        if (index == 1) {
                            or.push(filter);
                        }
                        or.push(
                            Filter[conditionValues.operator](
                                conditionValues.property,
                                conditionValues.expression
                            )
                        );
                        break;
                    default:
                        break;
                }
            });

        const f: Array<any> = [];
        // If there are multiple querys we need to group them
        if (and.length > 1 && or.length > 1) {
            f.push(Filter.and.apply(this, and));
            f.push(Filter.or.apply(this, or));

            queryFilters = Filter.and.apply(this, f);
        } else if (and.length == 1 && or.length == 1) {
            f.push(and[0]);
            f.push(or[0]);

            queryFilters = Filter.and.apply(this, f);
        } else if (and.length == 1 && or.length > 1) {
            // Use the same filter twice because otherwise we can't group them
            f.push(Filter.and.apply(this, [and[0], and[0]]));
            f.push(Filter.or.apply(this, or));

            queryFilters = Filter.and.apply(this, f);
        } else if (and.length > 1 && or.length == 1) {
            f.push(Filter.and.apply(this, and));
            // Use the same filter twice because otherwise we can't group them
            f.push(Filter.and.apply(this, [or[0], or[0]]));

            queryFilters = Filter.and.apply(this, f);
        } else if (and.length > 1) {
            queryFilters = Filter.and.apply(this, and);
        } else if (or.length > 1) {
            queryFilters = Filter.or.apply(this, or);
        } else if (and.length == 1) {
            f.push(and[0]);

            queryFilters = Filter.and.apply(this, f);
        } else if (or.length == 1) {
            f.push(or[0]);

            queryFilters = Filter.or.apply(this, f);
        } else {
            // There is only one filter
            queryFilters = filter;
        }

        // Add a geometry filter if we have that selected
        if (this.geometry) {
            queryFilters = Filter.and.apply(this, [
                queryFilters,
                Filter['intersects']('geom', this.geometry, 'EPSG:28992')
            ]);
        } else {
            const extent = this.mapService.map().getView().calculateExtent(this.mapService.map().getSize());
            const geom  = fromExtent(extent);

            queryFilters = Filter.and.apply(this, [
                queryFilters,
                Filter['intersects']('geom', geom, 'EPSG:28992')
            ]);
        }

        // Now query that WFS
        this.queryService
            .queryWithFilter(
                [this.featureType],
                queryFilters,
                this.queryFormGroup.get('selectedMap').value.source.url,
                'mapserver'
            )
            .subscribe(response => {
                const parser: any = new WFS();
                const features: any = parser.readFeatures(response);
                console.log(features);

                let isLimited = false;
                const highlightFeatures: Array<any> = [];
                let sortedFeatures = [];

                for (const f of features) {
                    const featureId = String(f.getId());
                    let featureType = featureId.includes('.')
                        ? featureId.split('.')[0]
                        : featureId.split('_')[0];

                    let existingFeature = sortedFeatures.find(
                        obj => obj.title === featureType
                    );

                    if (!existingFeature) {
                        existingFeature = { title: featureType, features: [] };

                        sortedFeatures = [...sortedFeatures, existingFeature];
                    }

                    if (
                        !isLimited &&
                        (!existingFeature.features ||
                            existingFeature.features.length <= 500)
                    ) {
                        existingFeature.features.push(f);

                        highlightFeatures.push(f);

                        if (existingFeature.features.length > 500) {
                            isLimited = true;
                        }
                    }
                }

                if (sortedFeatures.length < 1) {
                    this.snackBar.open('Geen resultaten', 'Sluiten', {
                        panelClass: 'white-snackbar',
                        verticalPosition: 'top',
                        duration: 5000
                    });
                    return;
                }

                if (isLimited) {
                    this.snackBar.open(
                        'Query is gelimiteerd tot 500 Resultaten',
                        'Sluiten',
                        {
                            panelClass: 'white-snackbar',
                            verticalPosition: 'top',
                            duration: 10000
                        }
                    );
                }

                // console.log(featureTypeArray, highlightFeatures)
                this.layerService
                    .overviewSelectedLayer()
                    .getSource()
                    .addFeatures(highlightFeatures);


                let collection = {
                    layers: sortedFeatures,
                    source: this.legendService.findMap(this.queryFormGroup.get('selectedMap').value.id),
                    id: this.queryFormGroup.get('selectedMap').value.id
                };

                this.clicks.push(collection);

                console.log(this.clicks)
                this.gridService.features.set(this.clicks);
                this.gridService.featuresChange.next(
                    this.gridService.features()
                );
            });
    }

    /**
     * This function is used for the Angular Material autocomplete to
     * display a json object in the input field
     */
    displayFn(value): string {
        if (value) {
            return value.name;
        }

        return '';
    }

    openManual(): void {
        this.dialog.open(UserManualDialogComponent, {
            width: '65vw',
            height: '75vh'
        });
    }
}
