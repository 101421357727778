<div class="p-3" *ngIf="data.type === 'cubejs'">
    <gridster [options]="options" style="background-color: white">
        <gridster-item *ngFor="let chart of data.charts" [item]="chart.layout">
            <div class="header">
                <h3>{{ chart.name }}</h3>
            </div>

            <chart-renderer
                [query]="chart.options.query"
                [chartType]="chart.options.chartType"
                [config]="chart.options.pivotConfig"
            >
            </chart-renderer>
        </gridster-item>
    </gridster>
</div>
<div class="p-3 h-100" *ngIf="data.type === 'powerbi'">
    <powerbi-report
        [embedConfig]="reportConfig"
        cssClassName="powerbi-report-container"
        phasedEmbedding="false"
    >
        <div style="height: 100%"></div>
    </powerbi-report>
</div>
<div class="p-3 h-100" *ngIf="data.type === 'fme'">
    <iframe
        class="powerbi-report-container"
        [src]="url"
        frameborder="0"
    ></iframe>
</div>
