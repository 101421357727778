<ng-container>
    <div class="card-text">
        <div
            *ngIf="ready === false"
            class="d-flex justify-content-center text-dark"
        >
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <canvas
            *ngIf="ready && showChart && chartType !== 'table'"
            baseChart
            [datasets]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [type]="chartType"
        >
        </canvas>
        <table
            *ngIf="chartType === 'table'"
            mat-table
            class="mat-table"
            [dataSource]="chartData"
        >
            <ng-container
                *ngFor="let column of displayedColumns; let index = index"
                [matColumnDef]="column"
            >
                <th mat-header-cell *matHeaderCellDef>
                    {{ columnTitles[index] }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element[column] }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <h1 *ngIf="ready && !showChart">{{ chartData }}</h1>
    </div>
</ng-container>
