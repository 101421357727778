<!-- custom dialog for mobile devices when used backbutton -->
<div id="confirm-dialog">
    <div class="confirm">
        <h2>Pas op je staat op het punt om uit te loggen.</h2>
        <br />
        <p>Weet je zeker dat je wilt uitloggen?</p>
        <button type="button" name="button" (click)="leavePage()">
            <fa-icon [icon]="faSignOut"></fa-icon> Uitloggen
        </button>
        <button type="button" name="button" (click)="stayOnPage()">
            <fa-icon [icon]="faUndo"></fa-icon> Blijf op huidige pagina
        </button>
    </div>
</div>
<header></header>
<router-outlet></router-outlet>

<!-- Cookie banner -->
<div
    id="cookie-banner"
    class="position-fixed bottom-0 w-100 rounded-0 alert alert-dark text-center mb-0"
    role="alert"
>
    Deze website maakt gebruik van cookies om jou ervaring te verbeteren.
    <a
        href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies"
        target="blank"
        >Lees meer</a
    >
    <button
        type="button"
        class="btn btn-primary btn-sm ms-3"
        (click)="configService.hideCookieBanner()"
    >
        Accepteren
    </button>
</div>
