<h3 mat-dialog-title>Exporteren als</h3>
<div mat-dialog-content>
    <div class="exportPrint">
        <mat-form-field>
            <input
                matInput
                type="text"
                placeholder="Naam bestand"
                [(ngModel)]="printName"
                [ngModelOptions]="{ standalone: true }"
            />
        </mat-form-field>
        <div class="preview">
            <img class="printImage" [src]="imageToShow" alt="printImage" />
        </div>
    </div>
</div>

<div mat-dialog-actions class="row justify-content-center m-2">
    <button class="col px-5" mat-raised-button (click)="downloadPrint()">
        <fa-icon [icon]="faDownload"></fa-icon>Afbeelding
    </button>
    <button
        class="col px-5 ms-2"
        mat-raised-button
        (click)="downloadPrintAsPdf()"
    >
        <fa-icon [icon]="faDownload"></fa-icon>PDF
    </button>
</div>
