import { Component, EventEmitter, Output } from '@angular/core';
import { ConfigService } from '../../_services';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MapCreatorDialogComponent } from '../map-creator.component';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { KML } from 'ol/format';
import GeoJSON from 'ol/format/GeoJSON';

@Component({
    selector: 'from-kml',
    templateUrl: './from-kml.component.html',
    styleUrls: ['./from-kml.component.scss']
})
export class MapFromKmlFileComponent {
    @Output() changeLoading = new EventEmitter<boolean>();

    environment: any;
    kmlFeatures: any;
    records: any;

    step = 0;

    geoJson: any;

    name: FormControl;
    resourceFormGroup: FormGroup;

    error: string;

    constructor(
        public configService: ConfigService,
        private http: HttpClient,
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MapCreatorDialogComponent>
    ) {
        this.environment = environment;
        this.name = new FormControl('', Validators.required);

        this.resourceFormGroup = this.formBuilder.group({
            name: this.name
        });
    }

    uploadListener($event: any): void {
        const files = $event.srcElement.files;

        this.handleFileUpload(files[0]);
    }

    handleFileUpload(file) {
        if (this.isValidKmlFile(file)) {
            const reader = new FileReader();
            reader.readAsText(file);

            reader.onload = () => {
                this.kmlFeatures = new KML().readFeatures(reader.result);

                this.geoJson = new GeoJSON().writeFeaturesObject(
                    this.kmlFeatures
                );
                console.log(this.geoJson);
                // And go to the next step
                this.step = 1;
            };

            reader.onerror = function () {
                console.log('error is occured while reading file!');
            };
        } else {
            alert('Please import valid .kml file.');
            this.fileReset();
        }
    }

    onSubmit(): void {
        this.changeLoading.emit(true);

        // Required map attributes
        const body = this.resourceFormGroup.getRawValue();
        if (this.kmlFeatures.length > 1000) {
            body.type = 'WebGLPoints';
        } else {
            body.type = 'Vector';
        }
        body.collapsed = true;
        body.visible = true;
        body.order = 50;
        body.hide = false;
        body.click = 'featureInMap';
        body.grid = {};
        body.source = {
            type: 'Vector',
            geojson: this.geoJson
        };

        this.http
            .post(`${environment.api_base_url}/maps`, body)
            .toPromise()
            .then((res: any) => {
                this.changeLoading.emit(false);

                this.bindToConfig(res.id);
            })
            .catch(err => {
                console.error(err);
                this.changeLoading.emit(false);
            });
    }

    isValidKmlFile(file: File): boolean {
        return file.name.endsWith('.kml');
    }

    fileReset(): void {
        this.records = [];
    }

    bindToConfig(map_id): void {
        const body = {
            map_id,
            config_id: this.configService.config().id
        };
        this.http
            .post(`${environment.api_base_url}/maps/map-to-config`, body)
            .toPromise()
            .then((res: any) => {
                console.log(res);
                this.dialogRef.close();
                this.configService.loadConfiguration();
            })
            .catch(err => {
                console.error(err);
            });
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer?.files;
        console.log(files)
        if (files && files.length) {
            if (files[0].name.slice(-3) === 'kml') {
                this.handleFileUpload(files[0]);
            }
        }
    }
}
