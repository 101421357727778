<div class="container">
    <div class="card card-container">
        <h2>Wachtwoord aanpassen</h2>
        <p>
            Je wachtwoord is verlopen of je logt voor de eerste keer in, maak
            een nieuw wachtwoord aan dat niet eerder is gebruikt.
        </p>
        <p *ngIf="error" class="alert alert-danger">
            {{ error }}
        </p>
        <form
            class="form-signin"
            [formGroup]="resetForm"
            (ngSubmit)="updatePassword()"
        >
            <div class="input-group">
                <input
                    class="form-control"
                    [ngClass]="{
                        error:
                            f.password.errors &&
                            (f.password.dirty || f.password.touched)
                    }"
                    placeholder="Nieuw wachtwoord"
                    [type]="hide1 ? 'password' : 'text'"
                    formControlName="password"
                />
                <div class="input-group-append" (click)="hide1 = !hide1">
                    <div class="input-group-text">
                        <mat-icon>{{
                            hide1 ? 'visibility_off' : 'visibility'
                        }}</mat-icon>
                    </div>
                </div>
            </div>

            <div *ngIf="f.password.errors">
                <div *ngIf="f.password.errors.pattern" class="error">
                    Het wachtwoord moet minimaal 1 hoofdletter, 1 nummer en 1
                    leesteken bevatten.
                </div>
                <div *ngIf="f.password.errors.minlength" class="error">
                    Het wachtwoord moet uit minimaal 8 karakters bestaan.
                </div>
            </div>

            <div class="input-group">
                <input
                    class="form-control"
                    [ngClass]="{
                        error:
                            f.confirmPassword.errors &&
                            (f.confirmPassword.dirty ||
                                f.confirmPassword.touched)
                    }"
                    placeholder="Bevestig wachtwoord"
                    [type]="hide2 ? 'password' : 'text'"
                    formControlName="confirmPassword"
                />
                <div class="input-group-append" (click)="hide2 = !hide2">
                    <div class="input-group-text">
                        <mat-icon>{{
                            hide2 ? 'visibility_off' : 'visibility'
                        }}</mat-icon>
                    </div>
                </div>
            </div>

            <div
                *ngIf="
                    resetForm.get('password').value !==
                    resetForm.get('confirmPassword').value
                "
            >
                <div class="error">De wachtwoorden komen niet overeen.</div>
            </div>
            <button
                class="btn btn-lg btn-primary btn-block btn-signin"
                [disabled]="
                    !resetForm.valid &&
                    resetForm.get('password').value !==
                        resetForm.get('confirmPassword').value
                "
                type="submit"
            >
                Opslaan
            </button>
        </form>
    </div>

    <div
        id="image"
        *ngIf="configService.backgroundImage"
        [style.backgroundImage]="'url(' + configService.backgroundImage + ')'"
    ></div>
    <video
        muted
        autoplay
        loop
        id="myVideo"
        class="fade-in"
        *ngIf="configService.backgroundVideo"
    >
        <source [src]="configService.backgroundVideo" type="video/mp4" />
    </video>
</div>
