import { Component, EffectRef, OnDestroy, effect } from '@angular/core';
import { ConfigService, CustomExtentService, MapService, SidenavService } from 'app/_services';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { boundingExtent } from 'ol/extent';

@Component({
    selector: 'custom-extent',
    templateUrl: 'custom-extent.component.html',
    styleUrls: ['custom-extent.component.scss']
})
export class CustomExtentComponent implements OnDestroy {
    readonly faMapPin = faMapPin;
    private configEffect: EffectRef;

    constructor(
        private readonly configService: ConfigService,
        public readonly customExtentService: CustomExtentService,
        private readonly mapService: MapService,
        private readonly sidenavService: SidenavService
    ) {
        this.configEffect = effect(() => this.loadExtents());
        this.sidenavService.setWidth(380, 'px');
    }

    ngOnDestroy(): void {
        this.configEffect.destroy();
    }

    loadView(view): void {
        const coordinatesArray = view.extent.split(',');
        const boundingExtent2 = [
            [+coordinatesArray[0], +coordinatesArray[1]],
            [+coordinatesArray[2], +coordinatesArray[3]]
        ];

        const extent = boundingExtent(boundingExtent2);
        this.mapService.map().getView().fit(extent, { duration: 1000 });
    }

    private loadExtents(): void {
        const config = this.configService.config();
        const isPublic = config.public !== undefined && config.public;

        // Load appropriate extents based on the config
        if (isPublic) {
            this.customExtentService.loadPublicExtents(config.id);
        } else {
            this.customExtentService.loadExtents();
        }
    }
}
