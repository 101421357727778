<h1 mat-dialog-title>Metadata bewerken</h1>

<mat-dialog-content>
    <form [formGroup]="formGroup">
        <ng-container *ngFor="let p of formGroup.controls; let i = index">
            <div [formGroupName]="i" class="card p-3 mb-3">
                <div class="row justify-content-between">
                    <mat-form-field class="col-5">
                        <input
                            matInput
                            placeholder="Naam"
                            value=""
                            formControlName="title"
                            i18n-placeholder
                        />
                        <mat-label>Titel</mat-label>
                    </mat-form-field>
                    <button
                        class="col-1"
                        type="button"
                        mat-icon-button
                        name="button"
                        (click)="removeRow(i)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <ng-container formGroupName="info">
                    <div
                        [formGroupName]="j"
                        *ngFor="
                            let layer_group of p.get('info')['controls'];
                            let j = index
                        "
                        class="card mb-3"
                    >
                        <div class="row mx-4 my-2 justify-content-between">
                            <mat-form-field class="col-5">
                                <input
                                    matInput
                                    placeholder="Omschrijving"
                                    value=""
                                    formControlName="key"
                                    i18n-placeholder
                                />
                                <mat-label>Omschrijving</mat-label>
                            </mat-form-field>

                            <mat-form-field class="col-5">
                                <input
                                    matInput
                                    placeholder="Waarde"
                                    value=""
                                    formControlName="value"
                                    i18n-placeholder
                                />
                                <mat-label>Waarde</mat-label>
                            </mat-form-field>

                            <button
                                class="col-1"
                                type="button"
                                mat-icon-button
                                name="button"
                                (click)="removeRow(i, j)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                    <button
                        mat-mini-fab
                        class="float-end mt-2"
                        matTooltip="Kolom toevoegen"
                        color="secondary"
                        type="button"
                        (click)="addSub(i)"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-container>
            </div>
        </ng-container>
        <mat-divider class="mt-3 mb-3"></mat-divider>
        <div class="row mt-3">
            <button
                mat-mini-fab
                color="secondary"
                type="button"
                (click)="add()"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>

    <mat-dialog-actions class="float-end">
        <button mat-button (click)="onNoClick()">Sluiten</button>
        <button mat-button (click)="onSave()">Bevestigen</button>
    </mat-dialog-actions>
</mat-dialog-content>
