import { Component, EventEmitter, Output } from '@angular/core';
import { ConfigService, MapService } from '../../_services';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MapCreatorDialogComponent } from '../map-creator.component';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';

@Component({
    selector: 'from-csv',
    templateUrl: './from-csv.component.html',
    styleUrls: ['./from-csv.component.scss']
})
export class MapFromCSVComponent {
    @Output() changeLoading = new EventEmitter<boolean>();

    environment: any;
    headersRow: Array<any>;
    csvRecordsArray: any;
    records: any;
    splitter = ';';
    splitters = [';', ','];

    xCoordinateName: any;
    yCoordinateName: any;

    step = 0;

    geoJson: any;

    name: FormControl;
    resourceFormGroup: FormGroup;

    error: string;

    constructor(
        public configService: ConfigService,
        private http: HttpClient,
        private mapService: MapService,
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MapCreatorDialogComponent>
    ) {
        this.environment = environment;
        this.name = new FormControl('', Validators.required);

        this.resourceFormGroup = this.formBuilder.group({
            name: this.name
        });
    }

    uploadListener($event: any): void {
        const files = $event.srcElement.files;

        this.handleFileUpload(files[0]);
    }

    handleFileUpload(file) {
        if (this.isValidCSVFile(file)) {
            const reader = new FileReader();
            reader.readAsText(file);

            reader.onload = () => {
                const csvData = reader.result;
                // Split the file on enters
                this.csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

                this.headersRow = this.getHeaderArray(this.csvRecordsArray);

                // And go to the next step
                this.step = 1;
            };

            reader.onerror = function () {
                console.log('error is occured while reading file!');
            };
        } else {
            alert('Please import valid .csv file.');
            this.fileReset();
        }
    }

    convertToGeoJson(): void {
        this.error = undefined;
        this.changeLoading.emit(true);

        this.geoJson = {
            type: 'FeatureCollection',
            crs: {
                type: 'EPSG',
                properties: {
                    code: '28992'
                }
            },
            features: []
        };

        console.log(this.csvRecordsArray.length);
        this.csvRecordsArray.forEach((record, i) => {
            // Skip first row
            if (i) {
                const currentRecord = record.split(this.splitter);
                const feature = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: []
                    },
                    properties: {}
                };

                const coords = [];
                currentRecord.forEach((value, j) => {
                    if (this.xCoordinateName === this.headersRow[j]) {
                        // Check if the coordinate is between epsg 28992 bounds
                        if (646.36 < +value && 308975.28 > +value) {
                            coords.push(+value);
                        } else {
                            this.error =
                                'Er zijn foutieve X coordinaten gevonden, bijvoorbeeld: ' +
                                value;
                        }
                    } else if (this.yCoordinateName === this.headersRow[j]) {
                        if (276050.82 < +value && 636456.31 > +value) {
                            coords.push(+value);
                        } else {
                            this.error =
                                'Er zijn foutieve Y coordinaten gevonden, bijvoorbeeld: ' +
                                value;
                        }
                    } else {
                        // Set all the properties from the csv
                        feature.properties[this.headersRow[j]] = value;
                    }

                    //  If the coordinates are complete set them in the feature
                    if (coords.length === 2) {
                        feature.geometry.coordinates = coords;
                    }
                });

                if (feature.geometry.coordinates.length) {
                    this.geoJson.features.push(feature);
                }
            }
        });

        // And go to the next step
        this.changeLoading.emit(false);
        if (this.error === undefined) {
            this.step = 2;
        }
    }

    onSubmit(): void {
        this.changeLoading.emit(true);

        // Required map attributes
        const body = this.resourceFormGroup.getRawValue();
        if (this.csvRecordsArray.length > 1000) {
            body.type = 'WebGLPoints';
        } else {
            body.type = 'Vector';
        }
        body.collapsed = true;
        body.visible = true;
        body.order = 50;
        body.hide = false;
        body.click = 'featureInMap';
        body.grid = {};
        body.source = {
            type: 'Vector',
            geojson: this.geoJson
        };

        this.http
            .post(`${environment.api_base_url}/maps`, body)
            .toPromise()
            .then((res: any) => {
                console.log(res);
                this.changeLoading.emit(false);

                this.bindToConfig(res.id);
            })
            .catch(err => {
                console.error(err);
                this.changeLoading.emit(false);
            });
    }

    isValidCSVFile(file: File): boolean {
        return file.name.endsWith('.csv');
    }

    getHeaderArray(csvRecordsArr: any): any[] {
        let count = 0;
        this.splitters.forEach(s => {
            if (count < (<string>this.csvRecordsArray[0]).split(s).length) {
                this.splitter = s;
            }
            count = (<string>this.csvRecordsArray[0]).split(s).length;
        });
        // @shouldRemove let to const
        let headers = (<string>csvRecordsArr[0]).split(this.splitter);
        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    fileReset(): void {
        this.records = [];
    }

    bindToConfig(map_id): void {
        const body = {
            map_id,
            config_id: this.configService.config().id
        };
        this.http
            .post(`${environment.api_base_url}/maps/map-to-config`, body)
            .toPromise()
            .then((res: any) => {
                this.dialogRef.close();
                
                this.configService.config.set(undefined);
                this.mapService.map.set(undefined);
                this.configService.loadConfiguration();
            })
            .catch(err => {
                console.error(err);
            });
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer?.files;
        console.log(files)
        if (files && files.length) {
            if (files[0].name.slice(-3) === 'csv') {
                this.handleFileUpload(files[0]);
            }
        }
    }
}
