<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Shape </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <input
            type="file"
            #csvReader
            name="Upload CSV"
            id="txtFileUpload"
            (change)="uploadListener($event)"
            multiple
            accept=".cpg, .dbf, .prj, .shp, .shx"
        />
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 1" hideToggle *ngIf="geoJson">
        <mat-expansion-panel-header>
            <mat-panel-title> Result </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="resourceFormGroup" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>Naam</mat-label>
                <input matInput type="text" formControlName="name" />
            </mat-form-field>

            <div class="col float-end">
                <button mat-raised-button color="primary" type="submit" i18n>
                    Opslaan
                </button>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>
