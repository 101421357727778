<button
    mat-mini-fab
    color="secondary"
    class="tool"
    (click)="openDialog()"
    matTooltip="Locatie opslaan"
    matTooltipPosition="left"
>
    <fa-icon [icon]="faMapPin"></fa-icon>
</button>
