<br />

<div class="form" *ngIf="legendService.styleMap().type === 'Vector'">
    <div class="row">
        <button
            mat-mini-fab
            color="secondary"
            class="closeButton"
            type="button"
            (click)="toLegend()"
        >
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
    <h5>
        Weergave
        {{ legendService.styleMap().title ?? legendService.styleMap().name }}
    </h5>
    <div class="row">
        <!-- <span class="mt-2">Lijn dikte</span> -->
        <!-- <mat-slider
            class="w-75"
            [max]="10"
            [min]="-3"
            [(ngModel)]="legendService.styleSettings.lineWidth"
            (change)="updateVectorStyle()"
            thumbLabel="true"
            color="primary"
        ></mat-slider> -->
    </div>
    <div>
        <p class="mt-2">
            Kleur omlijning:
            <input
                class="colorPicker"
                [(colorPicker)]="legendService.styleSettings.color"
                (colorPickerSelect)="updateVectorStyle()"
                [style.background]="legendService.styleSettings.color"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'-15%'"
                cpDisableInput="disabled"
                [cpPositionRelativeToArrow]="true"
            />
        </p>
    </div>

    <div>
        <p class="mt-2">
            Kleur opvulling:
            <input
                class="colorPicker"
                [(colorPicker)]="legendService.styleSettings.fillColor"
                (colorPickerSelect)="updateVectorStyle()"
                [style.background]="legendService.styleSettings.fillColor"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'-15%'"
                cpDisableInput="disabled"
                [cpPositionRelativeToArrow]="true"
            />
        </p>
    </div>

    <br />

    <h5>Labels</h5>
    <div class="row">
        <mat-form-field class="">
            <mat-label>Waarde</mat-label>
            <input
                matInput
                placeholder=""
                value=""
                [(ngModel)]="legendService.styleSettings.labelKey"
                [matAutocomplete]="autoKey"
            />
            <mat-autocomplete
                #autoKey="matAutocomplete"
                (optionSelected)="updateVectorStyle()"
            >
                <mat-option
                    *ngFor="let c of legendService.styleLabelKeys"
                    [value]="c"
                >
                    {{ c }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="row">
        <!-- <span class="mt-2">Schaal weergave</span>
        <mat-slider
            class="w-75"
            [max]="50"
            [min]="1"
            [(ngModel)]="legendService.styleSettings.labelMaxRes"
            (change)="updateVectorStyle()"
            thumbLabel="true"
            color="primary"
        ></mat-slider> -->
    </div>
</div>
<div class="form h-50" *ngIf="legendService.styleMap().type === 'WebGLPoints'">
    <h5>Weergave</h5>
    <div class="row">
        <p class="mt-3">Kleur:</p>
        <input
            class="colorPicker"
            [(colorPicker)]="legendService.styleSettings.color"
            (colorPickerSelect)="updateWebGLStyle()"
            [style.background]="legendService.styleSettings.color"
            [cpPosition]="'bottom'"
            [cpPositionOffset]="'-10%'"
            cpAlphaChannel="disabled"
            cpDisableInput="disabled"
            [cpPositionRelativeToArrow]="true"
        />
    </div>
</div>
<div class="mt-5">
    <button
        mat-raised-button
        (click)="save()"
        class="float-end"
        color="primary"
    >
        Opslaan
    </button>
</div>
