import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import proj4 from 'proj4';
import { ReplaySubject, Observable, forkJoin } from 'rxjs';
import { DOCUMENT } from '@angular/common';

declare var google: any;
export interface StreetviewData {
    coordinates: any;
}
/**
 * Dialog for the Name of the saved location
 */
@Component({
    selector: 'settings-dialog',
    templateUrl: 'streetview.html',
    styleUrls: ['streetview.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * This component loads streetView
 */
export class StreetviewComponent implements OnInit {
    private _loadedLibraries: { [url: string]: ReplaySubject<void> } = {};
    googleMapsUrl: string;

    constructor(
        private readonly http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: StreetviewData,
        @Inject(DOCUMENT) private readonly document: any
    ) {}

    ngOnInit(): void {
        this.http
            .get(environment.api_base_url + `/key/googleMaps`, {
                observe: 'response',
                responseType: 'json'
            })
            .subscribe((response: any) => {
                this.googleMapsUrl = response.body;
                this.lazyLoadStreetview().subscribe(_ => {
                    if (!google) {
                        google = this.document.defaultView.google;
                    }
                    this.initialize();
                });
            });
    }

    lazyLoadStreetview(): Observable<any> {
        console.log(this.googleMapsUrl);
        return forkJoin([this.loadScript(this.googleMapsUrl)]);
    }

    private loadScript(url: string): Observable<any> {
        if (this._loadedLibraries[url]) {
            return this._loadedLibraries[url].asObservable();
        }

        this._loadedLibraries[url] = new ReplaySubject();

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = url;
        script.onload = () => {
            this._loadedLibraries[url].next();
            this._loadedLibraries[url].complete();
        };

        this.document.body.appendChild(script);

        return this._loadedLibraries[url].asObservable();
    }

    initialize(): void {
        const googleCoordinates = proj4('EPSG:28992', 'EPSG:4326', [
            this.data.coordinates[0],
            this.data.coordinates[1]
        ]);
        new google.maps.StreetViewPanorama(
            document.getElementById('streetView'),
            {
                position: {
                    lat: googleCoordinates[1],
                    lng: googleCoordinates[0]
                },
                pov: { heading: 0, pitch: 0 },
                zoom: 1,
                fullscreenControl: true
            }
        );
    }
}
