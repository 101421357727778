import { Injectable } from '@angular/core';
import { Cluster } from 'ol/source';
import { MapService } from './map.service';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is for making queries
 */
export class CqlService {
    cql: string;

    activeFilters: {
        [key: string]: string[];
    } = {};

    constructor(private readonly mapService: MapService) {
        this.activeFilters = {};
    }

    add(layers: string[], cql: string): void {
        for (const layer of layers) {
            if (!this.activeFilters[layer]) {
                this.activeFilters[layer] = [cql];
            } else if (!this.activeFilters[layer].includes(cql)) {
                this.activeFilters[layer].push(cql);
            }
        }

        this.updateLayers(layers);
    }

    update(layers: string[], cql: string): void {
        this.cql = cql;

        this.updateLayers(layers);
    }

    delete(layers: string[], cql: string): void {
        for (const layer of layers) {
            const filters = this.activeFilters[layer];
            if (filters) {
                const index = filters.indexOf(cql);
                if (index !== -1) {
                    filters.splice(index, 1);
                }
            }
        }

        this.updateLayers(layers);
    }

    updateLayers(layers: string[]): void {
        const mapLayers = this.mapService.map().getLayers().getArray();

        for (const lyr of mapLayers) {
            if (layers.includes(lyr.get('title'))) {
                this.changeLayerCql(lyr);
            }
        }
    }

    changeLayerCql(layer: any): void {
        const layerSource =
            layer.getSource() instanceof Cluster
                ? layer.getSource().getSource()
                : layer.getSource();

        layerSource.set('cql', this.cql);
        layerSource.refresh();
    }
}
