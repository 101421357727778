<div class="overlay m-0">
    <h2 class="my-0">Maak een melding</h2>
    <form
        [formGroup]="createNotificationFormGroup"
        (ngSubmit)="createNotification()"
    >
        <div class="container m-0">
            <div>
                <div>
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            placeholder="Email"
                            value=""
                            formControlName="email"
                            type="email"
                            required
                            i18n-placeholder
                        />
                    </mat-form-field>
                </div>
            </div>

            <div>
                <div>
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            placeholder="Onderwerp"
                            value=""
                            formControlName="subject"
                            type="text"
                            required
                            i18n-placeholder
                        />
                    </mat-form-field>
                </div>
            </div>

            <div>
                <div>
                    <mat-form-field class="w-100">
                        <textarea
                            matInput
                            rows="15"
                            matInput
                            class="w-100"
                            placeholder="Bericht"
                            value=""
                            formControlName="message"
                            required
                            i18n-placeholder
                        ></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3 mb-1">
                <div class="col">
                    <button
                        type="button"
                        (click)="back()"
                        mat-raised-button
                        i18n
                    >
                        Terug
                    </button>
                </div>
                <div class="col">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        i18n
                    >
                        Bevestigen
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
