import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapService, InteractionService } from 'app/_services';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { unByKey } from 'ol/Observable';

@Component({
    selector: 'get-coordinate',
    templateUrl: './get-coordinate.component.html',
    styleUrls: ['./get-coordinate.component.scss']
})
/**
 * This tool will get your current coordinates
 * On the right of the Map
 */
export class GetCoordinateComponent {
    faMapMarkedAlt = faMapMarkedAlt;

    snackbarRef: any;
    constructor(
        private mapService: MapService,
        public snackBar: MatSnackBar,
        public interactionService: InteractionService
    ) {}

    getCoordinate(): void {
        if (this.interactionService.getCoordinate.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.getCoordinate.active =
                !this.interactionService.getCoordinate.active;
        }

        if (this.interactionService.getCoordinate.active == true) {
            document.getElementById('cook_map').style.cursor = 'crosshair';
        } else {
            document.getElementById('cook_map').style.cursor = 'default';
        }

        if (
            !this.interactionService.getCoordinate.interaction &&
            this.interactionService.getCoordinate.active
        ) {
            this.interactionService.getCoordinate.interaction = this.mapService
                .map()
                .on('singleclick', event => {
                    this.openSnackBar(event);
                });
        } else {
            // @shouldRemove
            // this.interactionService.removeInteractions();
            unByKey(this.interactionService.getCoordinate.interaction);
        }
    }

    openSnackBar(event): void {
        const x = Math.round(event.coordinate[0] * 100) / 100;
        const y = Math.round(event.coordinate[1] * 100) / 100;
        const coordinate: string = x + ' , ' + y;
        this.snackbarRef = this.snackBar.open(coordinate, 'Kopieer', {
            panelClass: 'white-snackbar',
            verticalPosition: 'top',
            duration: 10000
        });
        this.snackbarRef.onAction().subscribe(() => {
            this.copyMessage(coordinate);
        });
    }

    copyMessage(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
