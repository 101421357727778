import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EffectRef,
    effect
} from '@angular/core';
import { MapService, CustomExtentService, ConfigService } from 'app/_services';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';

export interface DialogData {
    name: string;
}

@Component({
    selector: 'create-custom-extent',
    templateUrl: './create-custom-extent.component.html',
    styleUrls: ['./create-custom-extent.component.scss']
})
/**
 * This tool will save the current location with a text that you can give
 * On the right of the Map
 */
export class CreateCustomExtentComponent implements OnInit, OnDestroy {
    faMapPin = faMapPin;

    configSubscription: EffectRef;
    config: any;

    constructor(
        private mapService: MapService,
        private configService: ConfigService,
        public dialog: MatDialog,
        public customExtentService: CustomExtentService
    ) {
        this.configSubscription = effect(
            () => {
                this.config = this.configService.config();
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.config = this.configService.config();
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    openDialog(): void {
        // open name dialog
        const dialogRef = this.dialog.open(CustomExtentDialogComponent, {
            data: { name: '' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.saveView(result);
            }
        });
    }

    saveView(name: string): void {
        // get current extent/location
        const activeExtent = this.mapService.map().getView().calculateExtent();
        const body = {
            name,
            extent: `${activeExtent[0]},${activeExtent[1]},${activeExtent[2]},${activeExtent[3]}`
        };

        console.log(this.config);
        if (this.config.public) {
            body['config_id'] = this.config.id;
            console.log(body);
        }

        // store the saved location
        this.customExtentService.storeCustomExtent(body);

        // refresh the locations
        this.customExtentService.loadExtents();
    }
}

// Dialog for the Name of the saved location
@Component({
    selector: 'custom-extent-dialog',
    template: ` <div mat-dialog-content>
            <h5>Geef een naam aan je favoriete locatie.</h5>
            <br />
            <mat-form-field>
                <input matInput [(ngModel)]="data.name" placeholder="Naam" />
            </mat-form-field>
        </div>
        <div mat-dialog-actions style="justify-content: right;">
            <button mat-button (click)="onNoClick()">Sluiten</button>
            <button mat-button [mat-dialog-close]="data.name">Opslaan</button>
        </div>`
})
export class CustomExtentDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CustomExtentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
