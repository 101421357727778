import { Component, EffectRef, OnDestroy, OnInit, effect } from '@angular/core';
import { CustomExtentService, ConfigService } from 'app/_services';
import { faMapPin, faMapSigns } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../../_dialogs/delete/delete.dialog';

@Component({
    selector: 'setting-custom-extent',
    templateUrl: './setting-custom-extent.component.html',
    styleUrls: ['./setting-custom-extent.component.scss']
})
/**
 * With this component you can set your own custom extent
 */
export class SettingCustomExtentComponent implements OnInit, OnDestroy {
    readonly faMapPin = faMapPin;
    readonly faMapSign = faMapSigns;

    configSubscription: EffectRef;

    constructor(
        private readonly configService: ConfigService,
        readonly customExtentService: CustomExtentService,
        private readonly dialog: MatDialog
    ) {
        this.configSubscription = effect(
            () => {
                const config = this.configService.config();
                // Load all the extents
                if (config.public && config.public !== undefined) {
                    this.customExtentService.loadPublicExtents(config.id);
                } else {
                    this.customExtentService.loadExtents();
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    ngOnInit(): void {
        const config = this.configService.config();
        console.log(config);

        // Load all the extents
        if (config.public && config.public !== undefined) {
            this.customExtentService.loadPublicExtents(config.id);
        } else {
            this.customExtentService.loadExtents();
        }
    }

    onDelete(view): void {
        // open the delete dialog to confirm the delete
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            data: {
                id: view.id,
                type: 'opgeslagen locatie',
                descriptor: view.name
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog closed, result: ' + result);
            if (result.delete && result.id) {
                this.delete(result.id);
            }
        });
    }

    delete(id): void {
        console.log(id);
        // delete custom view
        this.customExtentService.deleteCustomExtent(id);
    }
}
