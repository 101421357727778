<form class="autocomplete-form">
    <mat-form-field class="w-100" color="primary">
        <input
            matInput
            type="text"
            placeholder="Zoeken"
            [formControl]="searchControl"
            [matAutocomplete]="auto"
            aria-label="Search input"
        />
        <button
            class=""
            *ngIf="searchControl.getRawValue()"
            matSuffix
            mat-icon-button
            aria-label="Clear search"
            (click)="clearSearch()"
            [matTooltip]="'Zoekopdracht wissen'"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
            (onSelectionChange)="goToResult(result)"
            *ngFor="let result of filteredResults()"
            [value]="getSelectedSearchValue(result)"
        >
            <div
                class="scroll"
                *ngIf="config.type === 'custom' || !config.type"
            >
                <strong>
                    {{ result.zoekweergave1 }}
                    {{ result.zoekweergave2 }}
                </strong>
                <span class="grey">
                    {{ result.zoekweergave3 }} {{ result.zoekweergave4 }}
                    {{ result.zoekweergave5 }}
                </span>
            </div>
            <div *ngIf="config.type === 'pdok'">
                {{ result.weergavenaam }}
            </div>
            <div *ngIf="config.type === 'maps'">
                {{ result[column] }}
            </div>
        </mat-option>
        <mat-option *ngIf="!hasResults()" disabled="true">
            <div><strong>Geen resultaten</strong></div>
        </mat-option>
        <mat-option
            *ngIf="coordinate()"
            (onSelectionChange)="zoomToCoordinate()"
            value=""
        >
            <div>EPSG Coordinaat</div>
        </mat-option>
    </mat-autocomplete>
</form>
<mat-progress-bar
    mode="indeterminate"
    *ngIf="showAnimation()"
    class="loadingAnimation"
>
</mat-progress-bar>
