import {
    trigger,
    state,
    style,
    transition,
    animate
} from '@angular/animations';

/**
 * All the animations used in the application are collected in this file. Animations.ts
 * can be imported to use these animations in the rest of the code.
 */


export const grid = trigger('grid', [
    state(
        'open',
        style({
            bottom: '290px'
        })
    ),
    state(
        'closed',
        style({
            bottom: 'calc(1dvh + env(safe-area-inset-bottom))'
        })
    ),
    transition('open => closed', animate('200ms')),
    transition('closed => open', animate('200ms'))
]);
