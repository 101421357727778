import { computed, Injectable, signal } from '@angular/core';

type WidthUnit = 'px' | '%';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is to keep track of the sidenav status
 */
export class SidenavService {
    readonly widthValue = signal<number>(380);
    readonly widthUnit = signal<WidthUnit>('px');
    readonly tabIndex = signal<number>(0);
    readonly width = computed(() => `${this.widthValue()}${this.widthUnit()}`);
    readonly isOpen = computed(() => this.widthValue() > 0);

    setWidth(newWidth: number, unit: string): void {
        if (unit !== 'px' && unit !== '%') {
            console.error('Invalid unit');
            return;
        }

        this.widthValue.set(newWidth);
        this.widthUnit.set(unit);
    }
}
