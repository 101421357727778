<button
    mat-mini-fab
    color="secondary"
    class="tool"
    (click)="selectFeatureInfo()"
    matTooltip="{{ message }}"
    matTooltipPosition="left"
    [disabled]="disabled()"
    [class.active]="interactionService.vrPhoto.active"
>
    <mat-icon>360</mat-icon>
</button>
