import { Injectable } from '@angular/core';
import { Style, Fill, Stroke, Circle } from 'ol/style';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for providing the styles for drawing
 */
export class DrawService {
    styles = {
        yellow: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
                color: '#ffcc33',
                width: 2
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#ffcc33'
                })
            })
        }),
        green: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
                color: '#2ecc71',
                width: 2
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#2ecc71'
                })
            })
        }),
        blue: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
                color: '#3498db',
                width: 2
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#3498db'
                })
            })
        }),
        brown: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
                color: '#a38671',
                width: 2
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#a38671'
                })
            })
        }),
        red: new Style({
            stroke: new Stroke({
                color: '#e74c3c',
                width: 1
            }),
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#e74c3c'
                })
            })
        }),
        highlight: new Style({
            stroke: new Stroke({
                color: 'rgba(54, 102, 223, 1)',
                width: 2
            }),
            fill: new Fill({
                color: 'rgba(54, 102, 223, 0.4)'
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: 'rgba(54, 102, 223, 1)'
                })
            })
        })
    };
}
