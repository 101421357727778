<!-- chat-dialog.component.html -->
<div class="chat-dialog">
    <h1 mat-dialog-title>Berichten</h1>
    <button mat-icon-button class="close-button" [mat-dialog-close]="data">
        <mat-icon>close</mat-icon>
    </button>

    <!-- Lichaam van de chat -->
    <div class="chat-body" #chatBody>
        <div class="message" *ngFor="let message of messages()">
            <div
                [ngClass]="{
                    'user-message': message.user_id == authService.id(),
                    'bot-message': message.user_id !== authService.id()
                }"
            >
                {{ message.user.first_name }}{{ message.user.middle_name }}
                {{ message.user.last_name }} - {{ message.text }} -
                {{ message.created_at | date }}
            </div>
        </div>
    </div>

    <!-- Input voor het bericht -->
    <div class="chat-footer">
        <mat-form-field class="message-input" appearance="outline">
            <mat-label>Type een bericht...</mat-label>
            <input
                matInput
                type="text"
                [formControl]="message"
                (keydown.enter)="sendMessage()"
            />
        </mat-form-field>
        <button [disabled]="message.invalid" mat-icon-button color="primary" (click)="sendMessage()">
            <mat-icon>send</mat-icon>
        </button>
    </div>
</div>
