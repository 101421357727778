import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PublicGuard {
    constructor(private router: Router) {}

    /**
     * Check if a route can be activated, if this is not the case send the user to the login page
     * @return {boolean} [description]
     */
    canActivate(): boolean {
        if (environment.public) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
