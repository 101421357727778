import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeleteDialogData {
    type: string;
    id: number;
    descriptor: string;
}

@Component({
    selector: 'delete-dialog',
    templateUrl: 'delete.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Dialog for deleting an item
 */
export class DeleteDialogComponent {
    constructor(
        private readonly dialog: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: DeleteDialogData
    ) {}

    onNoClick(): void {
        this.dialog.close();
    }
}
