import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule
} from '@angular/material/dialog';

export interface InfoDialogData {
    title: string;
    message: string;
}

@Component({
    selector: 'info-dialog',
    templateUrl: 'info.dialog.html',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Small info dialog
 */
export class InfoDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<InfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InfoDialogData
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
