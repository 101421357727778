import {
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MapService, ConfigService, LayerService, SidenavService } from 'app/_services';
import { DateAdapter } from '@angular/material/core';
import { environment } from 'environments/environment';
import GeoJSON from 'ol/format/GeoJSON';
import { faFilePdf, faMapPin } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { Feature } from 'ol';
@Component({
    selector: 'messages',
    templateUrl: 'messages.component.html',
    styleUrls: ['messages.component.scss']
})
/**
 * @shouldRemove add description
 */
export class MessagesComponent implements OnInit, OnDestroy {
    // fontawsome icons
    faFilePdf = faFilePdf;
    faMapPin = faMapPin;

    env: any;

    // Feature: the features to load
    features: any[];
    layer: any;
    properties: any;
    config: any;
    notifications: any;

    // Type: Defines the part of the html to load
    featureType: string;
    filter: string;

    constructor(
        private mapService: MapService,
        private sidenavService: SidenavService,
        private layerService: LayerService,
        private configService: ConfigService,
        private http: HttpClient,
        private dateAdapter: DateAdapter<Date>,
        public sanitizer: DomSanitizer
    ) {
        this.dateAdapter.setLocale('nl');
        this.featureType = '';
        this.env = environment;

        this.sidenavService.setWidth(1000, 'px');
    }

    ngOnInit(): void {
        this.config = this.configService.config();
        this.http
            .get(
                environment.api_base_url +
                    '/notifications/config/' +
                    this.config.id
            )
            .subscribe(res => {
                this.notifications = res;
            });
    }

    ngOnDestroy(): void {
        this.layerService.notificationLayer().getSource().clear();
    }

    /**
     * Zoom to the feature on the map
     * @param feature
     */
    loadView(features): void {
        this.layerService.notificationLayer().getSource().clear();

        console.log(features);
        features.forEach(feature => {
            const geom = JSON.parse(feature.geometry);
            const props = JSON.parse(feature.properties);
            const newFeature = new Feature();

            newFeature.setGeometry(
                new GeoJSON().readGeometry(geom, {
                    featureProjection: 'EPSG:28992',
                    dataProjection: 'EPSG:4326'
                })
            );
            newFeature.setProperties(props);

            this.layerService
                .notificationLayer()
                .getSource()
                .addFeature(newFeature);

            this.mapService
                .map()
                .getView()
                .fit(
                    this.layerService
                        .notificationLayer()
                        .getSource()
                        .getExtent(),
                    { duration: 1000, padding: [200, 200, 200, 200] }
                );
        });
    }

    download(value, name): void {
        const encodedValue = btoa(value);
        this.http
            .get(environment.api_base_url + '/download/' + encodedValue, {
                responseType: 'blob'
            })
            .subscribe(x => {
                value = value.substring(value.lastIndexOf('/') + 1);
                FileSaver.saveAs(x, name);
            });
    }
}
