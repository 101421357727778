import {
    Component,
    Inject,
    OnDestroy,
    EffectRef,
    effect,
    signal
} from '@angular/core';
import { ConfigService, MapService } from 'app/_services';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
    selector: 'share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})
/**
 * This tool will save the current location with a text that you can give
 * On the right of the Map
 */
export class ShareComponent {
    faShare = faShare;

    constructor(
        private readonly dialog: MatDialog,
    ) {}

    openDialog(): void {
        // open name dialog
        const dialogRef = this.dialog.open(ShareDialogComponent, {
            data: { name: '' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.saveView(result);
            }
        });
    }

    saveView(name: string): void {}
}

// Dialog for the Name of the saved location
@Component({
    selector: 'share-dialog',
    template: `
        <div mat-dialog-content>
            <h5>Locatie delen</h5>
            <br />
            <div>
                <mat-form-field>
                    <mat-label>Tijd actief</mat-label>
                    <mat-select [(ngModel)]="expire">
                        <mat-option
                            *ngFor="let option of expireOptions"
                            [value]="option"
                        >
                            {{ option }} uur
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br />
                <!-- <mat-form-field>
                    <input matInput [(ngModel)]="data.name" placeholder="Naam" />
                </mat-form-field> -->
                <div *ngIf="share_url">Kopieer url: {{ share_url }}</div>
                <div mat-dialog-actions style="justify-content: right;">
                    <button mat-button (click)="onNoClick()">Sluiten</button>
                    <button mat-button (click)="save()">Aanmaken</button>
                </div>
                <div></div>
            </div>
        </div>
    `
})
export class ShareDialogComponent implements OnDestroy {
    readonly expire = signal(1);
    readonly expireOptions = [1, 8, 24];
    configId: number;
    configSubscription: EffectRef;
    share_url: any;
    constructor(
        private readonly dialogRef: MatDialogRef<ShareDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly configService: ConfigService,
        private readonly http: HttpClient,
        private readonly mapService: MapService
    ) {
        this.share_url = undefined;

        this.configSubscription = effect(
            () => {
                this.configId = this.configService.config().id;
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        const extent = this.mapService.map().getView().calculateExtent();

        const body = {
            extent: `${extent[0]},${extent[1]},${extent[2]},${extent[3]}`,
            expire: this.expire,
            option_id: this.configId
        };

        this.http
            .post(environment.api_base_url + '/shared/', body)
            .subscribe((res: any) => {
                this.share_url =
                    window.location.origin + '/cook/shared/' + res.id;
                console.log(this.share_url);
                console.log(res);
            });
    }
}
