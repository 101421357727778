<button
    mat-mini-fab
    color="secondary"
    class="tool"
    matTooltip="Meettool Lijn"
    aria-label="Meettool Lijn"
    matTooltipPosition="left"
    (click)="measure('LineString')"
    [class.active]="
        interactionService.measure.lineActive &&
        interactionService.measure.active
    "
>
    <mat-icon>timeline</mat-icon>
</button>
<button
    mat-mini-fab
    color="secondary"
    class="tool"
    matTooltip="Meettool Oppervlakte"
    aria-label="Meettool Oppervlakte"
    matTooltipPosition="left"
    (click)="measure('Polygon')"
    [class.active]="
        interactionService.measure.surfaceActive &&
        interactionService.measure.active
    "
>
    <fa-icon [icon]="faVectorSquare"></fa-icon>
</button>
<button
    mat-mini-fab
    color="secondary"
    class="tool"
    matTooltip="Klik op een meetlijn/vlak om hem te verwijderen."
    aria-label="Klik op een meetlijn/vlak om hem te verwijderen."
    matTooltipPosition="left"
    (click)="clear()"
    [class.active]="interactionService.deleteMeasure.active"
>
    <fa-icon [icon]="faTimes"></fa-icon>
</button>
