import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
    selector: 'metadata-edit-dialog',
    templateUrl: 'metadata-edit.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Dialog to edit the metadata
 */
export class MetadataEditDialogComponent implements OnInit {
    metadata: any;
    formGroup: FormArray;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dialogRef: MatDialogRef<MetadataEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data.metadata) {
            this.metadata = JSON.parse(data.metadata);
        }
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.createForm();

        if (this.metadata) {
            this.metadata.forEach((m, i) => {
                this.add();

                m.info.forEach(info => {
                    this.addSub(i);
                });
            });
            this.formGroup.patchValue(this.metadata);
        } else {
            this.add();
            this.addSub(0);
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        this.dialogRef.close(this.formGroup.getRawValue());
    }

    addSub(id: number): void {
        const groups = this.formGroup as FormArray;
        const info = groups.at(id).get('info') as FormArray;
        info.push(this.createInfo());
    }

    add(): void {
        const layers = this.formGroup as FormArray;
        layers.push(this.createContent());
    }

    removeRow(i, j = undefined): void {
        const groups = this.formGroup as FormArray;

        if (j != undefined) {
            const columns = groups.controls[i].get('info') as FormArray;
            columns.removeAt(j);
        } else {
            groups.removeAt(i);
        }
    }

    private createInfo(): FormGroup {
        return this.formBuilder.group({
            key: '',
            value: ''
        });
    }

    private createForm(): void {
        this.formGroup = this.formBuilder.array([]);
    }

    private createContent(): FormGroup {
        return this.formBuilder.group({
            title: '',
            info: this.formBuilder.array([])
        });
    }
}
